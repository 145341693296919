import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function NavyLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 403 409.49" {...restProps}>
      <g transform="matrix(1.3333 0 0 -1.3333 -267.31 806.22)">
        <g transform="translate(1.3674 -.68371)">
          <path
            transform="matrix(.75 0 0 -.75 200.48 604.66)"
            d="m348.27 11.781-10.033 29.24h-31.932l26.184 19.328-9.543 30.951 25.814-18.596 25.936 18.352-9.7852-30.707 24.465-19.328h-31.072zm-327.37 109.98v48.445h23.488v165.89h-23.488v50.646h107.17v-50.646h-22.754v-116.71l99.094 167.36h74.137v-216.54h22.021v-48.445h-106.43v48.445h24.221v102.77l-90.285-151.21z"
            fill={colors.secondaryColor}
            stroke={colors.primaryColor}
            strokeWidth={variant === 'inverted' ? '0' : '5.872'}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default NavyLogo;
