import React, { useCallback, useContext, useState } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

type FlashMessageContextValue = {
  addFlashMessage(severity: AlertColor, message: string): void;
  clearFlashMessage(): void;
};

const FlashMessageContext = React.createContext<FlashMessageContextValue>({
  addFlashMessage: () => null,
  clearFlashMessage: () => null,
});

type FlashMessageProviderProps = {
  children: React.ReactNode;
};

function FlashMessageProvider({ children }: FlashMessageProviderProps) {
  const [state, setState] = useState<{ severity: AlertColor; message: string }>(null);
  const addFlashMessage = useCallback(
    (severity: AlertColor, message: string) => setState({ severity, message }),
    [setState],
  );
  const clearFlashMessage = useCallback(() => setState(null), [setState]);

  return (
    <FlashMessageContext.Provider value={{ addFlashMessage, clearFlashMessage }}>
      <>
        {state && (
          <Snackbar
            open
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={(_, reason) => {
              if (reason !== 'clickaway') {
                setState(null);
              }
            }}
          >
            <Alert severity={state.severity} variant="filled" onClose={() => setState(null)}>
              {state.message}
            </Alert>
          </Snackbar>
        )}
        {children}
      </>
    </FlashMessageContext.Provider>
  );
}

export function useFlashMessageContext() {
  return useContext(FlashMessageContext);
}

export default FlashMessageProvider;
