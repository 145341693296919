import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { MoneyCell } from '../../../money';
import PicksTableProps from './PicksTableProps';

type DesktopPayoutsTableProps = {
  currentWeek: number;
  lastWeek: number;
  startWeek: number;
  weekPickUsers: PicksTableProps['weekPickUsers'];
};

function DesktopPayoutsTable({ currentWeek, lastWeek, startWeek, weekPickUsers }: DesktopPayoutsTableProps) {
  const sortedUsers = useMemo(() => {
    return weekPickUsers
      .slice()
      .sort(
        (left: DesktopPayoutsTableProps['weekPickUsers'][0], right: DesktopPayoutsTableProps['weekPickUsers'][0]) => {
          // TODO: Convert to use currency.js
          const leftPayout = parseFloat(left.totalPayout);
          const rightPayout = parseFloat(right.totalPayout);
          if (leftPayout === rightPayout) {
            return left.fullName.localeCompare(right.fullName);
          }
          return rightPayout - leftPayout;
        },
      );
  }, [weekPickUsers]);
  const overallWinnerPayoutExists = useMemo(() => {
    return currentWeek === lastWeek && sortedUsers.some(user => Number(user.weekPayouts[currentWeek + 1]) > 0);
  }, [sortedUsers]);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell sx={{ textAlign: 'center' }}>Total</TableCell>
          {Array.from(Array(overallWinnerPayoutExists ? currentWeek + 2 : currentWeek + 1).keys())
            .slice(startWeek)
            .map(i => (
              <TableCell key={i} sx={{ textAlign: 'center' }}>
                {i === lastWeek ? 'Bowls' : i === lastWeek + 1 ? 'Overall Winner' : <>Week {i}</>}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedUsers.map(user => (
          <TableRow key={user.id}>
            <TableCell>{user.fullName}</TableCell>
            <MoneyCell value={user.totalPayout} />
            {user.weekPayouts
              .slice(startWeek, overallWinnerPayoutExists ? user.weekPayouts.length : -1)
              .map((weekPayout, i) => (
                <MoneyCell key={i} value={weekPayout} />
              ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default DesktopPayoutsTable;
