import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function DukeLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 147.15663 142.61723" {...restProps}>
      <path
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        d="m82.428 1.0965 19.722 32.553v75.279l-19.722 32.651h41.314c15.872-0.0368 28.732-12.768 28.732-29.434v-81.002c0-16.665-12.858-30.049-28.732-30.049h-41.314zm-87.848 0.0028 19.742 32.55v75.279l-19.742 32.632h83.503l-19.75-32.632v-75.279l19.75-32.55h-83.503z"
      />
    </SvgIcon>
  );
}

export default DukeLogo;
