export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** JSON as is, but converting object keys to and from camelCase automatically */
  CamelCasedScalar: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: string;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: string;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: string;
};

/** An enumeration. */
export enum BalanceChangeType {
  DividendsReceived = 'DIVIDENDS_RECEIVED',
  InitialBalance = 'INITIAL_BALANCE',
  PostseasonDividendsReceived = 'POSTSEASON_DIVIDENDS_RECEIVED',
  SharesPurchased = 'SHARES_PURCHASED',
  SharesSold = 'SHARES_SOLD',
}

export type BuyTeam = {
  __typename?: 'BuyTeam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type BuyTeamInputType = {
  leagueId: Scalars['UUID'];
  shares: Scalars['Int'];
  teamId: Scalars['UUID'];
  totalPrice: Scalars['Decimal'];
};

export type CancelLeagueInvitation = {
  __typename?: 'CancelLeagueInvitation';
  league?: Maybe<LeagueObjectType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CancelLeagueInvitationInputType = {
  invitationId: Scalars['UUID'];
};

export type ChangePassword = {
  __typename?: 'ChangePassword';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ChangePasswordInputType = {
  password: Scalars['String'];
};

export type CreateLeague = {
  __typename?: 'CreateLeague';
  league?: Maybe<LeagueObjectType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateLeagueInputType = {
  invitationEmails: Array<InputMaybe<Scalars['String']>>;
  leagueType: LeagueType;
  name: Scalars['String'];
};

export type CreateLeagueInvitation = {
  __typename?: 'CreateLeagueInvitation';
  invitation?: Maybe<LeagueInvitationObjectType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateLeagueInvitationInputType = {
  email: Scalars['String'];
  leagueId: Scalars['UUID'];
};

export type CreatePick = {
  __typename?: 'CreatePick';
  ok?: Maybe<Scalars['Boolean']>;
  pick?: Maybe<PickEmLeagueGamePickObjectType>;
};

export type CreatePickInputType = {
  gameId: Scalars['UUID'];
  leagueId: Scalars['UUID'];
  pickedHomeTeam: Scalars['Boolean'];
};

export type GameLiveScoreObjectType = {
  __typename?: 'GameLiveScoreObjectType';
  clock: Scalars['String'];
  quarter: Scalars['String'];
};

export type GameObjectType = {
  __typename?: 'GameObjectType';
  awayRank?: Maybe<Scalars['Int']>;
  awayScore?: Maybe<Scalars['Int']>;
  awayTeam: TeamObjectType;
  description?: Maybe<Scalars['String']>;
  gameType: GameType;
  homeOdds?: Maybe<Scalars['Decimal']>;
  homeRank?: Maybe<Scalars['Int']>;
  homeScore?: Maybe<Scalars['Int']>;
  homeTeam: TeamObjectType;
  id: Scalars['UUID'];
  kickoffDateTime: Scalars['DateTime'];
  liveScoreData?: Maybe<GameLiveScoreObjectType>;
  neutralSite: Scalars['Boolean'];
  overtimes?: Maybe<Scalars['Int']>;
  season: Scalars['Int'];
  status: GameStatus;
  week: Scalars['Int'];
};

/** An enumeration. */
export enum GameStatus {
  /** Canceled */
  Canceled = 'CANCELED',
  /** Completed */
  Completed = 'COMPLETED',
  /** In Progress */
  InProgress = 'IN_PROGRESS',
  /** Scheduled */
  Scheduled = 'SCHEDULED',
}

/** An enumeration. */
export enum GameType {
  ConferenceChampionship = 'CONFERENCE_CHAMPIONSHIP',
  NonNy6Bowl = 'NON_NY6_BOWL',
  Ny6Bowl = 'NY6_BOWL',
  PlayoffFinal = 'PLAYOFF_FINAL',
  PlayoffSemifinal = 'PLAYOFF_SEMIFINAL',
  RegularSeason = 'REGULAR_SEASON',
}

export type LeagueInvitationObjectType = {
  __typename?: 'LeagueInvitationObjectType';
  email: Scalars['String'];
  id: Scalars['UUID'];
  league: LeagueObjectType;
  status: LeagueInvitationStatus;
  user?: Maybe<UserObjectType>;
};

/** An enumeration. */
export enum LeagueInvitationStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type LeagueObjectType = {
  __typename?: 'LeagueObjectType';
  commissioner: UserObjectType;
  games?: Maybe<Array<Maybe<GameObjectType>>>;
  id: Scalars['UUID'];
  invitations: Array<LeagueInvitationObjectType>;
  leagueType: LeagueType;
  marketOpenWindows?: Maybe<Array<Maybe<MarketOpenWindowType>>>;
  name: Scalars['String'];
  season: Scalars['Int'];
  startWeek: Scalars['Int'];
  team?: Maybe<TeamObjectType>;
  teams?: Maybe<Array<Maybe<TeamObjectType>>>;
  transactions: Array<Maybe<StockMarketLeagueUserBalanceLogObjectType>>;
  user?: Maybe<UserObjectType>;
  userIsCommissioner: Scalars['Boolean'];
  users?: Maybe<Array<Maybe<UserObjectType>>>;
  weekZeroType: WeekZeroType;
};

export type LeagueObjectTypeGamesArgs = {
  week?: InputMaybe<Scalars['Int']>;
};

export type LeagueObjectTypeTeamArgs = {
  id: Scalars['UUID'];
};

export type LeagueObjectTypeUserArgs = {
  id: Scalars['UUID'];
};

export type LeagueObjectTypeUsersArgs = {
  orderByScore?: InputMaybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum LeagueType {
  PickEm = 'PICK_EM',
  StockMarket = 'STOCK_MARKET',
}

export type MarketOpenWindowType = {
  __typename?: 'MarketOpenWindowType';
  close: Scalars['DateTime'];
  open: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  buyTeam?: Maybe<BuyTeam>;
  cancelLeagueInvitation?: Maybe<CancelLeagueInvitation>;
  changePassword?: Maybe<ChangePassword>;
  createLeague?: Maybe<CreateLeague>;
  createLeagueInvitation?: Maybe<CreateLeagueInvitation>;
  createPick?: Maybe<CreatePick>;
  respondToLeagueInvitation?: Maybe<RespondToLeagueInvitation>;
  sellTeam?: Maybe<SellTeam>;
};

export type MutationBuyTeamArgs = {
  input: BuyTeamInputType;
};

export type MutationCancelLeagueInvitationArgs = {
  input: CancelLeagueInvitationInputType;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInputType;
};

export type MutationCreateLeagueArgs = {
  input: CreateLeagueInputType;
};

export type MutationCreateLeagueInvitationArgs = {
  input: CreateLeagueInvitationInputType;
};

export type MutationCreatePickArgs = {
  input: CreatePickInputType;
};

export type MutationRespondToLeagueInvitationArgs = {
  input: RespondToLeagueInvitationInputType;
};

export type MutationSellTeamArgs = {
  input: SellTeamInputType;
};

export type PickEmLeagueGamePickObjectType = {
  __typename?: 'PickEmLeagueGamePickObjectType';
  game?: Maybe<GameObjectType>;
  id: Scalars['UUID'];
  league?: Maybe<LeagueObjectType>;
  pickHidden: Scalars['Boolean'];
  pickedHomeTeam?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum PriceChangeType {
  GameWon = 'GAME_WON',
  InitialPrice = 'INITIAL_PRICE',
  InitialPurchases = 'INITIAL_PURCHASES',
  NewRatings = 'NEW_RATINGS',
  SharesPurchased = 'SHARES_PURCHASED',
  SharesSold = 'SHARES_SOLD',
}

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<UserObjectType>;
  games?: Maybe<Array<Maybe<GameObjectType>>>;
  invitations?: Maybe<Array<Maybe<LeagueInvitationObjectType>>>;
  league?: Maybe<LeagueObjectType>;
  leagues?: Maybe<Array<Maybe<LeagueObjectType>>>;
  teams?: Maybe<Array<Maybe<TeamObjectType>>>;
  toggles?: Maybe<Array<Maybe<ToggleObjectType>>>;
};

export type QueryGamesArgs = {
  season?: InputMaybe<Scalars['Int']>;
  week?: InputMaybe<Scalars['Int']>;
};

export type QueryLeagueArgs = {
  id: Scalars['UUID'];
};

export type QueryLeaguesArgs = {
  commissioner?: InputMaybe<Scalars['Boolean']>;
};

export type RespondToLeagueInvitation = {
  __typename?: 'RespondToLeagueInvitation';
  league?: Maybe<LeagueObjectType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type RespondToLeagueInvitationInputType = {
  accept: Scalars['Boolean'];
  invitationId: Scalars['UUID'];
};

export type SellTeam = {
  __typename?: 'SellTeam';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SellTeamInputType = {
  leagueId: Scalars['UUID'];
  sharePrice: Scalars['Decimal'];
  shares: Scalars['Int'];
  teamId: Scalars['UUID'];
};

export type StockMarketLeaguePositionObjectType = {
  __typename?: 'StockMarketLeaguePositionObjectType';
  costBasis: Scalars['Decimal'];
  id: Scalars['UUID'];
  quantity: Scalars['Int'];
  team: TeamObjectType;
  user?: Maybe<UserObjectType>;
};

export type StockMarketLeagueTeamPriceLogObjectType = {
  __typename?: 'StockMarketLeagueTeamPriceLogObjectType';
  created: Scalars['DateTime'];
  data: Scalars['CamelCasedScalar'];
  id: Scalars['UUID'];
  league: LeagueObjectType;
  price: Scalars['Decimal'];
  priceChangeType: PriceChangeType;
  team: TeamObjectType;
};

export type StockMarketLeagueUserBalanceLogObjectType = {
  __typename?: 'StockMarketLeagueUserBalanceLogObjectType';
  balance: Scalars['Decimal'];
  balanceChangeType: BalanceChangeType;
  created: Scalars['DateTime'];
  data: Scalars['CamelCasedScalar'];
  id: Scalars['UUID'];
  league: LeagueObjectType;
  user: UserObjectType;
};

export type Subscription = {
  __typename?: 'Subscription';
  leagueCache?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionLeagueCacheArgs = {
  leagueId: Scalars['UUID'];
};

export type TeamObjectType = {
  __typename?: 'TeamObjectType';
  abbreviation: Scalars['String'];
  classification: Scalars['String'];
  currentGame?: Maybe<GameObjectType>;
  currentRank?: Maybe<Scalars['Int']>;
  games: Array<Maybe<GameObjectType>>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  nickname: Scalars['String'];
  positions?: Maybe<Array<Maybe<StockMarketLeaguePositionObjectType>>>;
  priceLogs?: Maybe<Array<Maybe<StockMarketLeagueTeamPriceLogObjectType>>>;
  shortName: Scalars['String'];
  stockPurchasePrices?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  stockSalePrice?: Maybe<Scalars['Decimal']>;
  styles?: Maybe<TeamStylesObjectType>;
};

export type TeamObjectTypeStockPurchasePricesArgs = {
  maxValue?: InputMaybe<Scalars['Decimal']>;
};

export type TeamStylesObjectType = {
  __typename?: 'TeamStylesObjectType';
  logoComponent?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type ToggleObjectType = {
  __typename?: 'ToggleObjectType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  on: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
};

export type UserObjectType = {
  __typename?: 'UserObjectType';
  balanceLogs?: Maybe<Array<Maybe<StockMarketLeagueUserBalanceLogObjectType>>>;
  cashBalance?: Maybe<Scalars['Decimal']>;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isEmailVerified: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  picks?: Maybe<Array<Maybe<PickEmLeagueGamePickObjectType>>>;
  position?: Maybe<StockMarketLeaguePositionObjectType>;
  positions?: Maybe<Array<Maybe<StockMarketLeaguePositionObjectType>>>;
  totalPayout?: Maybe<Scalars['Decimal']>;
  totalScore?: Maybe<Scalars['Int']>;
  weekPayouts?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  weekScores?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UserObjectTypePicksArgs = {
  week?: InputMaybe<Scalars['Int']>;
};

export type UserObjectTypePositionArgs = {
  teamId: Scalars['UUID'];
};

/** An enumeration. */
export enum WeekZeroType {
  Ignore = 'IGNORE',
  Practice = 'PRACTICE',
  Regular = 'REGULAR',
}
