import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AdminPanelSettings as AdminPanelSettingsIcon } from '@mui/icons-material';
import { Box, Button, Pagination, PaginationItem, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery, useSubscription } from 'urql';
import { useAccountContext } from '../../../../contexts/account';
import { useFlashMessageContext } from '../../../../contexts/flashMessage';
import { useToggleContext } from '../../../../contexts/toggle';
import {
  CreatePickMutation,
  CreatePickMutationVariables,
  FetchPickEmLeagueDetailQuery,
  FetchPickEmLeagueDetailQueryVariables,
  LeagueCacheSubscription,
  LeagueCacheSubscriptionVariables,
} from '../../../../generated/hooks';
import { CreatePickInputType } from '../../../../generated/schemas';
import DesktopPayoutsTable from './DesktopPayoutsTable';
import DesktopPicksTable from './DesktopPicksTable';
import DesktopStandingsTable from './DesktopStandingsTable';
import MobilePayoutsTable from './MobilePayoutsTable';
import MobilePicksTable from './MobilePicksTable';
import MobileStandingsTable from './MobileStandingsTable';

const fetchPickEmLeagueDetail = gql`
  query fetchPickEmLeagueDetail($leagueId: UUID!, $week: Int!) {
    league(id: $leagueId) {
      id
      season
      name
      leagueType
      userIsCommissioner
      startWeek
      commissioner {
        id
        fullName
      }
      games(week: $week) {
        id
        awayRank
        awayScore
        homeOdds
        homeRank
        homeScore
        kickoffDateTime
        neutralSite
        status
        homeTeam {
          id
          name
          styles {
            primaryColor
            secondaryColor
            logoComponent
          }
        }
        awayTeam {
          id
          name
          styles {
            primaryColor
            secondaryColor
            logoComponent
          }
        }
        liveScoreData {
          clock
          quarter
        }
      }
      users(orderByScore: true) {
        id
        fullName
        totalPayout
        weekPayouts
        totalScore
        weekScores
        picks(week: $week) {
          id
          pickHidden
          pickedHomeTeam
          game {
            id
          }
        }
      }
    }
  }
`;

const createPick = gql`
  mutation createPick($createPickInput: CreatePickInputType!) {
    createPick(input: $createPickInput) {
      ok
    }
  }
`;

type LeagueCacheSubscriptionResult = boolean;

const leagueCache = gql`
  subscription leagueCache($leagueId: UUID!) {
    leagueCache(leagueId: $leagueId)
  }
`;

const baseItems = [
  { name: 'Standings', page: 1, week: null },
  { name: 'Payouts', page: 2, week: null },
];

type ScoresTablesProps = {
  leagueId: string;
  season: number;
};

function ScoresTables({ leagueId, season }: ScoresTablesProps) {
  const { userId } = useAccountContext();
  const { addFlashMessage } = useFlashMessageContext();
  const { getToggle } = useToggleContext();
  const navigate = useNavigate();

  const currentWeekToggle = getToggle('currentWeek')?.value;
  const currentWeek = currentWeekToggle && JSON.parse(currentWeekToggle)[season];

  const lastWeekToggle = getToggle('seasonSettings')?.value;
  const lastWeek: number = lastWeekToggle && JSON.parse(lastWeekToggle)[season].lastWeek;

  const [items, setItems] = useState<{ name: string; page: number; week: number }[]>(baseItems);
  const [item, setItem] = useState(baseItems[0]);
  const [initialLoad, setInitialLoad] = useState(false);

  const [{ data, error }, refetch] = useQuery<FetchPickEmLeagueDetailQuery, FetchPickEmLeagueDetailQueryVariables>({
    query: fetchPickEmLeagueDetail,
    variables: { leagueId, week: item.week },
    pause: typeof item.week !== 'number',
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });
  const [, createPickMutation] = useMutation<CreatePickMutation, CreatePickMutationVariables>(createPick);
  const handleCreatePick = useCallback(
    (createPickInput: CreatePickInputType) => createPickMutation({ createPickInput }),
    [createPickMutation],
  );

  useEffect(() => {
    if (currentWeek !== undefined && !initialLoad) {
      setInitialLoad(true);
      setItem({ ...baseItems[0], week: currentWeek });
    }
  }, [currentWeek, initialLoad]);

  useEffect(() => {
    if (!data?.league || currentWeek === undefined) {
      return;
    }
    const newItems = [...baseItems];
    for (let i = data.league.startWeek; i <= currentWeek; i++) {
      newItems.push({ name: i === lastWeek ? 'Bowls' : `Week ${i}`, page: newItems.length + 1, week: i });
    }
    setItems(newItems);
    setItem(newItems.find(newItem => newItem.week === currentWeek) || newItems[0]);
  }, [currentWeek, data?.league?.startWeek]);

  const handleLeagueCacheSubscription = useCallback(
    (oldState: LeagueCacheSubscriptionResult, response: LeagueCacheSubscription): LeagueCacheSubscriptionResult => {
      refetch({ requestPolicy: 'network-only' });
      return response.leagueCache;
    },
    [refetch],
  );
  useSubscription<LeagueCacheSubscription, LeagueCacheSubscriptionResult, LeagueCacheSubscriptionVariables>(
    {
      query: leagueCache,
      variables: { leagueId },
    },
    handleLeagueCacheSubscription,
  );

  useEffect(() => {
    if (data && !data.league && error?.graphQLErrors[0].message === 'League matching query does not exist.') {
      addFlashMessage('warning', `League with ID ${leagueId} does not exist`);
      navigate('/', { replace: true });
    }
  }, [data, error]);

  const weekPickUsers = useMemo(() => {
    if (!data?.league) {
      return null;
    }
    return data?.league?.users.map(user => ({
      ...user,
      picks: data?.league.games.map(game => {
        const pick = user.picks.find(p => p.game.id === game.id);
        return {
          gameId: game.id,
          pickId: pick ? pick.id : null,
          pickHidden: pick ? pick.pickHidden : null,
          pickedHomeTeam: pick ? pick.pickedHomeTeam : null,
          pickedTeamId: pick ? (pick.pickedHomeTeam ? game.homeTeam.id : game.awayTeam.id) : null,
          pickedTeamName: pick ? (pick.pickedHomeTeam ? game.homeTeam.name : game.awayTeam.name) : null,
        };
      }),
    }));
  }, [data?.league]);

  if (!data?.league) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, flexGrow: 1 }}>
      {data.league.userIsCommissioner && (
        <Box sx={{ textAlign: 'right' }}>
          <Button
            variant="outlined"
            startIcon={<AdminPanelSettingsIcon />}
            component={Link}
            to={`/league/${leagueId}/settings`}
          >
            Manage League
          </Button>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          color="primary"
          count={items.length}
          page={item.page}
          renderItem={({ page: itemPage, onClick: _, ...itemProps }) => {
            return (
              <PaginationItem
                sx={{ px: 2 }}
                page={items[itemPage - 1]?.name}
                onClick={() => setItem(items[itemPage - 1])}
                {...itemProps}
              />
            );
          }}
        />
      </Box>
      {item.page === 1 ? (
        <>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobileStandingsTable
              currentWeek={currentWeek}
              lastWeek={lastWeek}
              startWeek={data.league.startWeek}
              weekPickUsers={weekPickUsers}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <DesktopStandingsTable
              currentWeek={currentWeek}
              lastWeek={lastWeek}
              startWeek={data.league.startWeek}
              weekPickUsers={weekPickUsers}
            />
          </Box>
        </>
      ) : item.page === 2 ? (
        <>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobilePayoutsTable
              currentWeek={currentWeek}
              lastWeek={lastWeek}
              startWeek={data.league.startWeek}
              weekPickUsers={weekPickUsers}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <DesktopPayoutsTable
              currentWeek={currentWeek}
              lastWeek={lastWeek}
              startWeek={data.league.startWeek}
              weekPickUsers={weekPickUsers}
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobilePicksTable
              data={data}
              leagueId={leagueId}
              week={item.week}
              weekPickUsers={weekPickUsers}
              userId={userId}
              onCreatePick={handleCreatePick}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <DesktopPicksTable
              data={data}
              leagueId={leagueId}
              week={item.week}
              weekPickUsers={weekPickUsers}
              userId={userId}
              onCreatePick={handleCreatePick}
            />
          </Box>
        </>
      )}
    </Paper>
  );
}

export default ScoresTables;
