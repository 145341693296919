import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LeagueSettings from './LeagueSettings';
import ScoresTables from './ScoresTables';

type PickEmLeagueDetailProps = {
  leagueId: string;
  season: number;
  userIsCommissioner: boolean;
};

function PickEmLeagueDetail({ leagueId, season, userIsCommissioner }: PickEmLeagueDetailProps) {
  return (
    <Routes>
      {userIsCommissioner && <Route path="settings" element={<LeagueSettings leagueId={leagueId} />} />}
      <Route index element={<ScoresTables leagueId={leagueId} season={season} />} />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
}

export default PickEmLeagueDetail;
