import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function PurdueLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 298 160.41" {...restProps}>
      <g transform="matrix(2.2533 0 0 2.2533 .12941 -.59454)">
        <path
          d="m0.3 71.1 11-25.9h14.2l7.9-18.5h-14.2l11-25.9h78.1c6.9 0 16.5 0.8 21.1 7.7s1.1 15-1 19.9c-5.6 13.1-13.7 21.8-34.9 21.8h-14.7l-8.8 20.9z"
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
        />
        <path
          d="m125.4 11.2c-2.5-3.8-7.9-5.5-17-5.5h-75l-6.9 16.3h14.1l-11.9 28h-14.2l-6.9 16.3h59.2l6.9-16.3h-14.3l1.9-4.5h32.2c20.7 0 26.3-9 30.5-18.9 2.3-5.4 4.3-11 1.4-15.4zm-54.1 10.8h23.7c1.7 0 2.5 0.4 2.7 0.7 0.2 0.2 0.1 0.9-0.3 2-1.2 2.8-4.1 4.6-7.6 4.6h-21.6z"
          fill="#9d9795"
        />
        <path
          d="m108.4 8.2h-73.3l-4.8 11.3h14.1l-14.1 33h-14.1l-4.8 11.3h53.8l4.8-11.3h-14.4l4.1-9.5h33.9c19.4 0 24.3-8.2 28.2-17.4s6-17.4-13.4-17.4zm-18.6 23.5h-25.3l5.2-12.2h25.3c4.5 0 6.3 2.3 4.7 6.1-1.7 3.8-5.4 6.1-9.9 6.1z"
          fill={variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default PurdueLogo;
