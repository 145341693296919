import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function KennesawStateLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 89.203117 84.007813" {...restProps}>
      <g>
        <path
          fill="#c5c6c8"
          d="m18.376 0.92581 6.6934 12.721v0.05664h-24.004l4.6328 8.207c-0.48625 1.095-0.75914 2.3066-0.74414 3.5078v9.5762c-0.10875 4.7762 3.8595 7.9312 7.8945 9.5762h-7.8711v12.514c-0.2375 9.5262 13.449 10.243 20.092 10.883v2.248l-6.6973 12.719h27.004v-14.148c1.825 0 3.6502-0.01 5.4727-0.03125l3.9609 4.8496-4.9199 9.3281h38.199l-10.525-12.873c1.915-0.47375 3.2754-1.6581 3.2754-3.0469 0-0.86625-0.53063-1.6513-1.3906-2.2363 2.81-1.6225 4.8766-4.3014 4.8516-7.6914v-8.502c-0.008-4.683-2.087-8.188-6.397-10.133h6.3379v-10.498c0-1.555 0.135-3.2276-0.28125-4.7363-1.055-3.8288-4.7923-5.8287-8.3223-6.9199l12.631-15.371h-38.289c1.6262 3.135 3.256 6.2705 4.8848 9.4043l-2.8691 3.4902c-2.3675-0.06375-4.6254-0.09883-6.6191-0.11133v-12.783h-27z"
        />
        <path
          d="m22.754 3.5703l-0.002 0.002h0.004l-0.002-0.002zm0.002 0.002l4.957 9.4217v3.354h-22.117l3.1364 5.556c-0.7075 0.982-1.1516 2.283-1.1328 3.514v9.578c-0.0475 6.448 9.3414 8.294 14.119 9.297 1.996 0.341 3.995 0.671 5.994 0.996v7.457c-1.97-0.112-3.931-0.281-5.877-0.527v-5.006h-14.213v9.873c-0.0425 6.609 10.508 7.43 15.176 7.98 1.635 0.193 3.273 0.346 4.914 0.477v5.326l-4.961 9.424h19.98v-14.164c0.854 0.006 1.708 0.01 2.561 0.012 2.262 0.003 4.527-0.009 6.795-0.049l5.881 7.197-3.694 7h28.237l-12.625-15.441c5.116-0.715 11.771-2.361 11.771-7.762v-8.502c0-1.608-0.258-3.443-0.84-4.408-2.105-3.495-6.751-4.315-10.367-5.172-1.536-0.365-7.554-1.378-14.793-2.555l5.51-6.705c2.221 0.125 4.341 0.313 6.27 0.596v5.467h14.158v-10.405c0.06-4.596-5.217-6.276-8.846-7.15-0.546-0.131-1.166-0.254-1.836-0.369l11.762-14.311-28.344 0.0003c0.768 1.4763 3.684 7.0897 3.684 7.0897l-4.799 5.842c-3.509-0.11-6.849-0.154-9.512-0.154h-0.973v-12.778l-19.976 0.0003zm54.012 62.071c-0.812 0-1.457 0.628-1.457 1.412 0 0.802 0.645 1.433 1.457 1.433 0.818 0 1.447-0.631 1.447-1.433 0-0.784-0.629-1.412-1.447-1.412zm0 0.283v0.002c0.628 0 1.086 0.508 1.086 1.138 0 0.62-0.457 1.12-1.079 1.12-0.637 0-1.103-0.501-1.103-1.131 0-0.62 0.466-1.129 1.096-1.129zm-0.008 0.414c-0.234 0-0.415 0.019-0.545 0.045v1.414h0.328v-0.57h0.156c0.181 0 0.266 0.068 0.291 0.222 0.044 0.165 0.071 0.295 0.114 0.348h0.355c-0.036-0.053-0.062-0.139-0.105-0.356-0.043-0.187-0.122-0.291-0.26-0.343v-0.018c0.172-0.051 0.295-0.173 0.295-0.328 0-0.138-0.061-0.249-0.139-0.309-0.105-0.06-0.224-0.105-0.49-0.105zm-0.028 0.234c0.207 0 0.303 0.085 0.303 0.215 0 0.146-0.147 0.207-0.328 0.207h-0.154v-0.406c0.033-0.008 0.093-0.016 0.179-0.016z"
          fill={colors.secondaryColor}
          transform="translate(0 -.00014877)"
        />
        <path
          d="m27.715 29.699c-1.964 0.11-3.927 0.272-5.885 0.51v0.578c0.699 0.265 3.742 0.899 4.305 0.986 0.47 0.074 1.002 0.157 1.58 0.248v-2.322zm29.951 20.205l2.41 2.946c2.449-0.109 4.907-0.315 7.377-0.686v-0.523c-0.716-0.274-1.713-0.442-2.635-0.625-2.383-0.378-4.766-0.745-7.152-1.112z"
          fill="#c5c6c8"
          transform="translate(0 -.00014877)"
        />
        <path
          d="m28.885 7.2735 2.5293 4.8066v4.2695h7.6152v-9.0762h-10.145zm31.533 0 2.0117 3.8437-23.4 28.486v-10.121c-2.5362-0.01-5.0777 0.000312-7.6152 0.07031v23.342c2.5362 0.06875 5.079 0.07555 7.6152 0.06055v-8.6953l23.355 28.588-1.9727 3.7402h14.291l-28.34-34.658 28.477-34.656h-14.422zm-48.49 12.769 1.512 2.678c-1.4612 0.74125-2.1465 1.636-2.1465 2.6973v9.5762c0 2.1088 3.524 4.0822 11.178 5.6797l5.2422 0.83008v-5.7441l-3.8828-0.60352c-2.7825-0.53125-5.6953-1.6382-5.6953-2.6895v-4.8379c0-1.9338 16.227-1.753 27.377-1.748l4.7676-5.8047c-1.42-0.02-2.8794-0.0332-4.3906-0.0332h-33.961zm56.264 1.151-4.127 5.023c4.0062 0.25125 7.0684 0.68031 7.0684 1.4141v4.4824h6.7656v-6.709c0-1.9325-3.4595-3.3284-9.707-4.2109zm-15.205 18.506-1.8379 2.2363 3.084 3.7715 11.213 1.7754c2.7 0.53 5.7012 1.6507 5.7012 2.6895v4.1719c0 0.97-3.472 1.5344-8.332 1.8594l4.2988 5.2539c6.995-0.86875 10.85-2.3148 10.85-4.3711v-8.5c0-1.7325-0.20617-2.3137-0.35742-2.5762-1.0062-1.7638-3.2386-2.4606-13.309-4.5469l-11.311-1.764zm-41.668 11.211v6.1758c0 3.6262 14.702 5.3594 34.609 5.3594 1.0812 0 2.1335-0.0069 3.166-0.01563l-4.75-5.8145c-6.355 0.0175-26.205 0.01734-26.205-1.7539v-3.9512h-6.8203zm20.096 14.889v5.9863l-2.5293 4.8047h10.145v-10.516c-2.5388-0.05-5.0802-0.13539-7.6152-0.27539z"
          fill={colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default KennesawStateLogo;
