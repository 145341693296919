import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function NcStateLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 188.85156 225.34377" {...restProps}>
      <g>
        <path
          d="M39.6624,7.72815,7.704,41.08815l-.0008,65.6,29.9136,30.47839H7.7032l.0008,48.08161,32.0672,32.4H149.064l32.0896-32.4V116.18093l-27.6512-27.984h27.6512V41.08814l-31.9808-33.36L39.664,7.72829Zm3.2496,7.60928H145.9216l27.6144,28.8128v36.4272H132.7664v11.4688h13.984L173.536,119.515v62.5968l-27.6384,27.92H42.95359l-27.6352-27.92.00032-37.3456h39.1568v-13.048H42.93791l-27.6192-28.1408.00032-59.4272,27.5936-28.8128Zm44.0416,39.6784,25.09921,19.64v-19.64Zm-11.6512,17.344v19.6864h25.5792Zm.032,67.8432v20.4688h36.7184V153.531h14.76v-6.5376h-14.76V140.203Z"
          fill={colors.secondaryColor}
        />
        <path
          d="M44.5376,19.15039,19.1248,45.68159V102.016L44.5376,127.9152H55.8912l11.7712-8.39041h52.056l13.0688,9.4688v18h-2.1776v6.5376h2.1776V172.496l-12.8896,9.28H67.8752l-13.4-9.76v-23.4384H19.1248V180.544l25.4128,25.68h99.76l25.43039-25.68V121.056l-24.696-25.2032H132.7664v5.9904H113.6256l-7.7968-5.9904H75.30239v5.89761l-20.82719-.02881V45.34718H74.5936l7.4912,5.8656h29.968v-5.8656h20.7136v31.4272H169.728V45.68158L144.2976,19.15038h-99.76Zm13.744,30v48.8032H71.50081v-33.32L114.912,98.04h14.04161V49.15039h-13.088v33.3344l-42.584-33.3344h-15Zm10.6,74.1872-10.6,7.5472V170.08l10.70881,7.904h49.688L129,170.56V157.344H115.8656v7.136H71.5312V136.4h44.3344v6.78719H129v-12.2656l-10.5152-7.584Z"
          fill={colors.primaryColor}
        />
        <path
          d="M186.84832,187.59984v-73.56l-19.472-20.1344h19.472V38.79025L151.60991,2.00945l-114.384-.00016-.00032.00014L1.9904,38.79023l.00016,70.2352,22.016,22.4272H1.99056l.00016,56.14719,35.4064,35.76h114.056ZM39.6624,7.72815H149.17121l31.98079,33.36V88.197H153.50081L181.152,116.181v69.0672l-32.0896,32.4H39.7712l-32.0672-32.4-.0008-48.08161H37.6168L7.7032,106.68812l.0008-65.6,31.95841-33.36Zm3.2496,7.60928L15.3184,44.15023l.00032,59.4272,27.6192,28.1408h11.5376v13.048H15.31872l.00032,37.3456,27.6352,27.92h102.944l27.63841-27.92V119.515L146.751,92.04623H132.767V80.57743h40.76961V44.15023l-27.61441-28.8128L42.91265,15.33727Zm1.6256,3.813h99.76l25.4304,26.5312v31.0928h-36.9616V45.344h-20.7136v5.8688h-29.968l-7.4912-5.8688H54.47521V101.7184l20.8272.032V95.8528h30.5264l7.7968,5.9904h19.1408V95.8528H145.032l24.696,25.2032v59.488l-25.4304,25.68h-99.76l-25.4128-25.68v-31.9664h35.3504V172.016l13.4,9.76h52.0224l12.8896-9.28V153.53118h-2.1776v-6.5376h2.1776v-18l-13.0688-9.4688h-52.056l-11.7712,8.39041H44.53761L19.12481,102.016V45.68159Zm13.744,30h15l42.584,33.3344V49.15039h13.088V98.04H114.912L71.5008,64.63359v33.32H58.2816Zm28.672,5.8656h25.09921v19.64ZM75.3024,72.36l25.5792,19.6864H75.3024Zm-6.4208,50.9776h49.6032L129,130.92158v12.2656H115.8656V136.4H71.5312v28.08h44.3344v-7.136H129V170.56l-10.3216,7.424h-49.688l-10.7088-7.904V130.88479Zm6.4528,16.8656h36.7184v6.7904h14.76v6.5376h-14.76v7.1408H75.3344Z"
          fill="#ffffff"
        />
      </g>
    </SvgIcon>
  );
}

export default NcStateLogo;
