import React, { useCallback } from 'react';
import { Add as AddIcon, Cancel as CancelIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { gql, useMutation } from 'urql';
import { CreateLeagueMutation, CreateLeagueMutationVariables } from '../../generated/hooks';
import { CreateLeagueInputType, LeagueType } from '../../generated/schemas';
// import { leagueTypeOptions } from '../../utils';
import InputField from '../forms/InputField';
// import SelectField from '../forms/SelectField';

const createLeague = gql`
  mutation createLeague($createLeagueInput: CreateLeagueInputType!) {
    createLeague(input: $createLeagueInput) {
      ok
      league {
        id
      }
    }
  }
`;

type CreateLeagueModalProps = {
  onClose: () => void;
};

function CreateLeagueModal({ onClose }: CreateLeagueModalProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      leagueName: '',
      // leagueType: '' as LeagueType,
      invitationEmails: [] as { value: string }[],
    },
  });
  const invitationEmails = useFieldArray({ control, name: 'invitationEmails' });

  const [, createLeagueMutation] = useMutation<CreateLeagueMutation, CreateLeagueMutationVariables>(createLeague);
  const handleCreateLeague = useCallback(
    (createLeagueInput: CreateLeagueInputType) => createLeagueMutation({ createLeagueInput }),
    [createLeagueMutation],
  );
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create League</DialogTitle>
      <form
        onSubmit={handleSubmit(
          ({
            leagueName,
            //  leagueType,
            invitationEmails,
          }) => {
            return handleCreateLeague({
              name: leagueName,
              leagueType: LeagueType.PickEm,
              invitationEmails: invitationEmails.map(invitationEmail => invitationEmail.value),
            }).then(response => {
              if (!response.error) {
                onClose();
              }
            });
          },
        )}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                control={control}
                label="League Name"
                name="leagueName"
                rules={{ required: 'Required' }}
                sx={{ width: '100%' }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <SelectField
                control={control}
                label="League Type"
                name="leagueType"
                options={leagueTypeOptions}
                rules={{ required: 'Required' }}
                sx={{ width: '100%' }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Divider sx={{ mt: 2, mx: -3 }} />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                Invitations
              </Typography>
              <IconButton color="primary" onClick={() => invitationEmails.append({ value: '' })}>
                <AddIcon />
              </IconButton>
            </Grid>
            {invitationEmails.fields.map((field, index) => (
              <Grid key={field.id} item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <InputField
                  control={control}
                  label="Email Address"
                  name={`invitationEmails.${index}.value`}
                  rules={{ required: 'Required' }}
                  sx={{ width: '100%' }}
                />
                <IconButton color="error" onClick={() => invitationEmails.remove(index)} sx={{ ml: 1 }}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateLeagueModal;
