import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { gql, useMutation, useQuery } from 'urql';
import {
  BuyTeamMutation,
  BuyTeamMutationVariables,
  FetchTeamPurchasePricesQuery,
  FetchTeamPurchasePricesQueryVariables,
} from '../../../../../generated/hooks';
import { BuyTeamInputType, TeamObjectType } from '../../../../../generated/schemas';
import ActionModal from './ActionModal';
import { getMarketInfo } from './utils';

const fetchTeamPurchasePrices = gql`
  query fetchTeamPurchasePrices($leagueId: UUID!, $teamId: UUID!, $cashBalance: Decimal!) {
    league(id: $leagueId) {
      marketOpenWindows {
        open
        close
      }
      team(id: $teamId) {
        stockPurchasePrices(maxValue: $cashBalance)
        currentGame {
          kickoffDateTime
        }
      }
    }
  }
`;

const buyTeam = gql`
  mutation buyTeam($buyTeamInput: BuyTeamInputType!) {
    buyTeam(input: $buyTeamInput) {
      ok
    }
  }
`;

export default function BuyModal({
  leagueId,
  cashBalance,
  team,
  onClose,
  refetch,
}: {
  leagueId: string;
  cashBalance: currency;
  team: Pick<TeamObjectType, 'id' | 'name' | 'styles'>;
  onClose(): void;
  refetch(): void;
}) {
  const [shares, setShares] = useState(1);
  const [{ data, fetching }] = useQuery<FetchTeamPurchasePricesQuery, FetchTeamPurchasePricesQueryVariables>({
    query: fetchTeamPurchasePrices,
    variables: { cashBalance: cashBalance.format({ symbol: '', separator: '' }), leagueId, teamId: team.id },
    context: useMemo(() => ({ requestPolicy: 'network-only' }), []),
  });

  const [, buyTeamMutation] = useMutation<BuyTeamMutation, BuyTeamMutationVariables>(buyTeam);
  const handleBuyTeam = useCallback(
    (buyTeamInput: BuyTeamInputType) => buyTeamMutation({ buyTeamInput: buyTeamInput }),
    [buyTeamMutation],
  );

  const maxShares = data?.league.team.stockPurchasePrices.length;
  const totalCost = data?.league.team.stockPurchasePrices
    .slice(0, shares)
    .reduce((cost, stockPurchasePrice) => cost.add(currency(stockPurchasePrice)), currency(0));

  const marketInfo = getMarketInfo(data?.league.marketOpenWindows, data?.league.team.currentGame?.kickoffDateTime);

  const [_, setRenderKey] = useState(0);
  useEffect(() => {
    if (marketInfo.open && marketInfo.closeDateTime) {
      const timeout = setTimeout(() => setRenderKey(key => key + 1), marketInfo.closeDateTime.getTime() - Date.now());
      return () => clearTimeout(timeout);
    }
    if (!marketInfo.open && marketInfo.openDateTime) {
      const timeout = setTimeout(() => setRenderKey(key => key + 1), marketInfo.openDateTime.getTime() - Date.now());
      return () => clearTimeout(timeout);
    }
  }, [marketInfo]);

  return (
    <ActionModal
      action="Buy"
      buttonText={`Buy ${shares} share${shares > 1 ? 's' : ''}`}
      marketInfo={marketInfo}
      team={team}
      onSubmit={() =>
        handleBuyTeam({
          leagueId: leagueId,
          totalPrice: totalCost.format({ symbol: '', separator: '' }),
          shares,
          teamId: team.id,
        })
      }
      onClose={onClose}
      refetch={refetch}
    >
      {fetching ? (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Shares to Buy</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2} sx={{ justifyContent: 'right', alignItems: 'center' }}>
                    <IconButton color="primary" disabled={shares === 1} onClick={() => setShares(s => s - 1)}>
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="h5">{shares}</Typography>
                    <IconButton color="primary" disabled={shares === maxShares} onClick={() => setShares(s => s + 1)}>
                      <AddIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Available Balance</TableCell>
                <TableCell align="right">{cashBalance.format()}</TableCell>
              </TableRow>
              {shares === 1 ? (
                <TableRow>
                  <TableCell>Share Price</TableCell>
                  <TableCell align="right">{totalCost.format()}</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>Low / Average / High Share Price</TableCell>
                  <TableCell align="right">
                    {currency(data?.league.team.stockPurchasePrices[0]).format()} / {totalCost.divide(shares).format()}{' '}
                    / {currency(data?.league.team.stockPurchasePrices[shares - 1]).format()}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Total Cost</TableCell>
                <TableCell align="right">{totalCost.format()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Balance Remaining After Purchase</TableCell>
                <TableCell align="right">{cashBalance.subtract(totalCost).format()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </ActionModal>
  );
}
