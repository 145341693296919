import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function AlabamaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 240" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 12.005 711.6)">
        <path
          d="m136.55 472.35-25.101-3.0583c12.507 20.01 19.35 38.511 39.514 58.802-5.5592-17.725-10.088-37.832-14.413-55.744m49.369-68.577c-17.091-8.8241-29.936-6.9752-31.302 3.678-1.5404 11.917-0.69179 28.682-0.1294 35.075 0.20903 2.3416 0.91825 9.9041 1.3687 12.188 5.8379 1.2169 13.637 4.0686 13.637-6.0768 2.9488 1.503 8.3588 5.94 9.287 10.651 5.1486 24.347-20.368 17.962-20.368 17.962 6.2236 28.762 12.915 59.392 27.164 84.667l-14.204 6.806c-9.6129-7.3584-19.296-14.809-27.373-16.013-7.3733-1.7792-52.454-5.3178-49.284 15.319-5.0367-0.40313-7.8685-6.3282-7.8387-11.442 1.6275-22.269 30.571-17.855 48.194-20.035-18.188-22.772-34.172-46.353-48.615-70.769-11.447-2.1898-12.955 12.009-11.006 15.919 0 0-7.5525-2.1202-10.481-10.389-3.5361-8.6897 0.46783-23.389 12.788-24.21-12.196-21.456-26.821-56.61-56.744-49.585-10.014 2.0654-21.704 19.019-15.652 29.103 3.7128 5.1636-1.7917 8.7843-8.1074 1.1273-15.528-17.136-0.54746-51.359 31.984-49.301 34.978-0.75899 58.578 42.712 71.314 68.54l33.166 5.2133-2.0978-27.062c-4.1831-67.3 44.257-41.323 44.257-41.323 6.7338 3.0558 13.786 10.424 13.612 19.009-0.36332 2.8916-1.9634 1.7693-3.5685 0.95059"
          fillOpacity=".94118"
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default AlabamaLogo;
