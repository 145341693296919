import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function IllinoisLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 200 288.895" {...restProps}>
      <g>
        <path
          fill={variant === 'main' ? colors.secondaryColor : '#ffffff'}
          d="m201.06 79.252v-77.781h-200v77.781h44.443v133.33h-44.443v77.781h200v-77.781h-44.443v-133.33h44.443z"
        />
        <path
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          d="m156.62 68.148h33.332v-55.552h-177.78v55.552h33.338c6.1309 0 11.11 4.9737 11.11 11.11v133.33c0 6.1368-4.9796 11.11-11.11 11.11h-33.338v55.557h177.78v-55.557h-33.332c-6.1368 0-11.11-4.9737-11.11-11.11v-133.33c0-6.1368 4.9737-11.11 11.11-11.11"
        />
      </g>
    </SvgIcon>
  );
}

export default IllinoisLogo;
