import React, { useMemo } from 'react';
import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import currency from 'currency.js';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { useAccountContext } from '../../../../../contexts/account';
import {
  FetchStockMarketTeamsOwnedQuery,
  FetchStockMarketTeamsOwnedQueryVariables,
} from '../../../../../generated/hooks';
import { TeamLogoComponent } from '../../../../logos';
import TeamActionButtons from '../TeamActionButtons';

const fetchStockMarketTeamsOwned = gql`
  query fetchStockMarketTeamsOwned($leagueId: UUID!, $userId: UUID!) {
    league(id: $leagueId) {
      user(id: $userId) {
        cashBalance
        fullName
        positions {
          id
          costBasis
          quantity
          team {
            id
            name
            stockPurchasePrices
            styles {
              primaryColor
              secondaryColor
              logoComponent
            }
          }
        }
      }
    }
  }
`;

export default function TeamsOwned() {
  const { userId: loggedInUserId } = useAccountContext();
  const { leagueId, userId } = useParams<{ leagueId: string; userId: string }>();
  const [{ data, fetching, stale }, refetch] = useQuery<
    FetchStockMarketTeamsOwnedQuery,
    FetchStockMarketTeamsOwnedQueryVariables
  >({
    query: fetchStockMarketTeamsOwned,
    variables: { leagueId, userId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  if (fetching || stale) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const loggedInUser = userId === loggedInUserId;
  const actionCells = loggedInUser && !isMdDown;
  const actionRows = loggedInUser && isMdDown;

  const positionValues = data.league.user.positions
    .map(position => {
      const costBasis = currency(position.costBasis);
      const sharePrice = currency(position.team.stockPurchasePrices[0]);
      const value = sharePrice.multiply(position.quantity);
      const change = value.subtract(costBasis);
      return {
        ...position,
        costBasis,
        sharePrice,
        value,
        change,
      };
    })
    .sort((left, right) => {
      if (left.value.intValue !== right.value.intValue) {
        return right.value.intValue - left.value.intValue;
      }
      return left.team.name.localeCompare(right.team.name);
    });

  const subtotalValue = positionValues.reduce((total, positionValue) => total.add(positionValue.value), currency(0));
  const totalCostBasis = positionValues.reduce(
    (total, positionValue) => total.add(positionValue.costBasis),
    currency(0),
  );
  const totalChange = positionValues.reduce((total, positionValue) => total.add(positionValue.change), currency(0));
  const cashBalance = currency(data.league.user.cashBalance);
  const totalValue = subtotalValue.add(cashBalance);

  return (
    <Table
      sx={{
        maxWidth: 1200,
        '& .MuiTableCell-sizeSmall': {
          px: 0.5,
        },
        '& .MuiTableCell-sizeSmall:first-of-type': {
          pl: 0,
        },
        '& .MuiTableCell-sizeSmall:last-of-type': {
          pr: 0,
        },
        '& .team-row .MuiTableCell-sizeSmall:not(.team-cell)': loggedInUser && {
          borderBottom: 0,
        },
        '& .team-actions-row .MuiTableCell-root': {
          pt: 0,
        },
      }}
      size={isMdDown ? 'small' : 'medium'}
    >
      <TableHead>
        <TableRow>
          {!isMdDown && <TableCell>Team</TableCell>}
          <TableCell colSpan={isMdDown ? 2 : 1} align="right">
            Shares Owned
          </TableCell>
          <TableCell align="right">Share Price</TableCell>
          <TableCell align="right">Value</TableCell>
          <TableCell align="right">Total Cost</TableCell>
          <TableCell align="right">Change</TableCell>
          {actionCells && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        {positionValues.map(positionValue => {
          return (
            <React.Fragment key={positionValue.id}>
              <TableRow className="team-row">
                <TableCell rowSpan={actionRows ? 2 : 1} className="team-cell">
                  <MuiLink component={Link} to={`/league/${leagueId}/team/${positionValue.team.id}`}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <TeamLogoComponent styles={positionValue.team.styles} />
                      {!isMdDown && <Box>{positionValue.team.name}</Box>}
                    </Stack>
                  </MuiLink>
                </TableCell>
                <TableCell align="right">{positionValue.quantity}</TableCell>
                <TableCell align="right">{positionValue.sharePrice.format()}</TableCell>
                <TableCell align="right">{positionValue.value.format()}</TableCell>
                <TableCell align="right">{positionValue.costBasis.format()}</TableCell>
                <TableCell align="right">
                  {positionValue.change.intValue > 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        color: 'success.main',
                      }}
                    >
                      <ArrowDropUpIcon />
                      <span>{positionValue.change.format()}</span>
                    </Box>
                  ) : positionValue.change.intValue < 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        color: 'error.main',
                      }}
                    >
                      <ArrowDropDownIcon />
                      <span>{positionValue.change.format({ negativePattern: '!#' })}</span>
                    </Box>
                  ) : null}
                </TableCell>
                {actionCells && (
                  <TableCell>
                    <TeamActionButtons
                      cashBalance={cashBalance}
                      costBasis={positionValue.costBasis}
                      shareQuantity={positionValue.quantity}
                      leagueId={leagueId}
                      team={positionValue.team}
                      refetch={() => refetch({ requestPolicy: 'network-only' })}
                    />
                  </TableCell>
                )}
              </TableRow>
              {actionRows && (
                <TableRow className="team-actions-row">
                  <TableCell colSpan={5}>
                    <TeamActionButtons
                      alignRight
                      cashBalance={cashBalance}
                      costBasis={positionValue.costBasis}
                      shareQuantity={positionValue.quantity}
                      leagueId={leagueId}
                      team={positionValue.team}
                      refetch={() => refetch({ requestPolicy: 'network-only' })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
        <TableRow>
          <TableCell colSpan={3}>Subtotal</TableCell>
          <TableCell align="right">{subtotalValue.format()}</TableCell>
          <TableCell align="right">{totalCostBasis.format()}</TableCell>
          <TableCell align="right">
            {totalChange.intValue > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                  color: 'success.main',
                }}
              >
                <ArrowDropUpIcon />
                <span>{totalChange.format()}</span>
              </Box>
            ) : totalChange.intValue < 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                  color: 'error.main',
                }}
              >
                <ArrowDropDownIcon />
                <span>{totalChange.format({ negativePattern: '!#' })}</span>
              </Box>
            ) : null}
          </TableCell>
          {actionCells && <TableCell />}
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>Cash Balance</TableCell>
          <TableCell align="right">{cashBalance.format()}</TableCell>
          <TableCell />
          <TableCell />
          {actionCells && <TableCell />}
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>Total Value</TableCell>
          <TableCell align="right">{totalValue.format()}</TableCell>
          <TableCell />
          <TableCell />
          {actionCells && <TableCell />}
        </TableRow>
      </TableBody>
    </Table>
  );
}
