import React, { useEffect } from 'react';
import { Breadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { useFlashMessageContext } from '../../../contexts/flashMessage';
import { FetchLeagueTypeQuery, FetchLeagueTypeQueryVariables } from '../../../generated/hooks';
import { LeagueType } from '../../../generated/schemas';
import PickEmLeagueDetail from './PickEmLeagueDetail';
import StockMarketLeagueDetail from './StockMarketLeagueDetail';

const fetchLeagueType = gql`
  query fetchLeagueType($leagueId: UUID!) {
    league(id: $leagueId) {
      id
      leagueType
      season
      name
      userIsCommissioner
    }
  }
`;

function LeagueDetail() {
  const { addFlashMessage } = useFlashMessageContext();
  const {
    params: { leagueId },
  } = useMatch('/league/:leagueId/*');
  const locationState = useLocation()?.state as {
    leagueName: string;
    leagueType: LeagueType;
    season: number;
    userIsCommissioner: boolean;
  };
  const navigate = useNavigate();
  const [{ data, error }] = useQuery<FetchLeagueTypeQuery, FetchLeagueTypeQueryVariables>({
    query: fetchLeagueType,
    variables: { leagueId },
    pause: Boolean(locationState?.leagueType),
  });

  const leagueName = locationState?.leagueName || data?.league?.name;
  const leagueType = locationState?.leagueType || data?.league?.leagueType;
  const season = locationState?.season || data?.league?.season;
  const userIsCommissioner = locationState?.userIsCommissioner || data?.league?.userIsCommissioner;

  useEffect(() => {
    if (data && !data.league && error?.graphQLErrors[0].message === 'League matching query does not exist.') {
      addFlashMessage('warning', `League with ID ${leagueId} does not exist`);
      navigate('/', { replace: true });
    }
  }, [data, error]);

  if (!leagueType) {
    return null;
  }

  return (
    <>
      <Breadcrumbs sx={{ mb: 2 }}>
        <MuiLink
          component={Link}
          to="/"
          sx={theme => ({ color: 'text.secondary', textDecorationColor: theme.palette.text.secondary })}
        >
          Home
        </MuiLink>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          {leagueName}
        </Typography>
      </Breadcrumbs>
      {leagueType === LeagueType.PickEm && (
        <PickEmLeagueDetail leagueId={leagueId} season={season} userIsCommissioner={userIsCommissioner} />
      )}
      {leagueType === LeagueType.StockMarket && <StockMarketLeagueDetail leagueId={leagueId} />}
    </>
  );
}

export default LeagueDetail;
