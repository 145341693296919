import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function HoustonLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 237.84386" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -257.49 151.55)">
        <g>
          <path
            d="m293.3 120.32v-36.803h10.447v-27.032h-38.294v27.032h10.447v36.803h-69.216v-36.803h11.204v-67.189l21.523-18.541h21.779v-29.349h-11.192v-36.792h69.216v36.792h-10.447v6.7783h37.537v-6.7783h-10.435v-36.792h69.216v36.792h-11.204v51.233h11.204v36.815h-53.761v27.032h11.192v36.803h-69.216z"
            fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          />
          <path
            d="m298.54 115.09v-26.345h10.435v-37.502h-48.753v37.502h10.435v26.345h-58.746v-26.345h11.216v-70.019l18.227-15.7h25.064v-39.831h-11.192v-26.31h58.746v26.31h-10.447v17.249h48.007v-17.249h-10.435v-26.31h58.746v26.31h-11.204v61.715h11.204v26.333h-53.761v37.502h11.204v26.345h-58.746zm52.666-92.241v-14.512h-16.352l16.352 14.512z"
            fill={variant === 'inverted' ? colors.primaryColor : '#ffffff'}
          />
          <path
            d="m13.621 13.9v20.483h14.006v90.907l18.883 16.24h29.043v-21.14h-14.006v-86.007h13.058v-20.483h-60.984zm108.27 0v20.483h13.06v86.007h-13.06v21.14h28.7l18.3-16.24v-90.907h13.99v-20.483h-60.99zm-54.13 79.801v20.489h14.006v89.57h-14.006v20.46h60.98v-20.46h-13.05v-34.01h72.45v34.01h-13.04v20.46h60.98v-20.46h-13.99v-89.57h13.99v-20.489h-60.98v20.489h13.04v33.55h-72.45v-33.55h13.05v-20.489h-60.98z"
            transform="matrix(.8 0 0 -.8 206 121.24)"
            fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default HoustonLogo;
