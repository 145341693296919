import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function WakeForestLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 176.79" {...restProps}>
      <g transform="matrix(1.4774 0 0 1.4774 -1.4408 -.45315)">
        <path
          d="m102.36 26.863 4.164-13.111c0.488-1.536 1.899-2.569 3.511-2.569h3.109v-10.22h-32.146v10.22h4.779c1.845 0 3.528 0.854 4.617 2.343 1.09 1.489 1.395 3.352 0.836 5.111l-10.31 32.466-10.521-36.194c-0.258-0.888-0.088-1.822 0.467-2.562 0.556-0.74 1.405-1.164 2.33-1.164h3.108v-10.22h-34.768v10.22h4.072c1.051 0 1.991 0.705 2.284 1.715l2.722 9.365-9.158 28.839-10.52-36.193c-0.259-0.889-0.088-1.822 0.467-2.562s1.404-1.164 2.329-1.164h3.109v-10.22h-34.769v10.22h4.072c1.051 0 1.991 0.705 2.284 1.715l23.393 80.473h9.953l15.408-48.517 14.104 48.517h9.953l17.455-54.964c2.347 1.222 4.031 3.368 4.031 6.754v61.14c0 1.516-1.234 2.749-2.749 2.749h-10.439v10.22h50.961v-10.22h-10.675c-1.386 0-2.513-1.128-2.513-2.514v-28.36h8.078c1.246 0 4.074 4.694 4.074 8.078v3.109h10.22v-34.005h-10.22v3.109c0 3.383-2.828 8.077-4.074 8.077h-8.078v-18.285c0-7.72 6.618-11.176 13.177-11.176h1.188c11.464 0 18.039 6.317 18.039 17.333v4.1h10.221v-31.653z"
          fill={colors.secondaryColor}
          stroke="#ffffff"
          strokeWidth={variant === 'main' ? '0' : '1.5'}
        />
        <path
          d="m102.06 29.972h-3.954l5.45-17.16c0.896-2.821 3.515-4.737 6.474-4.737v-4.003h-25.928v4.003h1.67c5.975 0 10.225 5.809 8.416 11.503l-13.404 42.209-13.375-46.01c-1.12-3.852 1.77-7.702 5.782-7.702v-4.003h-28.551v4.003h0.964c2.441 0 4.588 1.612 5.269 3.956l1.333 4.585 1.653 5.687-12.539 39.484-13.375-46.01c-1.12-3.852 1.77-7.702 5.782-7.702v-4.003h-28.552v4.003h0.964c2.441 0 4.588 1.612 5.269 3.956l20.48 70.451 2.262 7.781h5.344l17.814-56.093 14.044 48.312 2.262 7.781h5.345l17.736-55.848c5.299 1.206 9.133 4.878 9.133 10.746v61.14c0 3.235-2.623 5.858-5.858 5.858h-7.33v4.003h44.744v-4.003h-7.565c-3.105 0-5.622-2.517-5.622-5.622v-31.468h11.187c3.967 0 7.183 7.219 7.183 11.186h4.003v-27.788h-4.003c0 3.967-3.216 11.186-7.183 11.186h-11.187v-21.394c0-8.994 7.291-14.284 16.286-14.284h1.188c12.98 0 21.148 7.461 21.148 20.441v0.991h4.003v-25.436h-64.762z"
          fill={colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default WakeForestLogo;
