import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function IndianaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 270 341" {...restProps}>
      <path
        d="M88.2,0 v25.6 h19.9 v188h-37.9v-142.6h17.8v-25.7h-88v25.7h19.9v165.2l32.3,33h55.9v38.1h-19.9v33h93.4v-33h-19.9v-38.1h55.9l32.3,-33v-165.2h19.9v-25.7h-88v25.7h17.8v142.6h-37.9v-188h19.9v-25.6z"
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
      />
    </SvgIcon>
  );
}

export default IndianaLogo;
