import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import currency from 'currency.js';
import { TeamObjectType } from '../../../../../generated/schemas';
import BuyModal from './BuyModal';
import SellModal from './SellModal';

export default function TeamActionButtons({
  alignRight = false,
  cashBalance,
  costBasis,
  leagueId,
  shareQuantity,
  team,
  refetch,
}: {
  alignRight?: boolean;
  cashBalance: currency;
  costBasis: currency;
  leagueId: string;
  shareQuantity: number;
  team: Pick<TeamObjectType, 'id' | 'name' | 'stockPurchasePrices' | 'styles'>;
  refetch(): void;
}) {
  const [buyModalOpen, setBuyModalOpen] = useState(false);
  const [sellModalOpen, setSellModalOpen] = useState(false);

  return (
    <>
      {buyModalOpen && (
        <BuyModal
          cashBalance={cashBalance}
          leagueId={leagueId}
          team={team}
          onClose={() => setBuyModalOpen(false)}
          refetch={refetch}
        />
      )}
      {sellModalOpen && (
        <SellModal
          leagueId={leagueId}
          cashBalance={cashBalance}
          costBasis={costBasis}
          shareQuantity={shareQuantity}
          team={team}
          onClose={() => setSellModalOpen(false)}
          refetch={refetch}
        />
      )}
      <Stack spacing={1} direction="row" justifyContent={alignRight ? 'right' : 'left'}>
        <Button
          variant="contained"
          color="success"
          disabled={cashBalance.intValue < currency(team.stockPurchasePrices[0]).intValue}
          sx={{ width: 80, py: { xs: 0.25, md: 0.75 } }}
          onClick={() => setBuyModalOpen(true)}
        >
          Buy...
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={shareQuantity === 0}
          sx={{ width: 80, py: { xs: 0.25, md: 0.75 } }}
          onClick={() => setSellModalOpen(true)}
        >
          Sell...
        </Button>
      </Stack>
    </>
  );
}
