import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function AkronLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 130.23012" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -106.23 809.38)">
        <g>
          <path
            d="m136.06 626.18-0.9444-0.0996-0.58483-0.74946-28.361-36.115-4.8678-6.1992 7.4079 2.6888 30.226 10.972 26.426 3.857-79.679-56.501v-0.009l141.03 15.425 0.94151 0.0982 0.58628 0.74801 28.28 36.013 4.8332 6.1545-7.3631-2.6397-30.46-10.918-26.153-3.8224 86.703 61.526-148.02-20.429z"
            fill={colors.secondaryColor}
          />
          <path
            d="m275.7 643.43-139.4-19.499-28.359-36.117 30.44 11.051 35.968 5.252-80.3-56.928 132.42 14.488 28.283 36.017-30.67-10.999-35.666-5.2043 87.283 61.939z"
            fill={colors.primaryColor}
          />
          <path
            d="m246.65 628.62-28.466-3.6447 41.33 11.796-120.65-17.442-16.592-21.131 14.918 5.4137 56.573 8.2628-70.661-49.867 32.047 9.135-38.263-16.534 107.03 11.689 16.59 21.132-15.232-5.4642-56.257-8.2137 77.637 54.867z"
            stroke="#ffffff"
            strokeWidth=".9416"
            fill="#ffffff"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default AkronLogo;
