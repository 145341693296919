import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function OregonStateLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 176.99612 98.372348" {...restProps}>
      <g transform="matrix(.5 0 0 .5 .47293 .51935)">
        <path
          fill={colors.primaryColor}
          d="m149.58 1.1825c-37.635 0-75.781 6.882-113.39 20.444-3.9656 1.4338-7.9319 2.936-11.776 4.4618-2.0422 0.77176-4.1712 1.5956-6.3262 2.4535l-17.268 6.8735 18.13 4.1139c0.05338 0.015 6.7812 1.589 16.479 5.9969 12.265 5.5715 29.042 15.53 43.899 32.438-3.188-0.203-6.95-0.567-10.248-1.253-0.765-0.157-1.518-0.337-2.24-0.533l-11.261-3.1585 4.3504 10.862c0.07125 0.18998 0.79068 1.9198 2.3282 4.6565 3.7311 6.6371 11.327 17.515 25.082 27.791l-15.421-2.3561 11.841 12.286c0.24044 0.24934 0.59775 0.656 1.0667 1.1873 0.49868 0.55804 1.1024 1.2322 1.8088 2.0129 8.5606 9.3888 21.001 20.435 37.576 28.83l-16.238-1.3496 25.338 13.241c1.68 0.87565 3.4341 1.7484 5.3754 2.6715l0.13913 0.0649c13.036 7.9165 42.549 21.01 95.046 21.01 9.926 0 20.35-0.48327 30.978-1.4331l0.74207-0.0743 0.70961-0.0649 0.5612-0.051 1.512-0.14377h0.6725l0.91833-0.30148c0.35322-0.12462 0.72149-0.26139 1.0806-0.41277 8.427-3.4106 19.38-15.264 33.426-36.222 18.555-27.665 37.205-63.351 42.479-73.628 1.1606-2.2648 1.8041-3.5341 1.8041-3.5341l2.0778-4.1371-3.5944-2.9265c-14.316-11.651-29.772-22.042-45.935-30.87-9.24-5.014-18.85-9.633-28.56-13.727-25.83-10.837-53.76-18.447-83.04-22.623-13.29-1.728-26.77-2.5931-40.12-2.5931z"
        />
        <path
          strokeWidth="1.25"
          stroke={colors.secondaryColor}
          d="m149.58 6.9575c-33.898 0-71.301 5.6245-111.43 20.101-3.844 1.3891-7.717 2.847-11.6 4.3875-2.1015 0.79254-4.2174 1.6135-6.3309 2.4535 0 0 7.1451 1.6216 17.596 6.368 14.878 6.7648 36.461 19.885 53.68 43.709-1.125 0.02675-2.3329 0.04637-3.5944 0.04637-1.9501 0-4.0452-0.0495-6.1824-0.16234-3.9745-0.15731-9.2546-0.5495-13.826-1.4934-0.8994-0.18996-1.7788-0.4009-2.6159-0.6354 0 0 0.59922 1.4966 1.9944 3.984 4.2802 7.6019 16.065 24.529 41.26 36.727 0 0-1.0616 0.2215-3.0148 0.41741-1.0478 0.11275-2.3588 0.21094-3.9051 0.26437-0.6738 0.0206-1.3918 0.0325-2.1428 0.0325-0.22856 0-0.46919-0.004-0.70961-0.009-3.0425-0.0268-6.7006-0.26122-10.862-0.89049 0.52242 0.53726 1.5222 1.7044 2.9915 3.3161 8.1272 8.9168 30.728 31.555 67.52 38.18 0.13654 0.0206 0.27827 0.0491 0.42669 0.0789 0 0-4.7665 1.012-11.674 1.3682-1.4812 0.0712-3.0571 0.116-4.7075 0.116-1.5732 0-3.2118-0.0365-4.8978-0.1345-0.52242-0.0326-1.0578-0.0744-1.5863-0.116 1.7068 0.89049 3.4349 1.743 5.1714 2.5741 0.14545 0.0653 0.29682 0.13913 0.44524 0.21334 11.19 6.8479 39.864 20.3 92.272 20.3 9.3828 0 19.521-0.43337 30.462-1.41 0.47196-0.0445 0.95023-0.0946 1.4192-0.13912 0.78363-0.0712 1.5615-0.13617 2.3422-0.21334h0.019c0.24043-0.0861 0.48014-0.1744 0.72353-0.27829 8.24-3.3334 19.521-17.224 30.842-34.108 18.214-27.158 36.484-62.031 42.126-73.044 1.1576-2.244 1.7856-3.4878 1.7856-3.4878-14.642-11.921-29.75-21.919-45.058-30.286-8.3112-4.5059-17.674-9.104-28.028-13.474-27.641-11.597-55.374-18.486-81.62-22.22-12.491-1.6236-25.605-2.537-39.298-2.537z"
          fill="#000"
        />
        <path
          d="m142.18 19.363c2.8673 3.1968 4.3321 7.7235 4.5547 8.4121-1.3031-0.16326-28.976-3.5098-53.313 0.55664 0 0-2.8368 0.43446-6.6836 1.3457-0.02375 0-0.0437-0.0034-0.06445 0.0078-1.0775 0.25527-2.213 0.53763-3.4004 0.87305-4.8145 1.0122-12.325 2.9379-17.994 5.918 0 0 13.054 2.2857 35.67 26.732h8.8027 0.18945c-2.6566-5.1469-7.7598-16.955-5.5781-28 1.4633 0.27308 2.5476 0.47166 3.0137 0.54883 9.3855 2.0629 28.557 8.9188 35.916 31.074 0 0 2.3898-5.3412 2.4492-13.094 25.86-10.693 52.1-17.403 62.2-4.204-19.58-7.147-31.06 4.37-26.94 16.412 3.3274-14.105 21.311-17.612 33.99-2.9082 10.917 12.668 29.41 10.975 29.41 10.975s-2.1938-2.8037-2.9121-5.8164c0 0 9.5215 4.5586 20.762 3.2793 0 0-0.2405-9.7572 25.615-0.70117 0 0-6.4674 5.8638-30.053 8.3066 0 0 2.5087 2.2109 6.6465 3.502 0 0-25.872 7.1282-43.889 1.3906 0 0 4.3791-3.5082 5.2695-6.6367 0 0-15.078 7.5601-26.816-0.06836-10.55-6.857-16.8-4.704-16.8-4.704s8.6926 3.6249 9.957 11.232c0 0-44.49 7.4565-70.922-8.293 0 0 7.9675 18.29 32.414 23.176 13.392-1.8017 33.051-6.5439 63.246 2.4082-9.1866 0.23447-13.218 6.2649-15.574 8.877-14.307 1.3744-46.08 2.0168-84.951-1.0762 0 0 100.31 20.832 137.9-4.5918-13.698 17.581-46.86 18.384-58.377 18.574-0.67974 2.2143-0.71752 8.7778-0.36133 9.3535-6.8803 2.2202-34.811 3.4937-52.354 3.6836 0 0 82.427 8.0511 114.51-14.053-3.4134 5.9514-17.3 21.699-59.945 20.879 7.4681 18.693 27.622 23.093 51.445-1.127 46.399-41.264 64.063-24.907 68.598-44.963 2.5734-11.407-18.675-12.925-17.295-24.154 0 0 15.406 5.1776 26.891 0.5293 0 0-46.338-33.758-115.14-50.943 3.5322 3.3244 5.6484 9.2246 5.6484 9.2246s-33.221-12.029-75.727-11.938h-0.004zm114.43 134.01c-20.422 15.426-45.408 13.311-45.408 13.311 10.914 5.4349 31.379 5.6992 31.379 5.6992 12.528-5.8445 14.029-19.01 14.029-19.01z"
          fill={colors.primaryColor}
          transform="translate(-.0043946)"
        />
        <path
          d="m195.53 59.742c-0.16297 0.0075-0.32652 0.02184-0.49218 0.04687-2.6507 0.39478-4.4858 2.8718-4.0762 5.5195 0.39181 2.6507 2.8601 4.4808 5.5137 4.0859 2.6477-0.39774 4.4837-2.8698 4.0859-5.5234-0.35063-2.3193-2.2905-4.0067-4.5449-4.127-0.16103-0.0086-0.32335-0.0094-0.48633-0.002zm9.1895 6.127c-0.133-0.0069-0.26934-0.000875-0.40625 0.01953-1.0893 0.15732-1.8429 1.1723-1.6797 2.2676 0.16622 1.0864 1.1791 1.8438 2.2773 1.6836 1.0923-0.1692 1.8399-1.1851 1.6797-2.2715-0.14284-0.96097-0.94012-1.6512-1.8711-1.6992zm102.5 57.473-5.5644 1.2656-5.8027 8.0469-0.95508-6.9414c-10.985-1.1992-18.857 5.5742-18.857 5.5742l-3.5098 10.119s-2.4681 6.6224-4.0918 12.004c-4.8857 16.207-11.32 20.207-13.811 21.172h5.5176c2.4281-0.92313 7.092-3.1121 9.8555-7.1934 0 0-0.60947 2.4238-1.6543 4.3086h2.6621c0.89343-0.47195 1.7431-0.98039 2.5-1.5117 8.679-6.1412 33.713-46.844 33.713-46.844h-0.002z"
          fill="#ffffff"
          transform="translate(-.0043946)"
        />
        <path
          d="m284.88 82.137s-6.9798 0.33588-13.145 5.9785c0 0 3.7766 1.7988 10.152 0.37109-5.1796-1.9235-1.2257-4.7112 2.9922-6.3496zm21.611 9.8848s-6.7633 0.3175-12.744 5.7969c0 0 3.6561 1.7407 9.8418 0.35156-5.0222-1.8581-1.1938-4.5634 2.9023-6.1484zm-17.744 6.9746s-8.6925 1.2537-15.531 9.0156c0 0 5.0211 1.7822 12.795-0.77344-6.8091-1.7542-2.2948-5.7012 2.7363-8.2422z"
          transform="translate(-.0043946)"
          stroke={colors.secondaryColor}
          strokeWidth="1.25"
          fill="#000"
        />
      </g>
    </SvgIcon>
  );
}

export default OregonStateLogo;
