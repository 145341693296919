import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function UtahLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 126.49805 115.58209" {...restProps}>
      <g transform="matrix(.5 0 0 .5 .64647 .54616)">
        <path
          d="m156.12 1.2246c-31.611 0-59.615 15.463-76.59 39.092h-6.582v-21.146h-3.1855l-7.584 11.906-4.178-4.44-0.87695 2.9238 2.8535 10.756h-5.8984v10.027h2.9453c-15.243 13.747-56.11 58.536-56.118 143.69l-0.097656 8.5644s1.4935 1.5796 2.1035 2.2168c-0.04832 1.1929-0.21289 5.4648-0.21289 5.4648l6.5059 10.693s2.867 1.5553 3.918 2.123c0.2416 1.1627 1.0938 5.3047 1.0938 5.3047h5.4375s2.1733-2.1374 2.584-2.5391c0.59192-0.25972 4.9941-2.1562 4.9941-2.1562s2.587-7.2909 3.1094-8.7676c0.97546-0.31106 3.293-1.0566 3.293-1.0566l3.2793-7.5234c0.06342-0.16308 3.7167-11.69 4.7949-15.1 0.17818-0.0544 0.18669-0.0531 0.37695-0.10742 1.1114 1.3409 3.786 4.5586 3.7949 4.5586 0 0.015 2.568 3.6787 3.1328 4.4668 0.1359 0.61004 1.1094 4.8652 1.1094 4.8652l6.2754 0.47657s5.1643-6.7278 5.1855-6.752c0.02102 0.015 3.5957-3.2988 3.5957-3.2988l0.61914-0.58398v-4.748s-1.1768-1.7578-1.9258-2.8691c0.13892-0.1208 0.16311-0.13844 0.29297-0.24414 1.0902 0.3775 4.0723 1.4102 4.0723 1.4102l4.793-6.7148s-0.60504-1.3906-0.75-1.7168c0.906-0.8003 2.4629-2.1387 2.4629-2.1387s1.752-6.6066 1.9453-7.3223c0.47414-0.57984 6.2324-7.6836 6.2324-7.6836l-6.9688-8.4024c-0.87278-3.1558-3.705-13.44-3.9316-14.252 0.0906-0.33522 0.56896-2.0612 1.1367-4.1328 15.701 29.294 46.992 49.32 82.957 49.32 51.661 0 93.689-41.316 93.689-92.086 0-50.765-42.031-92.066-93.689-92.066l0.002-0.00391z"
          transform="translate(0 -.0018692)"
          fill={colors.secondaryColor}
          stroke="#ffffff"
          strokeWidth={variant === 'third' ? '2' : '0'}
        />
        <path
          d="m156.12 12.049c-45.677 0-82.709 36.379-82.709 81.244 0 44.868 37.032 81.258 82.709 81.258 45.667 0 82.703-36.39 82.703-81.258 0-44.865-37.036-81.244-82.703-81.244zm-85.918 13.969-5.8613 14.297h5.8613v-14.297zm-7.1738 24.33c-0.2114 0.16912-0.43223 0.34844-0.67383 0.55078l-3.3984 12.029-0.14648 0.24024s-5.5215 8.8824-5.5488 8.8672c-0.01498 0.02102-6.4151 16.981-6.6055 17.482-0.04228 0.53454-0.90625 12.354-0.90625 12.354s-9.6696 11.513-10.5 12.504v8.2676c0.33522 0.67346 2.332 4.6992 2.332 4.6992l-0.24219 4.9375-3.9551-0.18946s0.15996-3.1542 0.19922-3.9394c-0.3624-0.73084-2.2891-4.6016-2.2891-4.6016v-10.578s9.8585-11.719 10.607-12.613c0.0755-1.1234 0.84961-11.67 0.84961-11.67l0.09961-0.26562s6.699-17.754 6.8652-18.092c0 0 5.3026-8.5218 5.5684-8.9688 0.06342-0.20536 0.74941-2.6379 1.5527-5.4707-16.315 15.762-51.538 58.821-51.93 136.87 1.7395 0.70366 7.3418 2.9922 7.3418 2.9922s0.10102 4.3898 0.11914 5.5918c0.2416 0.20234 0.31128 0.25608 0.61328 0.50976 1.4405-1.1355 4.4316-3.4863 4.4316-3.4863h3.0625s2.9579 3.5862 3.7129 4.4922c0.56474 0.0695 0.92682 0.10596 1.6758 0.1875 0.85164-1.356 2.7559-4.4121 2.7559-4.4121s8.7437-8.9412 9.6074-9.832c0.09664-1.0298 0.74333-7.9458 0.93359-9.9238l-10.678-21.193 0.34766-6.625s3.969-6.346 4.5488-7.252c-0.0302-0.35334-0.03727-0.49945-0.06445-0.87695-0.604-0.66742-2.5391-2.8047-2.5391-2.8047v-3.7656h3.959v2.2754c0.63722 0.71574 2.4395 2.6934 2.4395 2.6934l0.23633 3.4648s-4.2105 6.7153-4.6816 7.4551c-0.03624 0.76104-0.20381 3.871-0.24609 4.6289 0.43186 0.86674 9.9368 19.702 10.287 20.391l5.6875 4.7227c0.44696-2.3012 1.4473-7.5352 1.4473-7.5352s5.729 0.51127 7.541 0.68945c0.82446-1.7878 3.6895-7.9277 3.6895-7.9277s3.7971 0.0803 5.4883 0.10742c0.4379-2.0445 1.7363-8.0117 1.7363-8.0117s2.0468-0.0958 2.5391-0.12305c0.41978-1.3801 0.99013-3.2336 1.1895-3.8828-0.27482-0.99962-4.1582-15.129-4.1582-15.129l0.13281-0.5 2.459-8.9512c-5.2065-11.446-8.1016-24.122-8.1016-37.449 0-15.502 3.9248-30.112 10.844-42.943h-10.24z"
          transform="translate(0 -.0018692)"
          fill="#ffffff"
        />
        <path
          d="m98.246 42.117v29.418h9.2246v66.143l0.0625 0.0508 20.059 21.816h57.045l20.055-21.816 0.0605-0.0508v-66.143h9.2305v-29.418h-55.002v29.418h5.8457v55.615h-17.432v-55.615h5.8477v-29.418h-54.996zm-23.273 117.3-1.0898 3.5566s-1.8732 0.0836-2.293 0.0957c-0.4228 1.9751-1.7793 8.2148-1.7793 8.2148s-4.5946-0.0822-6.1621-0.10937c-0.78822 1.6912-3.7852 8.1035-3.7852 8.1035s-4.7309-0.43333-6.7422-0.60547c-0.4077 2.1231-1.5098 7.8477-1.5098 7.8477h-3.918l-0.55664-0.47656-4.332-3.6524-0.42774 4.6016 1.123-0.3125 5.543 6.6738 3.6484 5.209s0.38676 1.6822 0.64648 2.8027c0.57682 0.0393 0.71477 0.0505 1.207 0.0898 1.2473-1.6248 3.9391-5.1237 4.1113-5.2988 0 0 2.3167-2.1675 3.1895-2.9648v-1.8965c-0.49045-0.74171-3.2031-4.7734-3.2031-4.7734l4.6855-3.9414s2.3121 0.79917 3.4023 1.1797c0.6946-0.97244 1.2412-1.7315 1.7969-2.5137-0.22046-0.51642-1.0098-2.332-1.0098-2.332s3.0411-2.6201 3.6992-3.2031c0.26274-0.96036 1.8652-6.9707 1.8652-6.9707s3.4171-4.2232 4.752-5.875c-0.64628-0.78822-1.7963-2.1779-2.8594-3.4492h-0.002zm-70.143 37.535v0.002c-0.02416 1.6248-0.040835 3.4479-0.046875 4.1094l2.1426 2.2617s-0.19032 4.9964-0.22656 5.9297c0.5134 0.85466 4.8812 8.0227 5.3555 8.793 0.74292 0.4077 4.582 2.4902 4.582 2.4902s0.53555 2.569 0.81641 3.9492h0.57031c0.64024-0.6342 1.9531-1.9297 1.9531-1.9297s3.0135-1.2856 4.0918-1.7598c0.47414-1.3258 3.1855-8.9746 3.1855-8.9746s2.5018-0.81041 3.459-1.1094c0.7248-1.6791 2.494-5.7227 2.5-5.7227 0 0 1.2505-3.9509 2.543-8.0371-2.0596 2.111-3.8653 3.9622-3.9922 4.0859-0.20234 0.34126-3.8359 6.1816-3.8359 6.1816l-5.793-0.67578s-2.5682-3.1001-3.459-4.166c-1.0117 0.78218-5.7754 4.5332-5.7754 4.5332l-4.4453-3.7266s-0.075269-3.3524-0.10547-4.7988c-0.70064-0.28388-2.1123-0.85269-3.5195-1.4356z"
          transform="translate(0 -.0018692)"
          fill={colors.primaryColor}
        />
        <path
          d="m52.291 95.457-0.66016 8.9863-0.01563 0.1875 1.2324 9.2969 3.9316-0.5s-1.144-8.5238-1.1953-8.9043c0.03624-0.38052 0.64648-8.7832 0.64648-8.7832l-3.9395-0.2832zm-2.9727 26.33v5.1582c-0.26274 0.6342-4.0723 10.135-4.0723 10.135l3.4395 10.957c0.03624 0.40468 1.4062 15.186 1.4062 15.186l3.9453-0.36328-1.4531-15.576s-2.7868-8.8934-3.1523-10.041c0.44696-1.1114 3.8398-9.5566 3.8398-9.5566v-5.8984h-3.9531zm-32.531 41.531-0.33008 15.264-0.01563 0.7832s0.94938 1.0172 1.3359 1.4219v5.6289h3.9551v-7.1445s-0.93109-0.9888-1.3086-1.3965c0.03624-1.4073 0.32227-14.477 0.32227-14.477l-3.959-0.0801z"
          transform="translate(0 -.0018692)"
          fill={colors.secondaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default UtahLogo;
