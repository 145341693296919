import React from 'react';
import { Box, useTheme } from '@mui/material';
import { TeamObjectType } from '../../generated/schemas';
import { LogoProps } from '../../types';
import AirForceLogo from './AirForceLogo';
import AkronLogo from './AkronLogo';
import AlabamaLogo from './AlabamaLogo';
import AppalachianStateLogo from './AppalachianStateLogo';
import ArizonaLogo from './ArizonaLogo';
import ArizonaStateLogo from './ArizonaStateLogo';
import ArkansasLogo from './ArkansasLogo';
import ArkansasStateLogo from './ArkansasStateLogo';
import ArmyLogo from './ArmyLogo';
import AuburnLogo from './AuburnLogo';
import BallStateLogo from './BallStateLogo';
import BaylorLogo from './BaylorLogo';
import BoiseStateLogo from './BoiseStateLogo';
import BostonCollegeLogo from './BostonCollegeLogo';
import BowlingGreenLogo from './BowlingGreenLogo';
import BuffaloLogo from './BuffaloLogo';
import ByuLogo from './ByuLogo';
import CaliforniaLogo from './CaliforniaLogo';
import CentralFloridaLogo from './CentralFloridaLogo';
import CentralMichiganLogo from './CentralMichiganLogo';
import CharlotteLogo from './CharlotteLogo';
import CincinnatiLogo from './CincinnatiLogo';
import ClemsonLogo from './ClemsonLogo';
import CoastalCarolinaLogo from './CoastalCarolinaLogo';
import ColoradoLogo from './ColoradoLogo';
import ColoradoStateLogo from './ColoradoStateLogo';
import ConnecticutLogo from './ConnecticutLogo';
import DukeLogo from './DukeLogo';
import EastCarolinaLogo from './EastCarolinaLogo';
import EasternMichiganLogo from './EasternMichiganLogo';
import FloridaAtlanticLogo from './FloridaAtlanticLogo';
import FloridaInternationalLogo from './FloridaInternationalLogo';
import FloridaLogo from './FloridaLogo';
import FloridaStateLogo from './FloridaStateLogo';
import FresnoStateLogo from './FresnoStateLogo';
import GeorgiaLogo from './GeorgiaLogo';
import GeorgiaSouthernLogo from './GeorgiaSouthernLogo';
import GeorgiaStateLogo from './GeorgiaStateLogo';
import GeorgiaTechLogo from './GeorgiaTechLogo';
import HawaiiLogo from './HawaiiLogo';
import HoustonLogo from './HoustonLogo';
import IllinoisLogo from './IllinoisLogo';
import IndianaLogo from './IndianaLogo';
import IowaLogo from './IowaLogo';
import IowaStateLogo from './IowaStateLogo';
import JacksonvilleStateLogo from './JacksonvilleStateLogo';
import JamesMadisonLogo from './JamesMadisonLogo';
import KansasLogo from './KansasLogo';
import KansasStateLogo from './KansasStateLogo';
import KennesawStateLogo from './KennesawStateLogo';
import KentStateLogo from './KentStateLogo';
import KentuckyLogo from './KentuckyLogo';
import LibertyLogo from './LibertyLogo';
import LouisianaLafayetteLogo from './LouisianaLafayetteLogo';
import LouisianaMonroeLogo from './LouisianaMonroeLogo';
import LouisianaTechLogo from './LouisianaTechLogo';
import LouisvilleLogo from './LouisvilleLogo';
import LsuLogo from './LsuLogo';
import MarshallLogo from './MarshallLogo';
import MarylandLogo from './MarylandLogo';
import MassachusettsLogo from './MassachusettsLogo';
import MemphisLogo from './MemphisLogo';
import MiamiLogo from './MiamiLogo';
import MiamiOhioLogo from './MiamiOhioLogo';
import MichiganLogo from './MichiganLogo';
import MichiganStateLogo from './MichiganStateLogo';
import MiddleTennesseeLogo from './MiddleTennesseeLogo';
import MinnesotaLogo from './MinnesotaLogo';
import MississippiStateLogo from './MississippiStateLogo';
import MissouriLogo from './MissouriLogo';
import NavyLogo from './NavyLogo';
import NcStateLogo from './NcStateLogo';
import NebraskaLogo from './NebraskaLogo';
import NevadaLogo from './NevadaLogo';
import NewMexicoLogo from './NewMexicoLogo';
import NewMexicoStateLogo from './NewMexicoStateLogo';
import NorthCarolinaLogo from './NorthCarolinaLogo';
import NorthernIllinoisLogo from './NorthernIllinoisLogo';
import NorthTexasLogo from './NorthTexasLogo';
import NorthwesternLogo from './NorthwesternLogo';
import NotreDameLogo from './NotreDameLogo';
import OhioLogo from './OhioLogo';
import OhioStateLogo from './OhioStateLogo';
import OklahomaLogo from './OklahomaLogo';
import OklahomaStateLogo from './OklahomaStateLogo';
import OldDominionLogo from './OldDominionLogo';
import OleMissLogo from './OleMissLogo';
import OregonLogo from './OregonLogo';
import OregonStateLogo from './OregonStateLogo';
import PennStateLogo from './PennStateLogo';
import PittsburghLogo from './PittsburghLogo';
import PurdueLogo from './PurdueLogo';
import RiceLogo from './RiceLogo';
import RutgersLogo from './RutgersLogo';
import SamHoustonLogo from './SamHoustonLogo';
import SanDiegoStateLogo from './SanDiegoStateLogo';
import SanJoseStateLogo from './SanJoseStateLogo';
import SmuLogo from './SmuLogo';
import SouthAlabamaLogo from './SouthAlabamaLogo';
import SouthCarolinaLogo from './SouthCarolinaLogo';
import SouthernMissLogo from './SouthernMissLogo';
import SouthFloridaLogo from './SouthFloridaLogo';
import StanfordLogo from './StanfordLogo';
import SyracuseLogo from './SyracuseLogo';
import TcuLogo from './TcuLogo';
import TempleLogo from './TempleLogo';
import TennesseeLogo from './TennesseeLogo';
import TexasAmLogo from './TexasAmLogo';
import TexasLogo from './TexasLogo';
import TexasStateLogo from './TexasStateLogo';
import TexasTechLogo from './TexasTechLogo';
import ToledoLogo from './ToledoLogo';
import TroyLogo from './TroyLogo';
import TulaneLogo from './TulaneLogo';
import TulsaLogo from './TulsaLogo';
import UabLogo from './UabLogo';
import UclaLogo from './UclaLogo';
import UnlvLogo from './UnlvLogo';
import UscLogo from './UscLogo';
import UtahLogo from './UtahLogo';
import UtahStateLogo from './UtahStateLogo';
import UtepLogo from './UtepLogo';
import UtsaLogo from './UtsaLogo';
import VanderbiltLogo from './VanderbiltLogo';
import VirginiaLogo from './VirginiaLogo';
import VirginiaTechLogo from './VirginiaTechLogo';
import WakeForestLogo from './WakeForestLogo';
import WashingtonLogo from './WashingtonLogo';
import WashingtonStateLogo from './WashingtonStateLogo';
import WesternKentuckyLogo from './WesternKentuckyLogo';
import WesternMichiganLogo from './WesternMichiganLogo';
import WestVirginiaLogo from './WestVirginiaLogo';
import WisconsinLogo from './WisconsinLogo';
import WyomingLogo from './WyomingLogo';

const logoComponents: Record<string, React.ComponentType<LogoProps>> = {
  AirForceLogo: AirForceLogo,
  AkronLogo: AkronLogo,
  AlabamaLogo: AlabamaLogo,
  AppalachianStateLogo: AppalachianStateLogo,
  ArizonaLogo: ArizonaLogo,
  ArizonaStateLogo: ArizonaStateLogo,
  ArkansasLogo: ArkansasLogo,
  ArkansasStateLogo: ArkansasStateLogo,
  ArmyLogo: ArmyLogo,
  AuburnLogo: AuburnLogo,
  BallStateLogo: BallStateLogo,
  BaylorLogo: BaylorLogo,
  BoiseStateLogo: BoiseStateLogo,
  BostonCollegeLogo: BostonCollegeLogo,
  BowlingGreenLogo: BowlingGreenLogo,
  BuffaloLogo: BuffaloLogo,
  ByuLogo: ByuLogo,
  CaliforniaLogo: CaliforniaLogo,
  CentralFloridaLogo: CentralFloridaLogo,
  CentralMichiganLogo: CentralMichiganLogo,
  CharlotteLogo: CharlotteLogo,
  CincinnatiLogo: CincinnatiLogo,
  ClemsonLogo: ClemsonLogo,
  CoastalCarolinaLogo: CoastalCarolinaLogo,
  ColoradoLogo: ColoradoLogo,
  ColoradoStateLogo: ColoradoStateLogo,
  ConnecticutLogo: ConnecticutLogo,
  DukeLogo: DukeLogo,
  EastCarolinaLogo: EastCarolinaLogo,
  EasternMichiganLogo: EasternMichiganLogo,
  FloridaAtlanticLogo: FloridaAtlanticLogo,
  FloridaInternationalLogo: FloridaInternationalLogo,
  FloridaLogo: FloridaLogo,
  FloridaStateLogo: FloridaStateLogo,
  FresnoStateLogo: FresnoStateLogo,
  GeorgiaLogo: GeorgiaLogo,
  GeorgiaSouthernLogo: GeorgiaSouthernLogo,
  GeorgiaStateLogo: GeorgiaStateLogo,
  GeorgiaTechLogo: GeorgiaTechLogo,
  HawaiiLogo: HawaiiLogo,
  HoustonLogo: HoustonLogo,
  IllinoisLogo: IllinoisLogo,
  IndianaLogo: IndianaLogo,
  IowaLogo: IowaLogo,
  IowaStateLogo: IowaStateLogo,
  JacksonvilleStateLogo: JacksonvilleStateLogo,
  JamesMadisonLogo: JamesMadisonLogo,
  KansasLogo: KansasLogo,
  KansasStateLogo: KansasStateLogo,
  KennesawStateLogo: KennesawStateLogo,
  KentStateLogo: KentStateLogo,
  KentuckyLogo: KentuckyLogo,
  LibertyLogo: LibertyLogo,
  LouisianaLafayetteLogo: LouisianaLafayetteLogo,
  LouisianaMonroeLogo: LouisianaMonroeLogo,
  LouisianaTechLogo: LouisianaTechLogo,
  LouisvilleLogo: LouisvilleLogo,
  LsuLogo: LsuLogo,
  MarshallLogo: MarshallLogo,
  MarylandLogo: MarylandLogo,
  MassachusettsLogo: MassachusettsLogo,
  MemphisLogo: MemphisLogo,
  MiamiLogo: MiamiLogo,
  MiamiOhioLogo: MiamiOhioLogo,
  MichiganLogo: MichiganLogo,
  MichiganStateLogo: MichiganStateLogo,
  MiddleTennesseeLogo: MiddleTennesseeLogo,
  MinnesotaLogo: MinnesotaLogo,
  MississippiStateLogo: MississippiStateLogo,
  MissouriLogo: MissouriLogo,
  NavyLogo: NavyLogo,
  NcStateLogo: NcStateLogo,
  NebraskaLogo: NebraskaLogo,
  NevadaLogo: NevadaLogo,
  NewMexicoLogo: NewMexicoLogo,
  NewMexicoStateLogo: NewMexicoStateLogo,
  NorthCarolinaLogo: NorthCarolinaLogo,
  NorthernIllinoisLogo: NorthernIllinoisLogo,
  NorthTexasLogo: NorthTexasLogo,
  NorthwesternLogo: NorthwesternLogo,
  NotreDameLogo: NotreDameLogo,
  OhioLogo: OhioLogo,
  OhioStateLogo: OhioStateLogo,
  OklahomaLogo: OklahomaLogo,
  OklahomaStateLogo: OklahomaStateLogo,
  OldDominionLogo: OldDominionLogo,
  OleMissLogo: OleMissLogo,
  OregonLogo: OregonLogo,
  OregonStateLogo: OregonStateLogo,
  PennStateLogo: PennStateLogo,
  PittsburghLogo: PittsburghLogo,
  PurdueLogo: PurdueLogo,
  RiceLogo: RiceLogo,
  RutgersLogo: RutgersLogo,
  SamHoustonLogo: SamHoustonLogo,
  SanDiegoStateLogo: SanDiegoStateLogo,
  SanJoseStateLogo: SanJoseStateLogo,
  SmuLogo: SmuLogo,
  SouthAlabamaLogo: SouthAlabamaLogo,
  SouthCarolinaLogo: SouthCarolinaLogo,
  SouthernMissLogo: SouthernMissLogo,
  SouthFloridaLogo: SouthFloridaLogo,
  StanfordLogo: StanfordLogo,
  SyracuseLogo: SyracuseLogo,
  TcuLogo: TcuLogo,
  TempleLogo: TempleLogo,
  TennesseeLogo: TennesseeLogo,
  TexasAmLogo: TexasAmLogo,
  TexasLogo: TexasLogo,
  TexasStateLogo: TexasStateLogo,
  TexasTechLogo: TexasTechLogo,
  ToledoLogo: ToledoLogo,
  TroyLogo: TroyLogo,
  TulaneLogo: TulaneLogo,
  TulsaLogo: TulsaLogo,
  UabLogo: UabLogo,
  UclaLogo: UclaLogo,
  UnlvLogo: UnlvLogo,
  UscLogo: UscLogo,
  UtahLogo: UtahLogo,
  UtahStateLogo: UtahStateLogo,
  UtepLogo: UtepLogo,
  UtsaLogo: UtsaLogo,
  VanderbiltLogo: VanderbiltLogo,
  VirginiaLogo: VirginiaLogo,
  VirginiaTechLogo: VirginiaTechLogo,
  WakeForestLogo: WakeForestLogo,
  WashingtonLogo: WashingtonLogo,
  WashingtonStateLogo: WashingtonStateLogo,
  WesternKentuckyLogo: WesternKentuckyLogo,
  WesternMichiganLogo: WesternMichiganLogo,
  WestVirginiaLogo: WestVirginiaLogo,
  WisconsinLogo: WisconsinLogo,
  WyomingLogo: WyomingLogo,
} as const;

type TeamLogoComponentProps = Omit<LogoProps, 'colors'> & {
  fallbackToBox?: boolean;
  styles: TeamObjectType['styles'];
};

export function TeamLogoComponent({
  styles,
  sx: sxProps,
  fallbackToBox = false,
  ...restProps
}: TeamLogoComponentProps) {
  const {
    palette: { grey },
  } = useTheme();
  const LogoComponent = styles?.logoComponent ? logoComponents[styles.logoComponent] : null;
  const colors = {
    primaryColor: styles?.primaryColor || grey[800],
    secondaryColor: styles?.secondaryColor || grey[300],
  };
  const sx = {
    height: { xs: 24, md: 28 },
    width: { xs: 28, md: 32 },
    ...sxProps,
  };
  return LogoComponent ? (
    <LogoComponent colors={colors} sx={sx} {...restProps} />
  ) : fallbackToBox ? (
    <Box sx={{ width: sx.width }} />
  ) : null;
}
