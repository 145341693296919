import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function OldDominionLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 106 88" {...restProps}>
      <g transform="translate(0 .176)">
        <path
          d="M91.69 32.55l2.03.03v7.404c4.33-.261 11.374-.881 11.374-.881l-1.98 13.058c-.044 2.735-.246 17.449-.306 21.862.411.748.889 1.694 1.184 2.463.5 1.299.803 2.821.974 3.813.176 1.023.835 6.986.835 6.986s-12.077.024-13.887.01c-1.779-.013-9.213-.054-10.515-.061-.966.069-4.225.2-8.23-.793a12.129 12.129 0 0 1-4.299-2.04h-5.064a21.354 21.354 0 0 1-8.394 2.61c-2.403.235-4.885.075-7.334-.138-.671-.058-2.434-.006-4.03.044-.887.026-1.725.057-2.344.082l-.748.03-.198.01a4.65 4.65 0 0 1-.484-.006l-10.015.463.317-1.555a25.023 25.023 0 0 1-7.943 1.157C9.2 86.923 0 75.355 0 64.031c0-9.367 6.125-18.252 14.627-22.225l.027-9.304 1.95.028c-1.633-2.81-2.863-5.676-2.863-9.284 0-8.786 7.105-15.908 15.87-15.908 2.157 0 4.222.435 6.105 1.222a15.805 15.805 0 0 1 6.106-1.222c.653 0 1.297.04 1.93.117C45.319 3.29 49.329.328 54.034.328c4.726 0 8.752 2.989 10.303 7.184a15.914 15.914 0 0 1 8.459 1.047A15.816 15.816 0 0 1 78.9 7.338c8.765 0 15.87 7.121 15.87 15.907 0 3.752-1.407 6.562-3.082 9.305"
          fill="#ffffff"
        />
      </g>
      <path
        d="M91.914 42.064c3.726-.136 11.049-.78 11.049-.78l-1.654 10.902c-.04 2.48-.263 18.737-.315 22.475l.23.408c.375.682.812 1.542 1.083 2.244.431 1.124.712 2.5.879 3.471.172 1 .597 4.872.597 4.872s-10.048.02-11.856.005c-1.815-.014-9.52-.056-10.59-.061-.745.058-3.866.22-7.735-.74-3-.744-5.209-2.768-6.648-4.893-3.13 3.095-7.252 4.986-11.718 5.418-2.294.225-4.664.065-7.003-.139-.706-.061-2.561-.014-4.24.037-.893.028-1.738.059-2.362.084l-.748.03-.204.01a2.59 2.59 0 0 1-.374-.011l-7.814.36s.25-1.228.53-2.554c-2.72 1.313-6.182 2.316-10.365 2.263-12.333-.161-20.85-10.808-20.85-21.257 0-8.982 6.17-17.603 14.623-21.038l.025-8.655 3.47.05c-.296-.42-.515-.734-.577-.833-2.186-3.467-3.8-6.409-3.8-10.31 0-7.786 6.296-14.097 14.064-14.097 2.182 0 4.256.502 6.105 1.396a13.993 13.993 0 0 1 6.106-1.396c1.103 0 2.178.128 3.212.37.843-4.21 4.552-7.38 9-7.38 4.485 0 8.219 3.223 9.02 7.487a14.009 14.009 0 0 1 3.635-.478c2.183 0 4.257.501 6.106 1.396a13.995 13.995 0 0 1 6.106-1.396c7.767 0 14.064 6.312 14.064 14.098 0 3.846-1.665 6.55-3.737 9.835-.24.38-.547.82-.846 1.233l3.532.05v7.524z"
        fill={colors.secondaryColor}
      />
      <path
        d="M87.332 32.056c-.322.51-.78 1.14-1.148 1.635l-2.224 2.983 5.71.082v7.529c.826.033 1.484.05 1.823.042 3.585-.094 8.824-.553 8.824-.553l-1.248 8.224c-.033 2.005-.3 21.4-.326 23.256.102.17.296.501.517.902.33.598.717 1.35.954 1.966.348.906.6 2.1.761 3.045.165.954.306 2.245.306 2.245s-7.533.015-9.336.001c-1.893-.014-10.184-.059-10.695-.062-.32.03-3.32.267-7.108-.673-3.538-.878-5.85-4.142-6.725-6.763-2.833 4.165-7.347 6.744-12.397 7.233-2.16.211-4.392.05-6.593-.141-.75-.065-2.72-.025-4.502.03-.901.027-1.755.058-2.384.084l-.752.03-.207.01a.369.369 0 0 1-.143-.022l-5.177.24s.578-2.864 1.039-4.243c.106-.32.24-.673.384-1.031-2.449 2.154-7.137 5.2-14 5.112-10.97-.143-18.636-9.649-18.636-19.01 0-8.485 6.246-16.78 14.618-19.46l.023-7.952 5.647.08s-2.846-3.95-3.093-4.343c-2.171-3.444-3.455-5.907-3.455-9.11 0-6.544 5.293-11.849 11.822-11.85 1.888 0 3.675.447 5.26 1.236l.845.422.844-.422a11.764 11.764 0 0 1 5.262-1.235c1.039 0 2.046.135 3.006.387l2.026.533.328-2.073c.523-3.32 3.393-5.857 6.851-5.857 3.492 0 6.383 2.585 6.866 5.95l.313 2.177 2.104-.628c1.069-.318 2.201-.49 3.373-.49 1.89 0 3.677.446 5.262 1.236l.844.422.845-.422a11.77 11.77 0 0 1 5.26-1.236c6.53 0 11.822 5.305 11.822 11.85 0 3.113-1.288 5.303-3.39 8.634"
        fill={colors.primaryColor}
      />
      <path
        d="M23.18 40.843h61.85l-.014 3.205c-2.032-.115-3.735-.222-3.735-.222s-6.363.625-9.959.625c-3.59 0-9.541-.71-9.541-.71l1.493 4.985c-.252-.167-.508-.329-.768-.486-5.856-3.518-13.085-3.998-15.896-4.042-1.635-.026-3.715.062-5.252.029-2.271-.05-8.89-1.318-8.903-1.32l-.336-.064s.216 1.767.36 2.929c-2.707-1.253-5.581-1.924-8.218-1.924-.362.002-.722.015-1.08.038v-3.043zM49.53 75.317c3.92.168 5.765-.771 8.235-3.279 2.694-2.796 2.678-7.154 1.82-10-1.115-3.703-4.455-5.746-8.317-6.732-.31-.07-1.143-.11-1.624-.168-.01 6.609-.218 13.387-.115 20.179M36.394 47.35s2.33.534 4.882.59c1.543.034 3.633-.054 5.275-.028 5.331.084 25.601 1.913 19.587 22.124-1.62 5.447-6.12 8.897-11.478 9.415-1.936.19-3.94.027-5.914-.144-3.317-.289-8.45.244-8.454.245l-.316.053s.442-1.613 1.44-2.668c1.105-1.168 2.161-1.63 2.161-1.63l-1.42-21.44s-1.123-.343-1.994-.924c-3.32-2.218-3.77-5.593-3.77-5.593"
        fill="#95999C"
      />
      <path
        d="M49.53 75.317c3.92.168 5.765-.771 8.235-3.279 2.289-2.376 2.621-5.878 2.144-8.624l1.49-2.16s2.998 10.604-5.953 14.598c-2.622 1.17-7.295.831-7.295.831l1.378-1.38v.014zM42.455 77.752c-.626.33-1.7 1.188-2.474 1.834.053-.184.5-1.66 1.436-2.649 1.104-1.168 2.16-1.63 2.16-1.63l-1.421-21.44 1.863-1.339 1.242 24.015s-1.15.338-2.806 1.21M36.394 47.35s2.329.534 4.882.59c1.543.034 3.633-.054 5.276-.028 4.105.065 17.068 1.164 20.067 10.895l-2.21-.28s-2.085-6.114-11.716-8.052c-6.9-1.389-9.662-.152-12.243-.883-2.197-.622-3.407-1.529-4.055-2.232l-.001-.01z"
        fill="#ffffff"
      />
      <path
        d="M73.822 17.799a7.51 7.51 0 0 1 5.05-1.943c4.17 0 7.55 3.387 7.55 7.566a7.559 7.559 0 0 1-2.475 5.603L73.822 40.002V25.905a7.577 7.577 0 0 1-2.06 3.12L61.639 40.002V24.344a7.525 7.525 0 0 1-.994 2.93l-6.61 12.728-6.53-12.978a7.54 7.54 0 0 1-.658-1.663v14.641L36.722 29.026a7.575 7.575 0 0 1-2.06-3.12v14.096L24.538 29.026a7.557 7.557 0 0 1-2.476-5.604c0-4.179 3.38-7.566 7.55-7.566 1.94 0 3.712.735 5.05 1.943v3.14a7.552 7.552 0 0 1 7.133-5.083c1.942 0 3.713.735 5.05 1.943v3.685c.856-3.24 3.8-5.628 7.3-5.628 3.858 0 7.04 2.9 7.493 6.644V17.8a7.51 7.51 0 0 1 5.05-1.943c3.302 0 6.109 2.124 7.134 5.083v-3.14zM51.411 11.177a2.664 2.664 0 0 1 2.663-2.668 2.67 2.67 0 0 1 2.665 2.67 2.664 2.664 0 0 1-2.663 2.668 2.669 2.669 0 0 1-2.665-2.67"
        fill="#95999C"
      />
      <path
        d="M51.411 11.177a2.664 2.664 0 0 1 2.663-2.668c1.143 0 2.12.723 2.497 1.737-.816-.215-1.896-.242-2.838.586-.849.747-1.013 1.803-.966 2.671a2.673 2.673 0 0 1-1.356-2.326M46.846 21.483c.854-3.24 3.798-5.627 7.299-5.627 3.844 0 7.017 2.88 7.488 6.605-.9-1.287-3.272-3.892-7.484-3.406-3.409.394-5.089 2.672-5.366 5.135-.23 2.046-.088 4.039.253 5.878l-1.532-3.044a7.549 7.549 0 0 1-.658-1.663v-3.878zM61.638 17.799a7.51 7.51 0 0 1 5.051-1.943 7.552 7.552 0 0 1 7.114 5.029c-.684-.735-3.045-2.763-7.608-1.83-2.327.476-3.783 1.8-4.557 3.38V17.8zM73.822 17.799a7.51 7.51 0 0 1 5.05-1.943 7.552 7.552 0 0 1 7.115 5.029c-.684-.735-3.045-2.763-7.609-1.83-2.313.473-3.773 1.721-4.556 3.248V17.8zM34.662 17.8v3.233c-.652-.784-2.666-2.186-4.985-1.978-3.05.274-4.668 2.281-4.984 4.754-.262 2.04.078 4.158.704 6.148l-.86-.931a7.556 7.556 0 0 1-2.475-5.604c0-4.179 3.38-7.566 7.55-7.566 1.941 0 3.712.735 5.05 1.943M46.846 17.8l-.001 3.233c-.65-.784-2.665-2.186-4.985-1.978-3.049.274-4.666 2.281-4.983 4.754-.262 2.04.078 4.158.704 6.148l-.86-.931a7.577 7.577 0 0 1-2.059-3.12v-4.967c1.025-2.959 3.831-5.083 7.133-5.083 1.942 0 3.713.735 5.05 1.943"
        fill="#ffffff"
      />
      <path
        d="M94.994 76.608c.267.247.722.684 1.287 1.736.306.57.544 1.492.544 1.492s-3.007-.155-4.852-.169c-2.025-.015-5.077.188-5.077.188s.42-.783 1.102-1.673c.403-.526 1.233-1.256 1.233-1.256s-.038-.221-.068-.332c-4.385 3.573-9.343 3.8-14.021 2.475-2.075-.588-3.733-2.75-4.255-4.584-.748-2.623-1.184-17.007-1.098-22.676 0 0-.614-.725-.928-1.278-.383-.677-.965-2.606-.965-2.606s2.03.303 3.956.343c1.526.031 4.728-.348 4.728-.348s-.508 16.689-.025 23.193c.409 1.912 1.62 3.996 4.939 3.845 4.353-.201 6.928-2.253 7.392-5.687.131-2.395-.127-16.572-.292-17.155-.08-.078-.82-.99-1.178-1.72-.403-.823-.675-2.253-.675-2.253s2.675.274 4.391.25c2.15-.032 4.809-.308 4.828-.312l-.623 3.808s-.28 15.602-.343 24.719"
        fill="#95999C"
      />
      <path
        d="M76.261 79.354a21.24 21.24 0 0 1-1.119-.285c-2.075-.588-3.732-2.75-4.256-4.584-.747-2.623-1.278-16.258-1.192-21.926 0 0-1.41-1.245-1.862-2.093-.529-.994-1.016-2.716-1.016-2.716s3.141.426 5.067.455c2.056.032 4.697-.3 4.697-.3-.41.779-1.159 1.266-2.089 1.81-.848.498-1.58.694-2.358.895 0 8.875.028 18.77.438 22.252.433 3.683 3.548 4.557 3.548 4.557l.142 1.935zM79.633 74.77c.531.15 1.147.22 1.861.189 4.353-.203 6.928-2.254 7.392-5.687.131-2.396-.567-17.223-.567-17.223s-.934-.812-1.41-1.471c-.565-.779-.96-2.432-.96-2.432s3.467.272 5.183.247c2.15-.031 4.809-.31 4.828-.312l-.01.062c-.464.343-1.653 1.194-2.575 1.592-.84.362-2.243.687-2.243.687l.063 14.197c.024 5.735-.495 10.42-7.29 11.74-4.017.779-5.854-.251-5.854-.251l1.582-1.338zM89.231 76.926s-.035-.206-.065-.32l1.966-1.56v2.998c-.87.226-1.354.393-2.18.75-.837.36-1.444.67-2.056 1.06 0 0 .42-.781 1.102-1.672.403-.526 1.233-1.256 1.233-1.256"
        fill="#ffffff"
      />
      <path
        d="M33.71 65.024c-.356-6.472-6.35-11.292-12.286-11.369-4.527-.3-7.46 2.71-7.675 7.777-.314 7.38 6.246 12.143 13.72 12.105 4.473-.023 6.525-3.32 6.24-8.513m6.263-3.062c.047 9.562-7.717 17.507-17.254 17.43-8.634-.07-14.853-7.672-14.853-15.184 0-8.274 7.568-16.487 16.406-16.534 7.01 0 15.652 5.813 15.701 14.288"
        fill="#95999C"
      />
      <path
        d="M33.71 65.024c-.12-2.163-.868-4.141-2.033-5.82l.658-2.754c3.702 3.728 5.462 12.297.622 16.936-3.775 3.617-11.39 2.423-15.704-.885l2.391-.896c2.255 1.262 4.973 1.947 7.825 1.932 4.473-.023 6.525-3.32 6.24-8.513"
        fill="#ffffff"
      />
      <path
        d="M14.643 76.836c-4.176-2.871-6.779-7.77-6.779-12.628 0-8.274 7.569-16.487 16.407-16.535 4.085 0 8.723 1.974 11.893 5.298l-2.328.606c-5.315-4.55-13.513-5.594-19.388.29-8.817 8.835-2.09 18.445.465 20.19l-.27 2.779z"
        fill="#ffffff"
      />
      <path
        fill={colors.primaryColor}
        d="M68.306 82.061h-.492l-.315.96-.315-.96h-.492v1.622h.305v-1.309h.007l.405 1.31h.18l.405-1.31H68v1.31h.305zM66.522 82.061h-1.187v.313h.426v1.31h.335v-1.31h.426z"
      />
    </SvgIcon>
  );
}

export default OldDominionLogo;
