import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function ByuLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 271.16687 176.27562" {...restProps}>
      <g transform="matrix(.625 0 0 .625 .53669 .77519)">
        <path
          fill={variant === 'main' ? colors.primaryColor : '#ffffff'}
          d="m216 0.432c-118.92 0-215.33 62.423-215.33 139.42 0 77 96.41 139.42 215.33 139.42 118.93 0 215.34-62.42 215.34-139.42 0-76.995-96.41-139.42-215.34-139.42z"
        />
        <path
          fill={variant === 'main' ? '#ffffff' : variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
          d="m266.05 60.807c5.171 0.68 22.936 4.136 28.906 6.493l-59.706 72.185-67.7-78.825c5.391-1.393 20.807-2.958 27.762-3.351-0.564-5.712-3.657-34.475-3.657-34.475-37.22 1.271-98.857 17.31-136.28 50.23 2.658 3.187 16.231 20.71 20.833 26.153 5.764-5.54 18.721-14.324 23.004-16.289l83.554 94.757s-0.145 40.574-0.073 42.576c-6.413-0.563-24.361-3.182-28.771-4.563-0.837 5.135-5.673 28.635-6.512 32.707 50.911 12.187 106.3 8.433 141.92-1.102-3.604-15.809-6.94-25.655-8.143-32.011-6.973 1.559-26.421 4.605-30.227 4.888l0.053-42.46 79.326-96.662s16.601 8.897 25.362 17.699c4.13-5.155 13.011-17.858 19.446-26.126-29.65-23.894-66.48-39.163-105.07-45.626-0.556 8.474-3.342 27.958-4.023 33.8z"
        />
      </g>
    </SvgIcon>
  );
}

export default ByuLogo;
