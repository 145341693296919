import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function WashingtonLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 298 205.87" {...restProps}>
      <g transform="matrix(1.3333 0 0 -1.3333 -120.27 885.48)">
        <g>
          <path
            d="m245.9 663.2v-36.664h16.3c-2.1402-7.962-7.5753-28.183-12.638-47.019-6.4923 26.133-20.788 83.685-20.788 83.685l-34.565-2e-3s-15.46-57.558-22.474-83.652c-4.6763 18.906-9.6841 39.138-11.627 46.988h17.184v36.664h-86.175v-36.664h17.858c1.9468-7.7944 29.056-116.24 29.056-116.24l48.903-2e-3s9.2237 35.06 14.832 56.381c5.3357-21.332 14.104-56.379 14.104-56.379l48.896-2e-3s28.557 108.55 30.577 116.24h17.773v36.664zm61.67-31.121h-16.497s-30.218-114.87-30.577-116.24h-40.308c-0.34442 1.385-18.283 73.143-18.283 73.143s-18.886-71.78-19.241-73.143h-40.308c-0.3481 1.3942-29.062 116.24-29.062 116.24h-16.635v25.581h75.09v-25.581h-18.72l18.486-74.707s26.577 98.922 26.944 100.29h25.98c0.34994-1.3942 24.91-100.28 24.91-100.28l20.07 74.703h-17.98v25.581h56.13z"
            fill={variant === 'third' ? '#ffffff' : colors.secondaryColor}
          />
          <path
            d="m251.44 657.66v-25.581h17.98l-20.07-74.696s-24.562 98.886-24.912 100.28h-25.98c-0.36467-1.3611-26.944-100.28-26.944-100.28l-18.486 74.703h18.722v25.581h-75.093v-25.581h16.639s28.71-114.84 29.06-116.24h40.309c0.35362 1.3629 19.236 73.143 19.236 73.143s17.946-71.758 18.287-73.143h40.308c0.35915 1.374 30.574 116.24 30.574 116.24h16.504v25.581z"
            fill={colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default WashingtonLogo;
