import React, { useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import {
  FetchStockMarketLeagueUsersQuery,
  FetchStockMarketLeagueUsersQueryVariables,
} from '../../../../../generated/hooks';
import { useTabs } from '../../../../../utils';
import getPlayerStandingsData from './getPlayerStandingsData';
import PlayerStandingsChart from './PlayerStandingsChart';
import PlayerStandingsTable from './PlayerStandingsTable';

const fetchStockMarketLeagueUsers = gql`
  query fetchStockMarketLeagueUsers($leagueId: UUID!) {
    league(id: $leagueId) {
      users {
        id
        cashBalance
        fullName
        positions {
          quantity
          team {
            stockPurchasePrices
          }
        }
      }
    }
  }
`;

const tabs = [
  {
    route: 'table',
    label: 'Table',
    Component: PlayerStandingsTable,
  },
  {
    route: 'chart',
    label: 'Chart',
    Component: PlayerStandingsChart,
  },
];

function PlayerStandingsInner() {
  const { leagueId } = useParams<{ leagueId: string }>();
  const [{ data }] = useQuery<FetchStockMarketLeagueUsersQuery, FetchStockMarketLeagueUsersQueryVariables>({
    query: fetchStockMarketLeagueUsers,
    variables: { leagueId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });
  const playerStandingsData = useMemo(() => getPlayerStandingsData(data?.league.users), [data?.league.users]);
  const { TabsComponent } = useTabs(tabs, `/league/${leagueId}/player-standings/:standingsDisplay`, 'standingsDisplay');

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TabsComponent leagueId={leagueId} data={playerStandingsData} />
    </Box>
  );
}

export default function PlayerStandings() {
  const { leagueId } = useParams<{ leagueId: string }>();
  return (
    <Routes>
      <Route path="/:standingsDisplay" element={<PlayerStandingsInner />} />
      <Route path="" element={<Navigate to={`/league/${leagueId}/player-standings/table`} replace />} />
      <Route path="*" element={<Navigate to={`/league/${leagueId}/player-standings/table`} replace />} />
    </Routes>
  );
}
