import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function UtepLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 152 112" {...restProps}>
      <g>
        <path
          d="M92.93 5.888l.534-1.606h-10.28l.8-4.282L68.23.401 67.027 4.55h-7.744l-.668 1.873c-20.028.803-40.056 10.303-49.803 17.53l-3.338 2.675 46.733-1.74-1.202 4.015 11.75-.401L44.329 112l18.96-6.022 15.22-78.28 8.68-.267 1.068-4.014 59.817-2.141C128.313 10.571 109.487 6.156 92.93 5.888z"
          fill="#B1B1B1"
        />
        <path
          d="M45.93 82.83C47 80.286 56.747 38.403 57.815 33.72l.534-2.408-19.894.803-8.813 38.537C27.64 80.02 25.904 83.9 23.5 84.702c-.267.134-.401.134-.535.134-.133 0-.267 0-.4-.267-.534-.67-.801-2.409-.267-4.818 1.068-4.55 10.682-44.826 10.815-45.228l.534-2.408-28.974 1.204L0 51.116l8.545-.402c-3.471 13.917-6.943 27.7-6.943 27.833v.134c-2.003 10.036.4 19.67 4.94 24.086l.401.268h.134c.133.133 4.406 3.211 11.482 3.211 5.341 0 11.216-1.739 16.156-4.817 6.943-4.683 9.614-13.515 9.614-13.515l1.602-5.085zM73.57 71.455l4.272-.669 4.54-23.15-4.273.135 3.205-17.262 33.78-1.204-2.67 16.325-12.952 1.07-.4 2.007 8.545-.669-3.071 17.396-8.812 1.07-.267 2.007 18.292-2.408-3.071 17.128-41.525 10.838 4.406-22.614zM117.497 29.17l-2.67 16.192 4.673-.401-6.275 37.734 14.954-3.88 2.804-15.389c9.613-2.274 19.227-14.184 20.028-33.72v-1.74l-33.514 1.205zm16.29 18.467l.667-4.015 2.27-.133c-.801 1.74-1.736 3.077-2.937 4.148z"
          fill="#B1B1B1"
        />
        <path
          d="M53.141 35.594l-9.08.267s-7.476 33.32-8.945 39.742c-1.469 6.022-4.14 10.438-10.815 10.973-3.338 1.07-4.673-2.543-3.605-7.092 1.068-4.55 10.815-45.362 10.815-45.362l-3.472 2.408-17.891.67-1.87 7.493-5.741 4.416 8.545-.402 5.074-2.944-7.878 32.115c-1.602 8.832-3.471 18.6 4.54 26.093 4.006 1.338 10.682 2.007 20.295-3.211 6.008-3.346 10.281-14.184 11.883-19.938 1.603-4.416 11.082-47.37 11.082-47.37h-.133l-2.804 2.142z"
          fill={colors.primaryColor}
        />
        <path
          d="M10.148 37.2l17.624-.67 3.472-2.408h.134l-25.37.936-3.604 14.05 5.741-4.415 2.003-7.494zM8.145 77.878l7.878-32.114-5.074 2.944C9.48 54.194 2.003 79.216 2.537 86.442c.133 7.092 2.537 15.389 10.014 17.396-7.878-7.36-6.008-17.128-4.406-25.96zM34.982 75.603c1.602-6.422 8.946-39.741 8.946-39.741l9.079-.268 2.804-2.141.267-.134-16.022.535s-7.878 35.862-9.747 42.552c-1.603 5.353-3.071 9.635-7.344 10.438.4 0 .935-.134 1.335-.134 6.543-.67 9.213-5.085 10.682-11.106z"
          fill={colors.secondaryColor}
        />
        <path
          d="M110.822 43.89l1.736-10.705-26.304 1.07-1.469 7.628-4.406 4.148 4.273-.401 4.54-3.078-6.41 31.98-4.406.67-2.002 11.24-4.807 4.951 37.519-9.768 2.003-10.705-20.295 2.676 2.537-2.944.934-5.754 8.813-1.07 2.136-12.31-10.949.936 2.804-2.542.668-5.085 13.085-.937z"
          fill={colors.primaryColor}
        />
        <path
          d="M86.254 34.256l26.304-1.07.4-1.874-30.175 1.07-2.404 13.649 4.407-4.148 1.468-7.627zM78.376 75.202l4.407-.67 6.409-31.98-4.54 3.078-5.34 26.896-4.274.669-3.471 18.198 4.807-4.951 2.002-11.24zM94.265 52.454l2.804-2.543v-.133l8.546-.67-.401 2.41-10.949.936zM111.089 70.92l.401-2.543-18.16 2.275-2.536 2.944 20.295-2.676z"
          fill={colors.secondaryColor}
        />
        <path
          d="M120.302 42.82l1.469-.134 3.471-2.275-6.008 35.594-3.872 4.282 11.215-3.212 2.938-15.388c8.278-1.204 18.292-11.641 19.627-30.509l-27.104.937-1.736 10.705zm11.082 8.43l1.469-9.5 6.676-.402 3.605-3.613c-1.602 8.698-4.273 16.191-15.088 17.128 0 0 2.403-2.543 3.338-3.613z"
          fill={colors.primaryColor}
        />
        <path
          d="M125.109 40.277l-3.338 2.275-6.276 37.333-.133.402 3.872-4.416 5.875-35.594z"
          fill={colors.secondaryColor}
        />
        <path
          d="M149.142 29.974v1.204l-27.105.937-1.735 10.705-3.071.267 1.869-12.043 30.042-1.07zM143 37.6l-3.605 3.614c-1.869 4.817-3.605 8.162-8.011 9.902-1.068 1.07-3.471 3.613-3.471 3.613 10.815-.937 13.619-8.43 15.087-17.128z"
          fill={colors.secondaryColor}
        />
        <path
          d="M77.04 6.155l-5.74.134-1.336 4.148H61.42l-.668 1.74c-21.363.936-36.718 5.085-50.87 12.712l44.862-2.409 4.005-4.55-.8 4.149-4.54 4.55 11.482-.402 5.208-4.951-17.625 82.829-5.741 5.085 14.954-4.817 15.221-78.414 8.679-.133 1.202-4.282 56.879-.937c-11.349-5.085-28.707-9.233-51.538-8.965l.4-1.472-17.224.267 5.34-3.747.535-3.077-4.14 2.542z"
          fill={colors.primaryColor}
        />
        <path
          d="M58.75 18.064l-4.007 4.416-1.335 4.148 4.54-4.55.801-4.014zM70.232 21.276l-5.341 4.951-18.159 82.963 5.742-5.085 17.758-82.829zM61.686 10.437h8.545l1.335-4.148 5.742-.134 4.139-2.676.267-1.338-11.616.401-1.335 4.149h-8.68l-.667 1.74c-9.88.4-32.178 4.281-49.536 16.458 14.153-7.627 29.508-11.775 51.138-12.578l.668-1.874zM80.647 6.69h10.147l-.267 1.339c23.633-.402 46.198 8.162 53.141 12.444-14.554-6.155-30.843-8.965-51.539-8.831l.4-1.472-17.223.267 5.34-3.746z"
          fill={colors.secondaryColor}
        />
        <path
          d="M73.035 100.894a2.537 2.537 0 11-5.074 0c0-1.472 1.069-2.543 2.537-2.543 1.469 0 2.537 1.204 2.537 2.543zm-.667 0c0-1.07-.935-2.008-2.003-2.008s-2.003.937-2.003 2.008c0 1.07.935 2.007 2.003 2.007 1.202 0 2.003-.937 2.003-2.007zm-1.336.401l.535.937h-.535l-.4-.937h-.534v.937h-.668v-2.677h1.068c.668 0 .935.268.935.803v.134c0 .402-.134.669-.4.803zm-.534-.401c.267 0 .4-.134.4-.402v-.134c0-.267-.133-.267-.4-.267h-.534v.936h.534v-.133z"
          fill={colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default UtepLogo;
