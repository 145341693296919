import React from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { formatDateTime } from '../../../../dates';
import { GameObjectType, GameStatus, TeamObjectType } from '../../../../generated/schemas';
import { TeamLogoComponent } from '../../../logos';

type BoxScoreProps = {
  game: Pick<
    GameObjectType,
    | 'awayRank'
    | 'awayScore'
    | 'homeOdds'
    | 'homeRank'
    | 'homeScore'
    | 'kickoffDateTime'
    | 'liveScoreData'
    | 'overtimes'
    | 'neutralSite'
    | 'status'
  > & {
    awayTeam: Pick<TeamObjectType, 'name' | 'styles'>;
    homeTeam: Pick<TeamObjectType, 'name' | 'styles'>;
  };
  userPickedHomeTeam?: boolean;
  handleCreatePick: (pickedHomeTeam: boolean) => void;
};

function Header({ game }: { game: BoxScoreProps['game'] }) {
  switch (game.status) {
    case GameStatus.Scheduled:
      return (
        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
          {formatDateTime(game.kickoffDateTime)}
        </Typography>
      );
    case GameStatus.InProgress:
      return (
        <Box>
          <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
            {game.liveScoreData.clock} {game.liveScoreData.quarter}
          </Typography>
          <LinearProgress
            sx={{ height: 2, mt: '-2px', mx: -1, '& .MuiLinearProgress-bar': { animationDuration: '8s' } }}
          />
        </Box>
      );
    case GameStatus.Completed:
      return (
        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
          Final
          {game.overtimes > 0 && <> ({game.overtimes > 1 ? game.overtimes + ' ' : ''}OT)</>}
        </Typography>
      );
    case GameStatus.Canceled:
      return (
        <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
          Canceled
        </Typography>
      );
    default:
      return null;
  }
}

type TeamSelectionProps = {
  odds?: number;
  rank?: number;
  score?: number;
  selected: boolean;
  selectionDisabled: boolean;
  team: Pick<TeamObjectType, 'name' | 'styles'>;
  teamCovered: boolean;
  onSelect: () => void;
  sx?: BoxProps['sx'];
};

function TeamSelection({
  odds,
  rank,
  score,
  selected,
  selectionDisabled,
  team,
  teamCovered,
  onSelect,
  sx = {},
}: TeamSelectionProps) {
  const checkboxColor = selected ? (teamCovered ? 'success.main' : teamCovered === false ? 'error.main' : null) : null;
  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      <TeamLogoComponent fallbackToBox styles={team.styles} variant="main" sx={{ display: 'block' }} />
      {rank ? (
        <Typography variant="caption" sx={{ ml: 1, fontWeight: teamCovered ? 'bold' : null, width: 16 }}>
          {rank}
        </Typography>
      ) : (
        <Box sx={{ ml: 1, width: 16 }} />
      )}
      <Typography variant="body1" sx={{ ml: 0.5, fontWeight: teamCovered ? 'bold' : null }}>
        {team.name}
      </Typography>
      {odds !== null && (
        <Typography variant="caption" sx={{ ml: 1 }}>
          ({odds > 0 ? '+' + odds : odds < 0 ? odds : 'Pick'})
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {score !== null && (
        <Typography variant="body1" sx={{ ml: 1, fontWeight: teamCovered ? 'bold' : null, textAlign: 'right' }}>
          {score}
        </Typography>
      )}
      <Checkbox
        checked={selected}
        disabled={selectionDisabled}
        sx={{ '&.MuiCheckbox-root.Mui-disabled': { color: checkboxColor } }}
        onChange={() => onSelect()}
      />
    </Box>
  );
}

function BoxScore({ game, userPickedHomeTeam, handleCreatePick }: BoxScoreProps) {
  return (
    <Card>
      <CardHeader title={<Header game={game} />} disableTypography sx={{ px: 1, pt: 0.5, pb: 0 }} />
      <Divider />
      <CardContent sx={{ px: 1, pt: 2, pb: '16px !important' }}>
        <TeamSelection
          team={game.awayTeam}
          odds={game.homeOdds !== null ? -Number(game.homeOdds) : null}
          rank={game.awayRank}
          score={game.status === GameStatus.InProgress || game.status === GameStatus.Completed ? game.awayScore : null}
          selected={userPickedHomeTeam === false}
          selectionDisabled={game.status !== GameStatus.Scheduled}
          teamCovered={
            game.status === GameStatus.Completed
              ? game.awayScore > game.homeScore + (game.homeOdds !== null ? Number(game.homeOdds) : 0)
              : null
          }
          onSelect={() => handleCreatePick(false)}
        />
        <TeamSelection
          team={game.homeTeam}
          rank={game.homeRank}
          odds={game.homeOdds !== null ? Number(game.homeOdds) : null}
          score={game.status === GameStatus.InProgress || game.status === GameStatus.Completed ? game.homeScore : null}
          selected={userPickedHomeTeam === true}
          selectionDisabled={game.status !== GameStatus.Scheduled}
          teamCovered={
            game.status === GameStatus.Completed
              ? game.homeScore + (game.homeOdds !== null ? Number(game.homeOdds) : 0) > game.awayScore
              : null
          }
          onSelect={() => handleCreatePick(true)}
          sx={{ mt: 2 }}
        />
      </CardContent>
    </Card>
  );
}

export default BoxScore;
