import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function JamesMadisonLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 250" {...restProps}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="JMAD" fillRule="nonzero">
          <g transform="translate(0.000000, 84.000000)">
            <g transform="translate(125.195205, 41.671488) scale(-1, 1) rotate(-180.000000) translate(-125.195205, -41.671488) translate(0.623288, 0.514463)">
              <g transform="translate(0.015154, 0.654465)" id="Shape">
                <path
                  d="M44.8630137,81.4566116 L37.5999144,52.1142252 L7.41840753,52.1142252 L0.844777397,25.5559401 C-0.658047945,19.487251 0.62885274,13.2276963 4.37517123,8.38317149 C8.13013699,3.52715702 13.8494863,0.74271281 20.0686644,0.74271281 L207.791267,0.74271281 C219.29726,0.74271281 229.670548,8.91401136 232.457363,20.1722583 L247.624315,81.4559256 L44.8674658,81.4559256"
                  fill={colors.secondaryColor}
                />
                <path
                  d="M47.6284247,77.9153926 L40.3652397,48.5730062 L10.1837329,48.5730062 L4.27542808,24.6993595 C3.03364726,19.68772 4.08912671,14.5284298 7.17003425,10.5439153 C10.2503425,6.55965806 14.9532534,4.27510021 20.0707192,4.27510021 L207.793322,4.27510021 C217.698288,4.27510021 226.629795,11.3166384 229.031336,21.0182924 L243.112671,77.9153068 L47.6332192,77.9153068"
                  fill={colors.primaryColor}
                />
                <path
                  d="M53.7297945,12.1441553 L42.8044521,56.2795768 L33.4523973,56.2795768 L37.1152055,41.4863702 L16.2850685,41.4863702 L11.9226712,59.1132379 C11.2614521,61.7843974 11.8230685,64.5338239 13.465137,66.6573206 C15.1071918,68.7801999 17.6140411,69.9972817 20.3411644,69.9972817 L51.1788356,69.9972817 C57.4665753,69.9972817 63.1363699,65.5286578 64.6610274,59.369166 L76.3500685,12.1442239 L53.7302055,12.1442239 L53.7297945,12.1441553 Z M210.47637,12.1441553 L199.551027,56.2795768 L190.198973,56.2795768 L201.116096,12.176395 L178.496233,12.176395 L166.880479,59.1132379 C166.21789,61.7843974 166.779507,64.5338239 168.422945,66.6573206 C170.06363,68.7801999 172.571233,69.9972817 175.297603,69.9972817 L207.925685,69.9972817 C214.213425,69.9972817 219.883219,65.5286578 221.407877,59.369166 L233.098288,12.1442239 L210.477055,12.1442239 L210.47637,12.1441553 Z M85.7503425,12.1763058 L71.4297945,70.0293636 L94.0510274,70.0293636 L102.144178,37.3342231 L104.834384,70.0293636 L121.337123,70.0293636 L140.309041,37.169595 L132.17411,70.0293636 L154.796712,70.0293636 L169.11589,12.1763058 L134.007671,12.1763058 L117.953562,41.0664793 L115.090753,12.1763058 L85.7496575,12.1763058 L85.7503425,12.1763058 Z"
                  fill={colors.secondaryColor}
                  transform="translate(122.374961, 41.086759) scale(-1, 1) rotate(-180.000000) translate(-122.374961, -41.086759) "
                />
                <path
                  d="M72.2003425,12.1442925 L60.7941781,58.2250693 C59.6895205,62.6884115 55.6430137,65.8775321 51.0859589,65.8775321 L12.9318493,65.8775321 C13.0952877,66.1450528 13.2711781,66.4066331 13.4656164,66.657252 C15.1069863,68.7808173 17.6132192,69.9972131 20.340274,69.9972131 L51.1779452,69.9972131 C57.4656849,69.9972131 63.1354795,65.5285892 64.660137,59.3690974 L76.3491781,12.1441553 L72.1994521,12.1441553 L72.2003425,12.1442925 Z M228.946918,12.1442925 L217.544178,58.2250693 C216.440205,62.6884115 212.391644,65.8775321 207.834589,65.8775321 L167.890753,65.8775321 C168.054199,66.1450528 168.230089,66.4066331 168.424521,66.657252 C170.06589,68.7808173 172.572808,69.9972131 175.299178,69.9972131 L207.92726,69.9972131 C214.215,69.9972131 219.886164,65.5285892 221.409452,59.3690974 L233.099863,12.1441553 L228.947466,12.1441553 L228.946918,12.1442925 Z M164.960616,12.176443 L151.659247,65.9137983 L133.192808,65.9137983 L132.173425,70.0295008 L154.796027,70.0295008 L169.115205,12.176443 L164.960137,12.176443 L164.960616,12.176443 Z M196.962671,12.176443 L186.046233,56.2796248 L190.201301,56.2796248 L201.11774,12.176443 L196.962671,12.176443 Z M143.654452,23.6393603 L119.222945,65.9137983 L104.494178,65.9137983 L104.83263,70.0295008 L121.33537,70.0295008 L140.307288,37.1697322 L143.655712,23.6393603 L143.654452,23.6393603 Z M101.108562,24.7835256 L90.9284247,65.9137983 L72.4462329,65.9137983 L71.4268493,70.0295008 L94.0480822,70.0295008 L102.141233,37.3343603 L101.108493,24.7835256 L101.108562,24.7835256 Z M32.9592466,41.4864182 L29.2978082,56.2796248 L33.4528767,56.2796248 L37.1156849,41.4864182 L32.9592466,41.4864182 Z M11.8393836,63.622724 C11.8445205,63.6421844 11.8520342,63.6608697 11.8581096,63.6803301 C11.853863,63.6635243 11.8494384,63.6462246 11.8447329,63.6294188 L11.8393904,63.6226965 L11.8393836,63.622724 Z"
                  fill="#ffffff"
                  transform="translate(122.469623, 41.086828) scale(-1, 1) rotate(-180.000000) translate(-122.469623, -41.086828) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default JamesMadisonLogo;
