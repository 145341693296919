import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function NebraskaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 170.8125 170.8125" {...restProps}>
      <g>
        <path
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          d="m1.2008 0.79535v5.5527 38.871 5.5527h5.5527 5.5527v68.861h-5.5527-5.5527v5.5527 38.867 5.5547h5.5527 59.973 5.5527v-5.5547-38.867-5.5527h-5.5527-5.5527v-27.521l47.51 74.918 1.6348 2.5781h3.0547 51.088 5.5527v-5.5547-38.867-5.5527h-5.5527-5.5527v-68.861h5.5527 5.5527v-5.5527-38.871-5.5527h-5.5527-59.973-5.5527v5.5527 38.871 5.5527h5.5527 5.5527v27.525l-47.502-74.936-1.635-2.578h-3.054-51.086-5.553z"
        />
        <path
          fill={variant === 'inverted' ? colors.primaryColor : '#ffffff'}
          d="m3.4214 3.016v3.332 38.871 3.3301h3.332 7.7734v73.305h-7.7734-3.332v3.332 38.867 3.334h3.332 59.973 3.332v-3.334-38.867-3.332h-3.332-7.7734v-37.391l51.605 81.375 0.98242 1.5488h1.832 51.088 3.332v-3.334-38.867-3.332h-3.332-7.7734v-73.305h7.7734 3.332v-3.3301-38.871-3.332h-3.332-59.973-3.332v3.332 38.871 3.3301h3.332 7.7734v37.398l-51.607-81.385-0.98047-1.5469h-1.834-51.086-3.332z"
        />
        <path
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          d="m6.7534 6.348v38.871h11.105v79.965h-11.105v38.871h59.973v-38.871h-11.105v-52.195l57.752 91.066h51.088v-38.871h-11.105v-79.965h11.105v-38.871h-59.973v38.871h11.105v52.205l-57.754-91.076h-51.086z"
        />
      </g>
    </SvgIcon>
  );
}

export default NebraskaLogo;
