import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MichiganLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 294.32812 212.39062" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -123.87 786.33)">
        <g transform="matrix(2.5 0 0 2.5 -323.12 -814.35)">
          <path
            d="m234.43 576.97-18.42-25.467-18.389 25.467h-28.368v-20.011h7.027v-27.304h-7.027v-20.01h36.173v20.01h-7.495v15.67l17.982-24.726 18.207 24.747v-15.691h-7.495v-20.01h36.173v20.01h-7.027v27.304h7.027v20.011h-28.368z"
            fill={variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
            stroke={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
            strokeWidth={variant === 'main' ? '0' : '1'}
          />
          <path
            d="m261.29 558.47v16.998h-26.092l-19.191-26.532-19.157 26.532h-26.092v-16.998h7.027v-30.317h-7.027v-16.998h33.161v16.998h-7.496v21.807l19.495-26.806 19.707 26.785v-21.786h-7.495v-16.998h33.16v16.998h-7.027v30.317h7.027"
            fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default MichiganLogo;
