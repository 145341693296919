import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function CentralFloridaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 200.84505" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -177.26 803.76)">
        <g>
          <path
            d="m292.97 578.78 4.0207 17.143-23.893 17.565h-24.627l6.6956 28.773-112.78 0.01 26.757-22.233-12.838-55.174 23.324-17.417h24.391l-2.746-11.718 22.857-17.829h23.244l-8.0751-34.705h45.07l5.9553 25.59s2.9301-1.5395 5.6323 0.72862c2.2759 1.9106 1.8096 4.8314 1.8096 4.8314h18.626l6.3605 27.346h9.4016l8.6294 37.089h-47.819z"
            fill="#fff"
          />
          <path
            d="m16.525 6.6875l23.807 19.784-16 68.758 24.691 18.44h35.741l-4.235 18.07 24.181 18.86h34.24l-10.1 43.38h45.06l9.06-38.94h30.97l7.95-34.18h11.75l8.1-34.809h-59.78l5.8-24.737-25.37-18.648h-34.91l-1.3 0.711 8.53-36.68-118.18-0.0095zm172.12 150.18c-2.65 0-4.74 2.11-4.74 4.81s2.09 4.84 4.71 4.84c2.61 0 4.68-2.14 4.68-4.84s-2.06-4.81-4.65-4.81zm-0.06 0.97c2.03 0 3.54 1.73 3.54 3.87 0 2.11-1.51 3.85-3.48 3.82-2.04 0-3.57-1.71-3.57-3.85 0-2.11 1.53-3.84 3.51-3.84zm0 1.38c-0.75 0-1.34 0.09-1.75 0.14v4.82h1.05v-1.91h0.5c0.59 0 0.87 0.23 0.95 0.73 0.14 0.59 0.23 1 0.37 1.18h1.11c-0.09-0.18-0.2-0.45-0.34-1.18-0.13-0.64-0.36-1.02-0.8-1.17v-0.06c0.53-0.18 0.94-0.56 0.94-1.15 0-0.46-0.19-0.82-0.47-1.02-0.31-0.24-0.7-0.38-1.56-0.38zm-0.08 0.82c0.66 0 0.97 0.29 0.97 0.73 0 0.5-0.47 0.71-1.06 0.71h-0.5v-1.38c0.11-0.03 0.31-0.06 0.59-0.06z"
            transform="matrix(.8 0 0 -.8 141.81 643)"
            fill="#000"
          />
          <path
            d="m268.97 560.58h-13.537l-4.0866-17.434h-15.053l9.1284 38.924-7.4799 6.1916h23.98l-3.2442-13.837h21.608l3.8505 16.41-14.594 10.728h-24.053l-12.689-6.9445 8.4018 36.104-30.68-0.003 7.4765-6.2126-12.166-52.281h-14.773l13.611 58.497-30.679 0.006 7.4786-6.2142-12.742-54.761 14.395-10.749h25.424l11.402 6.6374-5.8684-25.043 13.79-10.757h25.659l9.5597 5.4725-9.3496-40.177h21.757l7.2488 31.15h24.774l3.0762 13.226h-24.774l1.8427 7.9106-7.4799 6.2091h43.097l3.0762 13.222h-64.852l7.4629-6.2744z"
            fill="#b7a369"
          />
          <path
            d="m50.789 19.213l2.902 2.412-15.896 68.314 3.312 2.473 16.159-69.187 15.705 0.004 0.931-4.01-23.113-0.006zm68.781 0.006l-23.123 0.004c0.211 0.174 2.903 2.412 2.903 2.412l-16.452 70.705h-22.121l-0.933 4.012h26.01l17.016-73.12 15.76-0.002 0.94-4.011zm10.92 36.449l-22.29 12.197-13.723 58.575 3.294 2.57 13.749-58.412 19.92-10.918h32.53l-5.46-4.012h-28.02zm23.38 12.916l-3.11 13.285h3.89l3.12-13.285h-3.9zm6.31 30.469l2.9 2.417-18.5 79.51h3.89l18.15-77.92h58.46l0.94-4.007h-65.84zm-15.29 7.847l-5.11 21.79h-22.46l-0.94 4.01h26.36l5.11-21.79h7.79l0.94-4.01h-11.69zm34.34 26.33l-0.93 4.01h27.08c0.28-1.21 0.58-2.52 0.93-4.01h-27.08z"
            transform="matrix(.8 0 0 -.8 141.81 643)"
            fill="#fff"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CentralFloridaLogo;
