import React from 'react';
import { Box, CircularProgress, styled } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { FetchCurrentUserQuery, FetchCurrentUserQueryVariables } from '../../generated/hooks';
import EmailVerification from '../EmailVerification';
import AppHeader from './AppHeader';
import ChangePasswordForm from './ChangePasswordForm';
import LeagueDetail from './LeagueDetail';
import LeagueList from './LeagueList';
import LogosList from './LogosList';

export const fetchCurrentUser = gql`
  query fetchCurrentUser {
    currentUser {
      fullName
      isEmailVerified
    }
  }
`;

const AppContent = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
}));

function App() {
  const [{ data }] = useQuery<FetchCurrentUserQuery, FetchCurrentUserQueryVariables>({
    query: fetchCurrentUser,
  });
  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <AppHeader currentUser={data?.currentUser} />
      <AppContent>
        <Routes>
          {data.currentUser.isEmailVerified ? (
            <>
              <Route path="/change-password" element={<ChangePasswordForm />} />
              <Route path="/league/:leagueId/*" element={<LeagueDetail />} />
              <Route path="/" element={<LeagueList />} />
              <Route path="/logos" element={<LogosList />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/verify-email" element={<EmailVerification />} />
              <Route path="*" element={<Navigate to="/verify-email" />} />
            </>
          )}
        </Routes>
      </AppContent>
    </>
  );
}

export default App;
