import React, { DOMAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { BalanceChangeType, LeagueType, PriceChangeType } from './generated/schemas';

function getOptions(labelObject: Record<string, string>) {
  return Object.entries(labelObject).map(([value, label]) => ({ label, value }));
}

export const leagueTypeLabel: Record<LeagueType, string> = {
  PICK_EM: "Pick 'Em",
  STOCK_MARKET: 'Stock Market',
};

export const leagueTypeOptions = getOptions(leagueTypeLabel);

export const balanceChangeTypeLabel: Record<BalanceChangeType, string> = {
  INITIAL_BALANCE: 'Initial Balance',
  SHARES_PURCHASED: 'Shares Purchased',
  SHARES_SOLD: 'Shares Sold',
  DIVIDENDS_RECEIVED: 'Dividends Received',
  POSTSEASON_DIVIDENDS_RECEIVED: 'Postseason Dividends',
};

export const priceChangeTypeLabel: Record<PriceChangeType, string> = {
  INITIAL_PRICE: 'Initial Price',
  INITIAL_PURCHASES: 'Initial Purchases',
  GAME_WON: 'Game Won',
  SHARES_PURCHASED: 'Shares Purchased',
  SHARES_SOLD: 'Shares Sold',
  NEW_RATINGS: 'New Ratings',
};

export function buildUrl(url: string, host: string = process.env.REACT_APP_API_URL) {
  return `${host}${url}`;
}

type HoverProps = Pick<DOMAttributes<Element>, 'onMouseEnter' | 'onMouseLeave'>;
type UseHoverResult = [HoverProps, boolean];

export function useHover() {
  const [hover, setHover] = useState(false);
  const onMouseEnter = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);
  const hoverProps: HoverProps = useMemo(() => ({ onMouseEnter, onMouseLeave }), [onMouseEnter, onMouseLeave]);
  const result: UseHoverResult = useMemo(() => [hoverProps, hover], [hoverProps, hover]);
  return result;
}

type TabConfig<P = Record<string, unknown>> = {
  route: string;
  label: string;
  Component: React.ComponentType<P>;
};

export function useTabs<P = Record<string, unknown>>(tabConfig: TabConfig<P>[], path: string, pathSegment: string) {
  const { [pathSegment]: pathSegmentValue } = useParams();
  const [tab, setTab] = useState(tabConfig.findIndex(config => config.route === pathSegmentValue));
  const navigate = useNavigate();

  useEffect(() => {
    const newTab = tabConfig.findIndex(config => config.route === pathSegmentValue);
    if (newTab === -1) {
      navigate(generatePath(path, { [pathSegment]: tabConfig[0].route }));
      return;
    }
    if (newTab !== tab) {
      setTab(newTab);
    }
  }, [pathSegmentValue]);

  const TabsComponent = useCallback(
    (props: P) => {
      const Component = tabConfig[tab]?.Component;
      return (
        <>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab}
            onChange={(_, newTab) => {
              navigate(generatePath(path, { [pathSegment]: tabConfig[newTab].route }));
              return setTab(newTab);
            }}
            sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}
          >
            {tabConfig.map(config => (
              <Tab key={config.route} label={config.label} />
            ))}
          </Tabs>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>{Component && <Component {...props} />}</Box>
        </>
      );
    },
    [navigate, pathSegment, tabConfig, tab],
  );

  return { TabsComponent };
}
