import React from 'react';
import { AdminPanelSettings as AdminPanelSettingsIcon } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import {
  FetchStockMarketLeagueDetailQuery,
  FetchStockMarketLeagueDetailQueryVariables,
} from '../../../../generated/hooks';
import { useTabs } from '../../../../utils';
import PlayerStandings from './PlayerStandings';
import Rules from './Rules';
import TeamPrices from './TeamPrices';
import Transactions from './Transactions';

const fetchStockMarketLeagueDetail = gql`
  query fetchStockMarketLeagueDetail($leagueId: UUID!) {
    league(id: $leagueId) {
      name
      userIsCommissioner
    }
  }
`;

const tabs = [
  { route: 'player-standings', label: 'Player Standings', Component: PlayerStandings },
  { route: 'team-prices', label: 'Team Prices', Component: TeamPrices },
  { route: 'transactions', label: 'Transactions', Component: Transactions },
  { route: 'rules', label: 'Rules', Component: Rules },
];

export default function LeagueHome() {
  const { leagueId } = useParams<{ leagueId: string }>();
  const [{ data }] = useQuery<FetchStockMarketLeagueDetailQuery, FetchStockMarketLeagueDetailQueryVariables>({
    query: fetchStockMarketLeagueDetail,
    variables: { leagueId },
  });

  const { TabsComponent } = useTabs(tabs, `/league/${leagueId}/:leaguePage`, 'leaguePage');

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <Box style={{ display: 'flex' }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {data.league.name}
        </Typography>
        {data.league.userIsCommissioner && <AdminPanelSettingsIcon />}
      </Box>
      <TabsComponent />
    </>
  );
}
