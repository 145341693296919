import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { formatDate, formatTime } from '../../../../../dates';
import {
  FetchStockMarketTeamScheduleQuery,
  FetchStockMarketTeamScheduleQueryVariables,
} from '../../../../../generated/hooks';
import { GameStatus } from '../../../../../generated/schemas';
import { TeamLogoComponent } from '../../../../logos';

const fetchStockMarketTeamSchedule = gql`
  query fetchStockMarketTeamSchedule($leagueId: UUID!, $teamId: UUID!) {
    league(id: $leagueId) {
      team(id: $teamId) {
        games {
          id
          awayRank
          awayScore
          homeRank
          homeScore
          kickoffDateTime
          status
          awayTeam {
            id
            classification
            currentRank
            name
            styles {
              primaryColor
              secondaryColor
              logoComponent
            }
          }
          homeTeam {
            id
            classification
            currentRank
            name
            styles {
              primaryColor
              secondaryColor
              logoComponent
            }
          }
        }
      }
    }
  }
`;

export default function Schedule() {
  const { leagueId, teamId } = useParams<{ leagueId: string; teamId: string }>();
  const [{ data }] = useQuery<FetchStockMarketTeamScheduleQuery, FetchStockMarketTeamScheduleQueryVariables>({
    query: fetchStockMarketTeamSchedule,
    variables: { leagueId, teamId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Table
      sx={{
        maxWidth: 1200,
        '& .MuiTableCell-sizeSmall': {
          px: 0.5,
        },
        '& .MuiTableCell-sizeSmall:first-of-type': {
          pl: 0,
        },
        '& .MuiTableCell-sizeSmall:last-of-type': {
          pr: 0,
        },
      }}
      size={isMdDown ? 'small' : 'medium'}
    >
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Opponent</TableCell>
          <TableCell>Result</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.league.team.games.map(game => {
          const home = game.homeTeam.id === teamId;
          const kickoffDateTime = new Date(game.kickoffDateTime);
          const gameTimeIsScheduled = !kickoffDateTime
            .toLocaleString(undefined, {
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short',
              timeZone: 'America/New_York',
            })
            .startsWith('12:00 AM');
          const homeRank = kickoffDateTime < new Date() ? game.homeRank : game.homeRank || game.homeTeam.currentRank;
          const awayRank = kickoffDateTime < new Date() ? game.awayRank : game.awayRank || game.awayTeam.currentRank;
          return (
            <TableRow key={game.id}>
              <TableCell>{formatDate(game.kickoffDateTime)}</TableCell>
              <TableCell>
                {home ? (
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Box>vs</Box>
                    {game.awayTeam.classification === 'FBS' ? (
                      <MuiLink
                        component={Link}
                        to={`/league/${leagueId}/team/${game.awayTeam.id}`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <TeamLogoComponent styles={game.awayTeam.styles} />
                        <Box sx={{ ml: 1 }}>
                          {awayRank && `#${awayRank}`} {game.awayTeam.name}
                        </Box>
                      </MuiLink>
                    ) : (
                      <>
                        <TeamLogoComponent styles={game.awayTeam.styles} />
                        <Box sx={{ ml: 1 }}>
                          {awayRank && `#${awayRank}`} {game.awayTeam.name}
                        </Box>
                      </>
                    )}
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Box>@</Box>
                    {game.awayTeam.classification === 'FBS' ? (
                      <MuiLink
                        component={Link}
                        to={`/league/${leagueId}/team/${game.homeTeam.id}`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <TeamLogoComponent styles={game.homeTeam.styles} />
                        <Box sx={{ ml: 1 }}>
                          {homeRank && `#${homeRank}`} {game.homeTeam.name}
                        </Box>
                      </MuiLink>
                    ) : (
                      <>
                        <TeamLogoComponent styles={game.homeTeam.styles} />
                        <Box sx={{ ml: 1 }}>
                          {homeRank && `#${homeRank}`} {game.homeTeam.name}
                        </Box>
                      </>
                    )}
                  </Stack>
                )}
              </TableCell>
              <TableCell>
                {game.status === GameStatus.Completed ? (
                  <>
                    {home === game.homeScore > game.awayScore ? 'W' : 'L'} {home ? game.homeScore : game.awayScore}-
                    {home ? game.awayScore : game.homeScore}
                  </>
                ) : (
                  <>{gameTimeIsScheduled && formatTime(game.kickoffDateTime)}</>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
