import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function ArizonaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 252 232.79" {...restProps}>
      <g transform="matrix(.5 0 0 .5 1.2339 1.1773)">
        <path
          d="m402.8 0.048948v149.89l-15.181-0.0734 60.505 161.63 51.877 0.0674v150.01h-500v-149.81l51.51 0.06 61.04-162.09-15.508 0.04 0.061-149.78 305.7 0.049z"
          fill="#ffffff"
        />
        <path
          d="m250.01 55.622h96.565v38.623h-40.116l105.47 271.85h31.195v39.994h-130.73v-38.911h-36.696v76.298h206.33v-113.77l-46.333 0.0795-74.273-198.01h23.326v-113.7h-134.75-134.75v113.7h23.332l-74.279 197.76-46.333 0.17132v113.77h206.35v-76.298h-36.708v38.911h-130.73v-39.994h31.195l105.47-271.85h-40.11v-38.623h96.565z"
          fill={colors.primaryColor}
        />
        <path
          d="m249.91 55.971 107.09 277.7s13.719 33.796 36.004 33.796h-77.975s3.9162 0.56908 7.8201-5.9355c2.2335-3.7143 0.34879-9.2887 0.34879-9.2887l-24.115-61.992h-49.179-48.977l-24.146 61.992s-1.8418 5.5744 0.3855 9.2887c3.8856 6.5046 7.7896 5.9355 7.7896 5.9355h-77.969c22.273 0 36.011-33.796 36.011-33.796l106.92-277.7m-0.0856 204.84h37.724l-36.984-97.415-38.293 97.415h37.553z"
          fill={colors.secondaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default ArizonaLogo;
