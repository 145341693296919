import React, { useRef } from 'react';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { GameStatus } from '../../../../generated/schemas';
import TeamChip from '../../TeamChip';
import BoxScore from './BoxScore';
import PicksTableProps from './PicksTableProps';

function DesktopPicksTable({ data, leagueId, userId, week, weekPickUsers, onCreatePick }: PicksTableProps) {
  const columnRefs = [useRef<HTMLTableCellElement>(), useRef<HTMLTableCellElement>()];
  const columnOffsets = [
    columnRefs[0].current?.clientWidth || 0,
    (columnRefs[0].current?.clientWidth || 0) + (columnRefs[1].current?.clientWidth || 0),
  ];
  return (
    <Box sx={{ maxWidth: '100%', overflowY: 'scroll' }}>
      <Table
        sx={{
          '& .MuiTableCell-root': { px: 1, py: 1, height: 57 },
          '& .MuiTableCell-root:first-of-type': { pl: 0 },
          '& .MuiTableCell-root:last-of-type': { pr: 0 },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ position: 'sticky', left: 0, backgroundColor: 'white', whiteSpace: 'nowrap', zIndex: 10 }}
              ref={columnRefs[0]}
            />
            <TableCell
              sx={{
                position: 'sticky',
                left: columnOffsets[0],
                backgroundColor: 'white',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                zIndex: 10,
              }}
              ref={columnRefs[1]}
            >
              Total Score
            </TableCell>
            <TableCell
              sx={{
                position: 'sticky',
                left: columnOffsets[1],
                backgroundColor: 'white',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                zIndex: 10,
              }}
            >
              Week Score
            </TableCell>
            {data.league.games.map(game => (
              <TableCell key={game.id} sx={{ textAlign: 'center', minWidth: '370px' }}>
                <BoxScore
                  game={game}
                  userPickedHomeTeam={
                    weekPickUsers.find(user => user.id === userId).picks.find(pick => pick.gameId === game.id)
                      .pickedHomeTeam
                  }
                  handleCreatePick={pickedHomeTeam => onCreatePick({ leagueId, gameId: game.id, pickedHomeTeam })}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {weekPickUsers.map(weekPickUser => (
            <TableRow key={weekPickUser.id}>
              <TableCell sx={{ position: 'sticky', left: 0, backgroundColor: 'white', whiteSpace: 'nowrap' }}>
                {weekPickUser.fullName}
              </TableCell>
              <TableCell
                sx={{
                  position: 'sticky',
                  left: columnOffsets[0],
                  backgroundColor: 'white',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {weekPickUser.totalScore}
              </TableCell>
              <TableCell
                sx={{
                  position: 'sticky',
                  left: columnOffsets[1],
                  backgroundColor: 'white',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {weekPickUser.weekScores[week]}
              </TableCell>
              {data.league.games.map(game => {
                const pick = weekPickUser.picks.find(p => p.gameId === game.id);
                const homeTeamCovered =
                  game.status === GameStatus.Completed
                    ? game.homeScore + (game.homeOdds !== null ? Number(game.homeOdds) : 0) > game.awayScore
                    : null;
                return (
                  <TableCell key={game.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {pick.pickHidden ? (
                        <TeamChip team={{ name: 'Pick Hidden' }} />
                      ) : (
                        pick.pickedHomeTeam !== null && (
                          <TeamChip team={pick.pickedHomeTeam ? game.homeTeam : game.awayTeam} />
                        )
                      )}
                      {homeTeamCovered ? (
                        pick.pickedHomeTeam ? (
                          <CheckCircleIcon sx={{ color: 'success.main', ml: 1 }} />
                        ) : (
                          <CancelIcon sx={{ color: 'error.main', ml: 1 }} />
                        )
                      ) : homeTeamCovered === false ? (
                        pick.pickedHomeTeam === false ? (
                          <CheckCircleIcon sx={{ color: 'success.main', ml: 1 }} />
                        ) : (
                          <CancelIcon sx={{ color: 'error.main', ml: 1 }} />
                        )
                      ) : null}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default DesktopPicksTable;
