import React, { useEffect, useState } from 'react';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { IconButton, SxProps, TextField, Theme, Typography } from '@mui/material';
import { Control, Controller, Path, RegisterOptions } from 'react-hook-form';

type InputFieldBaseProps = {
  helperText?: React.ReactNode;
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
  type?: 'text' | 'email' | 'password';
  value: string;
};

export const InputFieldBase = React.forwardRef(function InputFieldBase(
  { helperText, label, name, onChange, sx, type = 'text', value }: InputFieldBaseProps,
  _ref: React.Ref<HTMLInputElement>,
) {
  const [displayType, setDisplayType] = useState(type);
  useEffect(() => {
    setDisplayType(type);
  }, [type]);
  return (
    <TextField
      helperText={helperText}
      InputProps={{
        endAdornment:
          type === 'password' &&
          (displayType === 'password' ? (
            <IconButton tabIndex={-1} onClick={() => setDisplayType('text')}>
              <VisibilityIcon />
            </IconButton>
          ) : (
            <IconButton tabIndex={-1} onClick={() => setDisplayType('password')}>
              <VisibilityOffIcon />
            </IconButton>
          )),
      }}
      label={label}
      name={name}
      onChange={onChange}
      sx={sx}
      type={displayType}
      value={value}
    />
  );
});

type InputFieldProps<TFieldValues extends { [key: string]: unknown }> = {
  control: Control<TFieldValues>;
  helperText?: string;
  label: string;
  name: string;
  rules?: Omit<RegisterOptions<TFieldValues>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  sx?: SxProps<Theme>;
  type?: 'text' | 'email' | 'password';
};

function InputField<TFieldValues extends { [key: string]: unknown }>({
  control,
  helperText,
  label,
  name,
  rules,
  sx,
  type,
}: InputFieldProps<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name as Path<TFieldValues>}
      rules={rules}
      render={({ field: { name, onChange, value }, fieldState: { error } }) => {
        const displayHelperText = error?.message ? (
          <Typography variant="caption" color="error">
            {error.message}
          </Typography>
        ) : (
          helperText && <Typography variant="caption">{helperText}</Typography>
        );

        return (
          <InputFieldBase
            helperText={displayHelperText}
            label={label}
            name={name as string}
            onChange={onChange}
            sx={sx}
            type={type}
            value={value as string}
          />
        );
      }}
    />
  );
}

export default InputField;
