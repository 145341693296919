import React from 'react';
import { Box, TableCell } from '@mui/material';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type MoneyCellProps = {
  value: string;
};

// TODO: Convert to use currency.js
export function MoneyCell({ value }: MoneyCellProps) {
  const valueNumber = parseFloat(value);
  const formattedAmount = valueNumber === 0 ? '\u2013' : formatter.format(valueNumber).substring(1);
  return (
    <TableCell sx={{ textAlign: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: 100, margin: 'auto' }}>
        <Box sx={{ mr: 2 }}>$</Box>
        <Box>{formattedAmount}</Box>
      </Box>
    </TableCell>
  );
}
