import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function WestVirginiaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 169.22 163.52" {...restProps}>
      <g transform="translate(.88962 1.1079)">
        <path
          fill={variant === 'third' ? colors.secondaryColor : colors.primaryColor}
          d="m129.92 64.446 12.994-22.446s1.3738-3.0475 5.125-2.68l19.108-0.05375v-38.93h-36.446c-2.2012 0.35875-6.4838-0.55-11.21 5.7262l-13.84 23.301-14.994-26.53s-5.4612-6.3738-11.825 0l-16.483 25.221-12.244-22.332s-2.99-5.4988-10.089-5.4988h-39.985l-0.03 38.941 18.266 0.02s4.1912-0.96625 6.2062 3.7075c0 0 72.479 115.56 72.402 115.56 3.9738 4.1925 9.6188 4.0262 12.741-0.12625l31.794-48.785s2.45-5.81 6.5212-5.8012h19.268l0.0188-39.33-37.299 0.0375z"
        />
        <path
          fill={variant === 'third' ? colors.primaryColor : colors.secondaryColor}
          d="m7.1094 7.5684-0.0547 24.026h14.809s4.7966-0.36909 6.9316 3.1621l30.727 50.246s1.9151 4.4688 5.3789 0l19.744-27.176 17.027 27.176s2.5292 4.705 5.6016 0l31.168-50.246s1.1923-3.5321 6.5449-3.1621h14.809v-23.959h-28.725s-3.1675-1.0874-7.375 6.4902l-18.62 27.785-17.97-29.117s-2.4407-5.9638-5.1582 0l-19.473 29.117-16.637-27.785s-2.9264-6.4902-7.5977-6.4902l-31.115-0.0664zm125.29 64.178c-6.03 0-8.9844 4.9375-8.9844 4.9375h0.004l-19.03 27.566-20.255-32.369-16.762 23.533 33.197 55.476c3.165 5.3988 5.8203 0 5.8203 0l32.059-50.191c3.0425-4.8888 6.9883-4.2715 6.9883-4.2715h14.365v-24.68h-27.4z"
        />
      </g>
    </SvgIcon>
  );
}

export default WestVirginiaLogo;
