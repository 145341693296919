import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function KentuckyLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 197.58203 162.02344" {...restProps}>
      <g>
        <path
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          d="m1.0493 0.95871v28.449h10.67v53.338h0.0059l-0.0059 0.004 17.781 17.775h24.893v32.008h-10.672v28.449h64.01v-28.449h-10.67v-7.1172h19.557l12.449 12.451v23.115h67.562l0.002-28.449h-0.002-16.002l-25.777-25.781 24-24.002h17.781l-0.002-28.451-56.896 0.0039v-24.895h10.67v-28.449h-64.008-0.002v28.449h0.002 10.666v24.895h-42.672v-24.895h10.666 0.006v-28.449h-0.006-64.006z"
        />
        <path
          fill={variant === 'inverted' ? colors.primaryColor : '#ffffff'}
          d="m149.52 106.75 27.559-27.56h16.002v-21.338h-56.898v-32.002h10.67v-21.335h-56.899v21.335h10.671v32.002h-49.786v-32.002h10.669v-21.335h-56.898v21.334h10.666 0.0038v55.116l16.002 16.002h26.669v39.119h-10.671v21.339h56.9v-21.339h-10.67v-14.225h24.894l14.225 14.225h0.002v21.339h60.449v-21.339h-14.222l-29.339-29.336z"
        />
        <path
          d="m8.1621 8.0723v14.223h10.67v56.894l14.225 14.225h24.89v-14.225h-10.668v-56.894h10.668v-14.223l-49.785 0.0003zm85.344 0v14.223h10.664v56.894h-10.664v14.225h24.894l14.22-14.225v-56.894h10.67v-14.223l-49.784 0.0003zm-42.674 53.338v14.223h10.67v64.007h-10.67v14.23h49.788v-14.23h-10.669v-21.34h30.229l21.33 21.34h-5.33v14.23h53.34v-14.23h-12.45l-32.89-32.89 31.11-31.117h14.23v-14.223h-53.34v14.223h5.33l-21.33 21.336h-30.229v-21.336h10.669v-14.223h-49.788z"
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          transform="translate(-.0010758)"
        />
      </g>
    </SvgIcon>
  );
}

export default KentuckyLogo;
