import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function BaylorLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 215.89 259.72" {...restProps}>
      <g>
        <polygon
          points="96.75,141.88 96.75,134.34 109.05,134.35 109.05,105.19 55.3,105.19 55.3,134.35 67.6,134.35 67.6,141.88"
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
        />
        <path
          d="M215.89,134.34v-29.15h-53.75v29.15h12.3v85.01c0,2.17-0.25,2.99-1.23,3.97l-6.01,6c-1,1-1.75,1.23-3.97,1.23   h-55.27c-2.23,0-2.97-0.23-3.98-1.24l-5.99-5.99c-0.98-0.98-1.24-1.8-1.24-3.97v-43.75h13.18c8.91,0,14.8,0,21.78-6.97l11.59-11.59   c6.99-6.98,6.99-12.75,6.99-21.48v-31.59c0-8.73,0-14.49-6.96-21.44l-4.01-4.01l3.98-3.99c6.99-6.98,6.99-12.74,6.99-21.48V40.04   c0-8.73,0-14.5-6.96-21.45L131.69,6.95C124.73,0,118.84,0,109.93,0H0v29.16h14.05v117.29H0v29.16h67.6v43.75   c0,8.91,0,14.8,6.97,21.78l11.6,11.59c6.97,6.98,12.62,6.98,21.17,6.98h56.54c8.54,0,14.18,0,21.13-6.96l11.65-11.64   c6.95-6.96,6.95-12.85,6.95-21.76v-78.43l-14.58-6.58L215.89,134.34z M109.93,29.16c2.17,0,2.99,0.25,3.97,1.23l6,6.01   c1,1,1.23,1.75,1.23,3.97v12.36c0,2.23-0.23,2.97-1.24,3.98l-5.99,6c-0.98,0.98-1.8,1.24-3.97,1.24H43.21v-28.2l-14.58-6.58H109.93   z M43.21,146.45V93.09h66.72c2.17,0,2.99,0.25,3.97,1.23l6,6.01c1,1,1.23,1.74,1.23,3.97v30.94c0,2.23-0.23,2.97-1.24,3.98l-5.99,6   c-0.93,0.93-1.72,1.2-3.62,1.23L43.21,146.45z"
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default BaylorLogo;
