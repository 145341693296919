import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function VanderbiltLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 117.73047 115.11328" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 0 115.11)">
        <g transform="translate(.83719 .83719)">
          <path
            d="m57.975 0.63672-14.858 41.006-35.568-0.01-7.549-0.002 34.061 25.879-17.524 46.24 41.279-24.838 34.465 20.688 6.912 4.15-17.523-46.24 34.06-25.879l-7.5488 0.002-35.568 0.0098-12.07-33.87-2.568-7.1363z"
            transform="matrix(.8 0 0 -.8 0 91)"
            fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          />
          <path
            d="m57.971 8.5664-12.932 35.686-37.49-0.01 29.615 22.502-15.236 40.206 35.892-21.595 35.981 21.595-15.237-40.206 29.616-22.502-37.491 0.01-12.718-35.686z"
            transform="matrix(.8 0 0 -.8 0 91)"
            fill={colors.secondaryColor}
          />
          <path
            d="m42.688 46.742v5.2969h2.6855l8.4277 31.018h7.3594l9.0293-31.018h2.5918v-5.2969h-11.158v5.2969h2.918l-6.623 24.178-6.1094-24.178h2.9629v-5.2969h-12.084z"
            transform="matrix(.8 0 0 -.8 0 91)"
            fill="#ffffff"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default VanderbiltLogo;
