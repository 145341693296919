import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function TroyLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 216.7719 245.41551" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -274.51 618.54)">
        <g transform="translate(0 .35697)">
          <path
            d="m388.42 493.55c-0.35885-0.004-36.388-0.38962-82.249-0.38962s-81.765 0.38557-82.122 0.38962l-3.7414 0.0407 0.48997-3.7094c3.4863-26.401 18.581-38.741 30.709-48.657 2.2261-1.8198 11.627-10.097 11.627-10.097v25.284c6.7427 2.0462 13.72 3.6109 20.819 4.6564-0.00028-21.027-0.002-124.27-0.002-124.27 0-12.604 10.352-24.399 16.538-31.446 0.72982-0.83173 3.4114-3.7731 5.8752-6.4979 2.5657 2.9494 5.4011 6.1744 6.2304 7.1364 5.9691 6.9226 15.959 18.509 15.959 30.807l-0.002 117.6h9.3246v4.9683c3.8803-0.83267 7.7052-1.8232 11.459-2.9622 0 0 0-24.922 0.0155-24.908l9.68 8.0445c14.413 11.9 29.316 24.204 32.643 50.35l0.47009 3.6938-3.7234-0.0404"
            fill="#ffffff"
          />
          <path
            d="m306.17 489.92c-46.412 0-82.158 0.39023-82.158 0.39023 3.9533-29.94 23.793-41.044 35.877-51.903v20.401c8.774 2.8264 17.943 4.8335 27.307 5.975-0.00056-17.013-0.002-127.98-0.002-127.98 0-14.345 15.848-28.667 19.117-33.363 3.8169 5.5195 18.997 19.191 18.997 33.363l-0.002 127.97c9.3516-1.1424 18.509-3.1482 27.271-5.9712v-20.401c15.745 13.255 32.389 24.493 35.877 51.903 0 0-35.872-0.39023-82.284-0.39023"
            fill={colors.primaryColor}
          />
          <path
            d="m306.17 484.8c-43.959 0-75.974-0.30262-75.974-0.30262 3.0212-14.983 11.892-25.072 24.777-35.68 0.00085-0.00056 0.003 13.527 0.003 13.527 12.144 4.2428 24.643 6.7961 37.047 7.8829-0.0137-0.009-0.0426-133.42-0.0426-133.42 0-9.7608 9.9127-20.497 14.232-25.886 6.7151 8.2825 14.308 16.618 14.308 26.078l-0.002 133.19c13.049-1.1614 25.569-3.8626 36.971-7.8466 0 0 0.002-13.528 0.003-13.527 12.885 10.608 21.756 20.697 24.777 35.68 0 0-32.141 0.30262-76.1 0.30262"
            fill="#ffffff"
          />
          <path
            d="m13.23 12.926c3.7765 18.729 14.867 31.34 30.973 44.6-12.696-15.474-10.433-26.512-10.433-26.512-12.029-6.363-20.54-18.088-20.54-18.088zm20.539 18.088s1.3862 6.4282 10.438 9.6035c15.18-5.3035 30.804-8.497 46.309-9.8555 7.8621-5.3537 17.731-9.4782 17.736-9.4805-37.288 0.005-74.482 9.7324-74.482 9.7324zm74.482-9.7324v208.62c8.3938-10.353 17.885-20.773 17.885-32.598l-0.002-166.49c16.311 1.4517 31.96 4.8286 46.213 9.8086 9.0517-3.1753 10.438-9.6035 10.438-9.6035s-37.211-9.7324-74.508-9.7324h-0.0254zm74.533 9.7324s2.263 11.039-10.434 26.512c16.106-13.26 27.197-25.871 30.973-44.6 0 0-8.5109 11.725-20.539 18.088z"
            transform="matrix(.8 0 0 -.8 219.61 494.84)"
            fill={colors.secondaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default TroyLogo;
