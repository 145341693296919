import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function EasternMichiganLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 220 249.52781" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -159.18 621.3)">
        <path
          d="m128.07 331.67h23.626v130.95h-23.626v33.532h174.4v-62.696h-37.62v29.163h-61.532v-45.497h45.454v-33.541h-45.454v-51.916h61.532v29.458h37.62v-62.994h-174.4v33.536"
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default EasternMichiganLogo;
