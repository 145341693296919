import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function BowlingGreenLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 202 188.82967" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -9.2892 858)">
        <g transform="matrix(2.4032 0 0 2.4032 -122.13 -855.21)">
          <path
            d="m96.174 639.62c7.606 2.972 14.57 0.769 14.669 0.736l0.422-0.141 2.983-5.619c4.596-1.452 5.993-4.404 6.394-7.469 0.226-1.728-0.071-3.191-0.423-4.22-0.179-0.522-0.372-0.934-0.521-1.216-0.156-0.297-1.101-1.67-1.101-1.67l-0.472 0.54c-0.023 0.027-0.509 0.582-0.562 0.629-0.082 0.073-0.226 0.188-0.44 0.315-0.424 0.253-1.141 0.565-2.228 0.682-1.067 0.114-1.991 0.125-2.771 0.029l-5.391-19.313c0.267-0.093 1.71-0.692 1.948-0.765 0.785-0.242 2.645-0.627 2.645-0.627s-4.877-4.613-13.357-2.048l-0.016-20.485h-20.979c-5.527 0-9.006 4.039-9.006 9.271v8.447h-4.23v2.761h-9.434s0.727 2.573 1.15 3.722c0.413 1.121 1.026 2.588 1.766 3.663 1.001 1.452 2.27 2.778 3.36 3.584-0.001 3.187 0.05 15.94 0.058 19.342-0.322 0.219-0.66 0.473-0.975 0.757-1.008 0.91-2.294 2.871-2.685 3.542-0.248 0.426-0.791 1.67-1.226 2.685-0.457 1.065-1.61 3.843-1.61 3.843h26.998c3.898 0 7.51-2.068 8.7-5.786 1.989 2.816 3.545 3.721 6.334 4.811"
            fill="#fe5000"
            stroke={variant === 'main' ? colors.primaryColor : '#ffffff'}
            strokeWidth="1"
          />
          <path
            d="m66.107 4.1348c-2.7652 0.050977-6.0891 0.49969-9.6445 1.8047-5.1088 1.8738-7.2912 4.9189-9.6074 9.0664v-0.89844c0-5.6825-4.479-9.0352-9.7715-9.0352h-31.316s2.0251 4.8752 2.5176 5.7227c0.4775 0.82125 1.981 3.0816 3.041 4.0391 0.55125 0.49625 1.1984 0.92797 1.7559 1.2617 0.0025 2.1338-0.09531 25.139-0.07031 25.932-1.2475 0.7175-3.0777 2.4845-4.4902 4.5332-1.608 2.332-2.8384 6.688-2.8384 6.688h16.559v15.631c0 5.7425 3.7167 9.9688 9.6367 9.9688h24.604l0.01953-26.094c0.42875 0.21375 0.82469 0.38461 1.1797 0.50586 8.6987 2.9575 14.004-0.43555 14.004-0.43555s-1.4477-0.31438-2.3477-0.60938c-0.79625-0.26125-2-0.7832-2-0.7832l7.373-26.232c1.325-0.29875 2.9143-0.31875 4.7168-0.125 3.1288 0.33625 4.3516 1.8262 4.3516 1.8262s1.8015-2.6375 1.3027-6.4375c-0.46125-3.5212-1.9989-6.7846-7.5664-8.3496l-3.743-7.0476s-3.0593-1.0186-7.668-0.93359zm-47.502 50.725-0.31445 1.8535h0.47852l0.1543-1.0664h0.0059l0.42383 1.0664h0.19141l0.44531-1.0664h0.0059l0.13476 1.0664h0.48047l-0.2793-1.8535h-0.47852l-0.39844 0.99023-0.37695-0.99023h-0.47266zm-1.6562 0.002v0.40625h0.39844v1.4453h0.48047v-1.4453h0.39648v-0.40625h-1.2754z"
            transform="matrix(.8 0 0 -.8 51.471 643.35)"
            fill={colors.secondaryColor}
          />
          <path
            d="m111.85 631.92s0.095-0.971-1.175-2.198c-1.47-1.421-4.358-3.022-4.358-3.022s4.245 0.766 7.725 0.14c2.571-0.463 3.41-1.955 3.41-1.955s0.713 2.165-0.905 4.112c-1.516 1.826-4.697 2.923-4.697 2.923"
            fillRule="evenodd"
            fill="#cda077"
          />
          <path
            d="m11.262 8.582s1.1517 2.502 2.418 3.6445c0.98375 0.8875 2.9121 1.748 2.9121 1.748v30.371s-2.0181 0.92812-2.7344 1.6406c-1.7275 1.7175-2.3691 3.752-2.3691 3.752h14.268v19.143c0 3.9938 2.328 6.457 6.123 6.457h21.092v-22.055h-19.018s3.5834 3.1414 6.3496 4.2539c2.4638 0.99125 6.6777 1.3379 6.6777 1.3379v11.072h-13.438c-1.3975 0-1.7988-0.6-1.7988-2.5312v-17.678h5.0078c2.4625 0 3.5956-0.39875 4.8594-1.5312 1.265-1.2638 1.7305-2.5948 1.7305-5.0586v-3.5742h3.3066c-0.000001 0 0.59914 2.7812 1.4004 4.3887 1.25 2.5 4.6406 5.4629 4.6406 5.4629l0.04102-15.242h-9.3887v-0.5293c0-2.6625-0.66469-4.0623-2.4609-5.2598 1.73-1.1312 2.4609-2.4607 2.4609-4.6582v-9.6289c0-3.3275-2.4609-5.5254-6.2559-5.5254h-25.824zm11.32 5.3926h12.174c1.9975 0 2.5957 0.53367 2.5957 2.2637v7.0977c0 1.93-0.46438 2.3965-2.3281 2.3965h-12.441v-11.758zm47.018 1.1523c-0.69781-0.0034-1.5638 0.0084-2.2363 0.06836-1.2075 0.10875-3.0703 0.48633-3.0703 0.48633l4.1758 1.7383 2.3574-2.2715s-0.52875-0.01805-1.2266-0.02149zm-47.018 15.998h11.975c2.33 0 2.7949 0.3993 2.7949 2.5293v0.5293h-5.6074c-3.5275 0-5.9883 2.5291-5.9883 6.0566v3.3047 0.80078h-3.1738v-13.221zm11.625 8.4492h3.1445v2.5078c0 1.7312-0.59852 2.2637-2.7285 2.2637h-2.8789v-0.80078-1.707c0-1.7975 0.53289-2.2637 2.4629-2.2637z"
            transform="matrix(.8 0 0 -.8 51.471 643.35)"
            fill="#ffffff"
          />
          <path
            d="m66.447 7.1191c-3.1862-0.013867-6.6605 0.62812-10.42 2.3516-2.3988 1.0987-2.8926 2.0098-2.8926 2.0098 0-0.0012 3.7607-0.7093 9.6895-0.31055 7.8288 0.52625 11.384 2.5104 12.516 3.1191l-3.471-6.5273c-1.702-0.389-3.51-0.6342-5.422-0.6426zm-2.3906 8.6348c-4.2025 0.4825-12.529 3.027-17.201 7.6445v0.33789c0 1.72-0.34008 3.296-1.1738 4.6973 0.40125 0.70625 0.68023 1.4526 0.86523 2.2363h5.3242c0.47-1.2538 1.0145-2.5509 1.582-3.5859 2.9088-5.3062 7.301-9.1751 10.604-11.33zm7.6309 9.9316s-6.0306 0.61953-13.078 3.9258c-0.74875 0.35125-1.4461 0.70859-2.0898 1.0586v0.0059l-0.01172 15.879c4.475 3.1038 8.0469 4.0977 8.0469 4.0977l7.134-24.967z"
            fillRule="evenodd"
            transform="matrix(.8 0 0 -.8 51.471 643.35)"
            fill={colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default BowlingGreenLogo;
