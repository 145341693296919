import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function OklahomaStateLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 130.40704" {...restProps}>
      <g transform="matrix(1.8834 0 0 1.8834 .88028 1.1197)">
        <path
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          d="m51.46 11.12h-23.9c-6.45 0-12.21 4.02-14.42 10.08l-13.14 35.86h34.59l-0.94 2.55 11.83 8.57h17.76c7.87 0 14.9-4.91 17.62-12.3l0.24-0.67 2.7 1.84h20.33c6.55 0 12.39-4.1 14.62-10.26l12.93-35.68h-34.38l0.91-2.48-11.92-8.63h-17.93c-7.12 0-14.45 4.44-16.9 11.12z"
        />
        <path
          d="m68.6 3.3691c-6.49 0-12.28 4.0509-14.52 10.131l-0.2793 0.76953h-25.852c-5.24-0.01-9.9188 3.2599-11.719 8.1699l-5.98 16.29 3.6309 2.5586h-4.5801l-4.5508 12.41h34.57l-1.7109 4.6504 8.9297 6.4707h16.82c6.53 0 12.36-4.0902 14.57-10.24l1.6094-4.4707 5.2617 3.5898h19.27c5.14 0 9.7305-3.2188 11.48-8.0488l11.369-31.381h-34.449l1.5996-4.4395-8.9102-6.4609h-16.561zm-0.07031 3.4609h15.51l5.9004 4.2793-2.5098 6.8398h34.48l-2.0098 5.5898h-44.939l2.9688-8.3281h-10.49l-3.3887 9.3496 16.199 12.888-5.949 16.211c-1.7 4.63-6.1012 7.6992-11.031 7.6992h-15.609l-5.9004-4.2988 2.5098-6.8203h-34.51l2-5.4199h44.951l-2.9512 8.1289h10.74l3.3105-9.2988-16.34-13 5.8789-16.02c1.73-4.69 6.1897-7.8008 11.18-7.8008zm-40.33 10.859h17.82l5.1406 3.7715-5.4004 14.699c-1.14 3.09-4.0791 5.1406-7.3691 5.1406h-18.811l-5.1992-3.8105 5.0195-13.66c1.36-3.69 4.8688-6.1406 8.7988-6.1406zm1.7812 3.5098-6.0195 16.67h11.828l5.9512-16.67h-11.76zm57.92 5.75h9.4688l-7.2793 19.9h8.6895c1.92 0 3.641-1.2095 4.291-3.0195l6.0488-16.881h9.5703l-6.3086 17.34c-1.32 3.64-4.7899 6.0703-8.6699 6.0703h-17.641l-5.309-3.609 7.14-19.801z"
          fill={variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default OklahomaStateLogo;
