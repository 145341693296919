import React, { ReactNode } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFlashMessageContext } from '../../../../../contexts/flashMessage';
import { formatDateTime } from '../../../../../dates';
import { TeamObjectType } from '../../../../../generated/schemas';
import { TeamLogoComponent } from '../../../../logos';
import { MarketCloseReason, MarketInfo } from './utils';

export default function ActionModal({
  action,
  buttonText,
  children,
  marketInfo,
  team,
  onSubmit,
  onClose,
  refetch,
}: {
  action: 'Buy' | 'Sell';
  buttonText: string;
  children: ReactNode;
  marketInfo: MarketInfo;
  team: Pick<TeamObjectType, 'name' | 'styles'>;
  onSubmit(): Promise<any>;
  onClose(): void;
  refetch(): void;
}) {
  const { addFlashMessage } = useFlashMessageContext();
  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <TeamLogoComponent styles={team.styles} />
          <div>
            {action} Shares of {team.name}
          </div>
        </Stack>
      </DialogTitle>
      <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ flexGrow: 1, pl: 2 }}>
          <Stack sx={{ flexGrow: 1, justifyContent: 'center' }}>
            {marketInfo.open ? (
              <>
                <Typography variant="body2">
                  {marketInfo.closeReason === MarketCloseReason.GameStart
                    ? 'Trading for this team will close at kickoff:'
                    : 'Market will close at:'}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {formatDateTime(marketInfo.closeDateTime)}
                </Typography>
              </>
            ) : marketInfo.openDateTime ? (
              <>
                <Typography variant="body2">
                  {marketInfo.closeReason === MarketCloseReason.GameStart
                    ? 'Trading for this team closed at kickoff. Market will open at:'
                    : 'Market is currently closed. Market will open at:'}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {formatDateTime(marketInfo.openDateTime)}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">Market is currently closed.</Typography>
            )}
          </Stack>
          <Button
            variant="contained"
            color={action === 'Buy' ? 'success' : 'error'}
            disabled={!marketInfo.open}
            onClick={() =>
              onSubmit()
                .then(response => {
                  const errorMessage = response?.error?.graphQLErrors?.[0]?.message;
                  if (errorMessage) {
                    addFlashMessage('error', errorMessage);
                    throw new Error(errorMessage);
                  }
                })
                .then(() => {
                  addFlashMessage('success', 'Transaction completed successfully');
                  onClose();
                  refetch();
                })
            }
          >
            {buttonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
