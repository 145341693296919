import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function TexasStateLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 149.99951 175.07439" {...restProps}>
      <g transform="matrix(2.9051 0 0 2.9051 -66.65 -1.605)">
        <path
          fill={colors.secondaryColor}
          d="m33.629 0.91222c-0.569 6.6787 1.286 10.026 1.351 10.141 0 0-1.528-0.90203-2.7617-2.6758 0.705 4.4863 4.0723 6.3516 4.0723 6.3516 1.5975 4.975 3.8613 7.3008 3.8613 7.3008-14.481 4.9425-16.064 22.494-16.064 22.494s4.6139-6.2644 12.441-7.5156c-12.114 4.0912-13.258 17.078-13.258 17.078s5.721-7.203 18.197-5.9668c-0.08125-0.07375 2.8275-4.3463 6.2812-5.6113 0 0-3.2406 3.0178-4.8906 5.9453 0 0 2.9009 0.51312 4.0684 0.85938 1.17 0.3475 4.2049 1.2921 4.0449 3.0371-0.25375 2.7525-5.7598 3.7067-5.7773 3.6367 3.735 2.5625 7.643 1.4208 7.6855 1.3633-0.57875 1.3038-4.4648 2.1953-4.4648 2.1953s11.42 4.349 12.275-5.6348c3.0412-2.2088 2.6836-5.5195 2.6836-5.5195s-1.4388 2.0121-4.2363 1.6309c-0.9-0.12375-4.9932-2.9501-3.7207-5.5176 0.42125-0.85375 3.5597-2.8384 7.8672-2.2422 1.9462 0.34625 2.8085 3.3003 2.791 3.4141-0.28625 1.7838-2.2207 4.7988-2.2207 4.7988s2.2634 0.26812 5.0059-1.9531c1.0475 0.41875 1.2488 0.71664 1.9551 0.24414 1.3338-0.89125 1.6714-2.991 1.7852-3.2285 0.6525-0.3525 1.8159-1.4573 1.5859-4.4648-0.185-2.4262-1.4954-5.2019-3.4004-7.2656-1.37-6.4475-3.5099-9.4504-14.42-14.307-13.31-5.76-17.341-7.168-22.737-18.585z"
          stroke="#ffffff"
          strokeWidth={variant === 'main' ? '0' : '1'}
        />
        <path
          fill={colors.primaryColor}
          d="m36.146 7.7383s0.1525 8.5188 5.625 15.111c-0.12625 0.03-0.24773 0.0593-0.36523 0.0918-0.02875-0.02125-8.7908 1.2961-13.551 16.59 0 0 4.1677-5.3809 19.348-3.9297-5.4525-0.52-16.863 3.117-20.381 14.189 0 0 3.7711-3.9516 13.559-3.1641 1.215-1.8075 4.964-5.4048 11.459-6.5723 0 0-3.6852 2.1446-6.2402 7.3809-0.105-0.01 1.1641 0.22508 2.8379 0.83008 0 0 8.0819 2.7941 1.1406 8.0879 0 0 4.2538 0.52024 5.2676-1.7598 1.2875 1.9362-1.2609 3.842-1.8809 4.2383 8.2175 0.565 6.7598-5.4688 6.7598-5.4688 1.6588-0.7475 2.5801-3.2637 2.5801-3.2637-1.9338 1.4662-3.5634 0.91844-4.2422 0.67969-0.59625-0.35625-5.9191-4.8054-4.4141-7.4941 1.3587-1.9425 5.937-3.3007 8.8633-2.8145 2.8825 0.44875 4.3845 4.6012 4.4082 4.6699 0.39 1.11-1.4273 4.2338-1.6211 4.4551 0 0 2.0534-0.14227 3.3809-2.166 1.5488 1.305 2.5245 0.24672 3.0957-2.3633 0.7925-3.9112-1-4.1504-1-4.1504s-0.10008 0.98445-0.51758 1.252c-0.41625 0.26875 0.34414-1.7472-0.41211-2.9922 0 0 2.8241 0.25484 2.8828 3.6523 0.0062 0.3025-0.11266 0.85875-0.25391 1.375 0.87875-0.54625 0.81641-2.541 0.81641-2.541-0.405-4.915-3.4785-7.293-3.4785-7.293-1.1575-5.5988-2.4307-8.6385-13.783-13.791-8.4475-3.8325-17.082-6.7136-19.883-12.84z"
        />
        <path
          d="m40.941 16.502c0 0.0275-0.29611 2.0941 2.4863 5.9902 3.3063-0.5375 7.5586-0.3751 12.041 1.5898-4.8599-4.1875-9.3352-4.1776-14.527-7.5801zm21.311 13.678c0 1.9162 0.45937 3.5803 1.6719 4.4453 0.79499 0.56624 2.0545 0.42474 2.5195-0.58398 0.26625-0.57624-0.15625-1.3558-0.15625-1.3945-0.465 1.3175-1.8359 1.8008-1.8359 1.8008s0.80969-1.4745 0.86719-2.7695c-1.3638-1.1512-3.0664-1.498-3.0664-1.498z"
          fill={colors.secondaryColor}
          transform="translate(-.00068665)"
        />
      </g>
    </SvgIcon>
  );
}

export default TexasStateLogo;
