import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function TennesseeLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 202 202" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 6.8852 789.46)">
        <path
          d="m155.37 630.55v-54h-20.001c0 9.9425-9.8453 17.998-21.996 17.998-8.8786 0-16.525-4.304-20.001-10.502v-67.493c0-14.339 11.159-25.965 24.936-25.998h0.0624v-20.001h-85.999v20.001h0.06013c13.777 0.0324 24.941 11.659 24.941 25.998v67.493c-3.4784 6.1981-11.122 10.502-20.001 10.502-12.149 0-22.001-8.0553-22.001-17.998h-20.001v54h160z"
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default TennesseeLogo;
