import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function WesternMichiganLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 200 200" {...restProps}>
      <path
        fill={variant === 'third' ? colors.secondaryColor : colors.primaryColor}
        d="M99.9 197.8C45.75 197.8 1.99 154.04 1.99 99.9C1.99 45.75 45.75 1.99 99.9 1.99C154.04 1.99 197.8 45.75 197.8 99.9C197.8 154.04 154.04 197.8 99.9 197.8Z"
      />
      <path
        fillRule="evenodd"
        fill={variant === 'third' ? colors.primaryColor : colors.secondaryColor}
        d="M99.9 0C113.02 0 126.01 2.58 138.13 7.61C150.25 12.63 161.27 19.99 170.54 29.26C179.82 38.54 187.18 49.56 192.2 61.68C197.22 73.8 199.8 86.79 199.8 99.91C199.79 140.32 175.44 176.74 138.11 192.19C100.78 207.65 57.81 199.1 29.24 170.52C0.68 141.95 -7.87 98.98 7.6 61.65C23.06 24.32 59.49 -0.02 99.9 -0.02L99.9 0ZM99.9 5.54C96.74 5.54 93.59 5.69 90.45 6C87.31 6.31 84.19 6.78 81.1 7.41C78.01 8.03 74.95 8.81 71.94 9.75C68.92 10.68 65.96 11.76 63.06 13C18.46 31.87 -4.6 81.45 9.71 127.71C24.02 173.97 71.04 201.88 118.51 192.27C165.97 182.67 198.44 138.68 193.64 90.49C188.84 42.31 148.32 5.59 99.9 5.54Z"
      />
      <path
        fill={variant === 'third' ? colors.primaryColor : colors.secondaryColor}
        d="M161.49 52.42L161.49 72.82L156.29 72.82L134.18 158.98L113.93 158.98L99.89 104.29L85.86 158.98L65.6 158.98L43.49 72.82L38.29 72.82L38.29 52.42L74.45 52.42L74.45 72.81L68.03 72.81L76.81 108.3L90.1 52.42L109.71 52.42L123 108.31L131.78 72.81L125.37 72.81L125.37 52.42L161.49 52.42Z"
      />
    </SvgIcon>
  );
}

export default WesternMichiganLogo;
