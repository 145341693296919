import React, { useCallback, useContext } from 'react';
import { gql, useQuery } from 'urql';
import { FetchTogglesQuery } from '../generated/hooks';

const fetchToggles = gql`
  query fetchToggles {
    toggles {
      id
      name
      on
      value
    }
  }
`;

type ToggleContextValue = {
  getToggle: (name: string) => FetchTogglesQuery['toggles'][0];
};

const ToggleContext = React.createContext<ToggleContextValue>({
  getToggle: () => null,
});

type ToggleProviderProps = {
  children: React.ReactNode;
};

function ToggleProvider({ children }: ToggleProviderProps) {
  const [{ data }] = useQuery<FetchTogglesQuery>({ query: fetchToggles });
  const getToggle = useCallback((name: string) => data?.toggles?.find(toggle => toggle.name === name), [data?.toggles]);
  return <ToggleContext.Provider value={{ getToggle }}>{children}</ToggleContext.Provider>;
}

export function useToggleContext() {
  return useContext(ToggleContext);
}

export default ToggleProvider;
