import React, { useMemo } from 'react';
import { Box, Chip, ChipProps, Typography, useTheme } from '@mui/material';
import { TeamObjectType } from '../../generated/schemas';
import { useHover } from '../../utils';
import { TeamLogoComponent } from '../logos';

type TeamChipProps = {
  team: Pick<TeamObjectType, 'name' | 'styles'>;
} & ChipProps;

function TeamChip({ team, sx, ...restProps }: TeamChipProps) {
  const [hoverProps, hover] = useHover();
  const {
    palette: { grey },
  } = useTheme();
  const chipSx = useMemo(() => {
    const primaryColor = team.styles?.primaryColor || grey[800];
    const secondaryColor = team.styles?.secondaryColor || grey[300];
    const colorProps = {};
    if (restProps.variant === 'outlined') {
      colorProps['borderColor'] = primaryColor;
      colorProps['color'] = primaryColor;
      if (restProps.onClick) {
        colorProps['&:hover'] = {
          backgroundColor: `${primaryColor} !important`,
          color: '#ffffff',
        };
      }
    } else {
      colorProps['backgroundColor'] = primaryColor;
      colorProps['color'] = '#ffffff';
      if (restProps.onClick) {
        colorProps['&:hover'] = {
          backgroundColor: secondaryColor,
          color: primaryColor,
        };
      }
    }
    return {
      borderRadius: { xs: 18, md: 20 },
      height: { xs: 36, md: 40 },
      ...colorProps,
      ...sx,
    };
  }, [restProps.onClick, restProps.variant, team.styles, sx, grey]);
  return (
    <Chip
      sx={chipSx}
      icon={
        <Box sx={{ px: 1 }}>
          <TeamLogoComponent
            styles={team.styles}
            variant={
              restProps.variant === 'outlined'
                ? restProps.onClick && hover
                  ? 'inverted'
                  : 'main'
                : restProps.onClick && hover
                ? 'third'
                : 'inverted'
            }
            sx={{ display: 'block' }}
          />
        </Box>
      }
      label={
        <Box
          sx={{
            width: {
              xs: 120 + (team.styles?.logoComponent ? 0 : 28),
              md: 150 + (team.styles?.logoComponent ? 0 : 32),
            },
            fontSize: 16,
            textAlign: team.styles?.logoComponent ? 'left' : 'center',
          }}
        >
          <Typography variant="body1">{team.name}</Typography>
        </Box>
      }
      {...hoverProps}
      {...restProps}
    />
  );
}

export default TeamChip;
