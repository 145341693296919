import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MiddleTennesseeLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 159.5 104.275" {...restProps}>
      <g>
        <path
          d="m87.479 75.288 3.2788-13.895h5.355l-3.7962 13.895h-4.8375zm12.925-48.91 7.115-25.188h-34.659l-12.849 15.339-3.2075-15.339h-35.066l-7.61 29.945h4.4625l-5.4688 20.835h-5.7138l-6.5975 27.921h40.212l4.9662-21.018h5.1488l-4.9675 21.018h39.741l-6.24 23.574h42.336l7.46-28.178h-5.425l3.9338-14.4h21.39l8.9438-34.51h-57.906z"
          fill={colors.secondaryColor}
        />
        <path
          fill="#ffffff"
          d="m91.343 29.948 0.67125-2.3762h4.3438l6.4462-22.814h-28.286l-16.496 19.701-4.1188-19.701h-29.396l-5.7962 22.814h4.4962l-7.3375 27.969h-5.6462l-4.9112 20.788h32.886l4.9112-20.788h-4.4475l2.3175-8.8288 3.3175 8.6162h11.348l7.6788-7.8525-2.2938 8.065h-5.4438l-4.9138 20.788h32.891l4.365-18.499h12.849l-5.7438 21.028h-5.1638l-5.5688 21.046h34.959l5.5738-21.046h-5.4625l5.8812-21.531h21.352l7.0938-27.378h-62.355z"
        />
        <path
          d="m27.184 8.1811-4.0566 15.963h4.5332l-9.1367 34.818h-5.5762l-3.2949 13.939h25.848l3.293-13.939h-4.5586l4.4551-16.975h4.1562l3.8164 9.9082h7.5605l10.928-11.176 3.668 0.002-5.1875 18.24h-5.3223l-3.2949 13.939h25.852l3.2891-13.939h-4.5586l9.8398-34.818h4.3438l4.5098-15.963h-22.158l-20.007 23.89-4.992-23.889h-23.947zm63.559 25.189-5.3184 21.031h13.178l1.5332-6.8965h7.0273l-9.5 34.771h-5.1445l-3.7559 14.197h27.873l3.7578-14.197h-5.498l9.5371-34.908h7.6191l-1.5156 6.5273h13.43l5.3184-20.525h-58.541z"
          fill={colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default MiddleTennesseeLogo;
