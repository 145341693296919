import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Info as InfoIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { gql, useMutation, useQuery } from 'urql';
import {
  FetchTeamSalePriceQuery,
  FetchTeamSalePriceQueryVariables,
  SellTeamMutation,
  SellTeamMutationVariables,
} from '../../../../../generated/hooks';
import { SellTeamInputType, TeamObjectType } from '../../../../../generated/schemas';
import ActionModal from './ActionModal';
import { getMarketInfo } from './utils';

const fetchTeamSalePrice = gql`
  query fetchTeamSalePrice($leagueId: UUID!, $teamId: UUID!) {
    league(id: $leagueId) {
      marketOpenWindows {
        open
        close
      }
      team(id: $teamId) {
        stockSalePrice
        currentGame {
          kickoffDateTime
        }
      }
    }
  }
`;

const sellTeam = gql`
  mutation sellTeam($sellTeamInput: SellTeamInputType!) {
    sellTeam(input: $sellTeamInput) {
      ok
    }
  }
`;

export default function SellModal({
  leagueId,
  cashBalance,
  costBasis,
  shareQuantity,
  team,
  onClose,
  refetch,
}: {
  leagueId: string;
  cashBalance: currency;
  costBasis: currency;
  shareQuantity: number;
  team: Pick<TeamObjectType, 'id' | 'name' | 'styles'>;
  onClose(): void;
  refetch(): void;
}) {
  const [shares, setShares] = useState(1);
  const [{ data, fetching }] = useQuery<FetchTeamSalePriceQuery, FetchTeamSalePriceQueryVariables>({
    query: fetchTeamSalePrice,
    variables: { leagueId, teamId: team.id },
    context: useMemo(() => ({ requestPolicy: 'network-only' }), []),
  });

  const [, sellTeamMutation] = useMutation<SellTeamMutation, SellTeamMutationVariables>(sellTeam);
  const handleSellTeam = useCallback(
    (sellTeamInput: SellTeamInputType) => sellTeamMutation({ sellTeamInput: sellTeamInput }),
    [sellTeamMutation],
  );

  const averageCost = costBasis.divide(shareQuantity);
  const sharePrice = currency(data?.league.team.stockSalePrice);
  const totalPayment = sharePrice.multiply(shares);

  const shareChange = sharePrice.subtract(averageCost);
  const totalChange = totalPayment.subtract(averageCost.multiply(shares));

  const marketInfo = getMarketInfo(data?.league.marketOpenWindows, data?.league.team.currentGame?.kickoffDateTime);

  const [_, setRenderKey] = useState(0);
  useEffect(() => {
    if (marketInfo.open && marketInfo.closeDateTime) {
      const timeout = setTimeout(() => setRenderKey(key => key + 1), marketInfo.closeDateTime.getTime() - Date.now());
      return () => clearTimeout(timeout);
    }
    if (!marketInfo.open && marketInfo.openDateTime) {
      const timeout = setTimeout(() => setRenderKey(key => key + 1), marketInfo.openDateTime.getTime() - Date.now());
      return () => clearTimeout(timeout);
    }
  }, [marketInfo]);

  return (
    <ActionModal
      action="Sell"
      buttonText={`Sell ${shares} share${shares > 1 ? 's' : ''}`}
      marketInfo={marketInfo}
      team={team}
      onSubmit={() =>
        handleSellTeam({
          leagueId: leagueId,
          sharePrice: sharePrice.format({ symbol: '', separator: '' }),
          shares,
          teamId: team.id,
        })
      }
      onClose={onClose}
      refetch={refetch}
    >
      {fetching ? (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Shares Owned</TableCell>
                <TableCell align="right">{shareQuantity}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Cost</TableCell>
                <TableCell align="right">{costBasis.format()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Average Cost</TableCell>
                <TableCell align="right">{averageCost.format()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shares to Sell</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={2} sx={{ justifyContent: 'right', alignItems: 'center' }}>
                    <IconButton color="primary" disabled={shares === 1} onClick={() => setShares(s => s - 1)}>
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="h5">{shares}</Typography>
                    <IconButton
                      color="primary"
                      disabled={shares === shareQuantity}
                      onClick={() => setShares(s => s + 1)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Current Balance</TableCell>
                <TableCell align="right">{cashBalance.format()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Tooltip title="The Share Price for selling is lower than the Share Price for buying. For more information see the league rules.">
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Box>Share Price</Box>
                      <InfoIcon fontSize="small" sx={{ color: 'info.main' }} />
                    </Stack>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={2} sx={{ justifyContent: 'right', alignItems: 'center' }}>
                    {shareChange.intValue > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                          color: 'success.main',
                        }}
                      >
                        <ArrowDropUpIcon />
                        <span>{shareChange.format()}</span>
                      </Box>
                    ) : shareChange.intValue < 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                          color: 'error.main',
                        }}
                      >
                        <ArrowDropDownIcon />
                        <span>{shareChange.format({ negativePattern: '!#' })}</span>
                      </Box>
                    ) : null}
                    <Box>{sharePrice.format()}</Box>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Payment</TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={2} sx={{ justifyContent: 'right', alignItems: 'center' }}>
                    {totalChange.intValue > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                          color: 'success.main',
                        }}
                      >
                        <ArrowDropUpIcon />
                        <span>{totalChange.format()}</span>
                      </Box>
                    ) : totalChange.intValue < 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                          color: 'error.main',
                        }}
                      >
                        <ArrowDropDownIcon />
                        <span>{totalChange.format({ negativePattern: '!#' })}</span>
                      </Box>
                    ) : null}
                    <Box>{totalPayment.format()}</Box>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Balance After Sale</TableCell>
                <TableCell align="right">{cashBalance.add(totalPayment).format()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </ActionModal>
  );
}
