import React from 'react';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import currency from 'currency.js';
import {
  Bar,
  BarChart,
  Cell,
  DefaultTooltipContent,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useAccountContext } from '../../../../../contexts/account';
import { PlayerStandingsData } from './getPlayerStandingsData';

export default function PlayerStandingsChart({ data }: { data: PlayerStandingsData }) {
  const { userId } = useAccountContext();
  const { palette } = useTheme();

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: '100%' }}>
      <ResponsiveContainer height={500} width="100%">
        <BarChart
          data={data.players}
          margin={{
            top: 20,
            right: isMdDown ? 10 : 30,
            left: isMdDown ? 10 : 20,
            bottom: isMdDown ? 0 : 40,
          }}
        >
          <XAxis dataKey="fullName" interval={0} tick={{ angle: -10 } as any} tickMargin={20} hide={isMdDown} />
          <YAxis tickFormatter={(x: number) => currency(x).format()} />
          <Tooltip
            formatter={(x: number) => currency(x).format()}
            content={({ payload, ...props }) => {
              if (payload.length) {
                payload = [
                  ...payload,
                  { name: 'Total Value', value: payload.reduce((total, p) => total + p.value, 0) },
                ];
              }
              return <DefaultTooltipContent {...props} payload={payload} />;
            }}
          />
          <Bar dataKey="holdings.value" name="Stock Value" stackId="a" fill={palette.success.main}>
            {data.players.map((player, idx) => (
              <Cell key={`cell-${idx}`} fill={player.id === userId ? palette.success.main : palette.grey[700]} />
            ))}
          </Bar>
          <Bar dataKey="cashBalance.value" name="Cash Balance" stackId="a" fill={palette.success.light}>
            {data.players.map((player, idx) => (
              <Cell key={`cell-${idx}`} fill={player.id === userId ? palette.success.light : palette.grey[500]} />
            ))}
          </Bar>
          <ReferenceLine
            y={data.averages.totalWorth.value}
            label={{
              value: `Average (${data.averages.totalWorth.format()})`,
              fill: palette.secondary.main,
              position: 'insideBottomRight',
            }}
            stroke={palette.secondary.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
