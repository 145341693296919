import React from 'react';
import { Grid, Paper } from '@mui/material';
import { LogoProps } from '../../types';
import AirForceLogo from '../logos/AirForceLogo';
import AkronLogo from '../logos/AkronLogo';
import AlabamaLogo from '../logos/AlabamaLogo';
import AppalachianStateLogo from '../logos/AppalachianStateLogo';
import ArizonaLogo from '../logos/ArizonaLogo';
import ArizonaStateLogo from '../logos/ArizonaStateLogo';
import ArkansasLogo from '../logos/ArkansasLogo';
import ArkansasStateLogo from '../logos/ArkansasStateLogo';
import ArmyLogo from '../logos/ArmyLogo';
import AuburnLogo from '../logos/AuburnLogo';
import BallStateLogo from '../logos/BallStateLogo';
import BaylorLogo from '../logos/BaylorLogo';
import BoiseStateLogo from '../logos/BoiseStateLogo';
import BostonCollegeLogo from '../logos/BostonCollegeLogo';
import BowlingGreenLogo from '../logos/BowlingGreenLogo';
import BuffaloLogo from '../logos/BuffaloLogo';
import ByuLogo from '../logos/ByuLogo';
import CaliforniaLogo from '../logos/CaliforniaLogo';
import CentralFloridaLogo from '../logos/CentralFloridaLogo';
import CentralMichiganLogo from '../logos/CentralMichiganLogo';
import CharlotteLogo from '../logos/CharlotteLogo';
import CincinnatiLogo from '../logos/CincinnatiLogo';
import ClemsonLogo from '../logos/ClemsonLogo';
import CoastalCarolinaLogo from '../logos/CoastalCarolinaLogo';
import ColoradoLogo from '../logos/ColoradoLogo';
import ColoradoStateLogo from '../logos/ColoradoStateLogo';
import ConnecticutLogo from '../logos/ConnecticutLogo';
import DukeLogo from '../logos/DukeLogo';
import EastCarolinaLogo from '../logos/EastCarolinaLogo';
import EasternMichiganLogo from '../logos/EasternMichiganLogo';
import FloridaAtlanticLogo from '../logos/FloridaAtlanticLogo';
import FloridaInternationalLogo from '../logos/FloridaInternationalLogo';
import FloridaLogo from '../logos/FloridaLogo';
import FloridaStateLogo from '../logos/FloridaStateLogo';
import FresnoStateLogo from '../logos/FresnoStateLogo';
import GeorgiaLogo from '../logos/GeorgiaLogo';
import GeorgiaSouthernLogo from '../logos/GeorgiaSouthernLogo';
import GeorgiaStateLogo from '../logos/GeorgiaStateLogo';
import GeorgiaTechLogo from '../logos/GeorgiaTechLogo';
import HawaiiLogo from '../logos/HawaiiLogo';
import HoustonLogo from '../logos/HoustonLogo';
import IllinoisLogo from '../logos/IllinoisLogo';
import IndianaLogo from '../logos/IndianaLogo';
import IowaLogo from '../logos/IowaLogo';
import IowaStateLogo from '../logos/IowaStateLogo';
import KansasLogo from '../logos/KansasLogo';
import KansasStateLogo from '../logos/KansasStateLogo';
import KennesawStateLogo from '../logos/KennesawStateLogo';
import KentStateLogo from '../logos/KentStateLogo';
import KentuckyLogo from '../logos/KentuckyLogo';
import LibertyLogo from '../logos/LibertyLogo';
import LouisianaLafayetteLogo from '../logos/LouisianaLafayetteLogo';
import LouisianaMonroeLogo from '../logos/LouisianaMonroeLogo';
import LouisianaTechLogo from '../logos/LouisianaTechLogo';
import LouisvilleLogo from '../logos/LouisvilleLogo';
import LsuLogo from '../logos/LsuLogo';
import MarshallLogo from '../logos/MarshallLogo';
import MarylandLogo from '../logos/MarylandLogo';
import MassachusettsLogo from '../logos/MassachusettsLogo';
import MemphisLogo from '../logos/MemphisLogo';
import MiamiLogo from '../logos/MiamiLogo';
import MiamiOhioLogo from '../logos/MiamiOhioLogo';
import MichiganLogo from '../logos/MichiganLogo';
import MichiganStateLogo from '../logos/MichiganStateLogo';
import MiddleTennesseeLogo from '../logos/MiddleTennesseeLogo';
import MinnesotaLogo from '../logos/MinnesotaLogo';
import MississippiStateLogo from '../logos/MississippiStateLogo';
import MissouriLogo from '../logos/MissouriLogo';
import NavyLogo from '../logos/NavyLogo';
import NcStateLogo from '../logos/NcStateLogo';
import NebraskaLogo from '../logos/NebraskaLogo';
import NevadaLogo from '../logos/NevadaLogo';
import NewMexicoLogo from '../logos/NewMexicoLogo';
import NewMexicoStateLogo from '../logos/NewMexicoStateLogo';
import NorthCarolinaLogo from '../logos/NorthCarolinaLogo';
import NorthernIllinoisLogo from '../logos/NorthernIllinoisLogo';
import NorthTexasLogo from '../logos/NorthTexasLogo';
import NorthwesternLogo from '../logos/NorthwesternLogo';
import NotreDameLogo from '../logos/NotreDameLogo';
import OhioLogo from '../logos/OhioLogo';
import OhioStateLogo from '../logos/OhioStateLogo';
import OklahomaLogo from '../logos/OklahomaLogo';
import OklahomaStateLogo from '../logos/OklahomaStateLogo';
import OldDominionLogo from '../logos/OldDominionLogo';
import OleMissLogo from '../logos/OleMissLogo';
import OregonLogo from '../logos/OregonLogo';
import OregonStateLogo from '../logos/OregonStateLogo';
import PennStateLogo from '../logos/PennStateLogo';
import PittsburghLogo from '../logos/PittsburghLogo';
import PurdueLogo from '../logos/PurdueLogo';
import RiceLogo from '../logos/RiceLogo';
import RutgersLogo from '../logos/RutgersLogo';
import SanDiegoStateLogo from '../logos/SanDiegoStateLogo';
import SanJoseStateLogo from '../logos/SanJoseStateLogo';
import SmuLogo from '../logos/SmuLogo';
import SouthAlabamaLogo from '../logos/SouthAlabamaLogo';
import SouthCarolinaLogo from '../logos/SouthCarolinaLogo';
import SouthernMissLogo from '../logos/SouthernMissLogo';
import SouthFloridaLogo from '../logos/SouthFloridaLogo';
import StanfordLogo from '../logos/StanfordLogo';
import SyracuseLogo from '../logos/SyracuseLogo';
import TcuLogo from '../logos/TcuLogo';
import TempleLogo from '../logos/TempleLogo';
import TennesseeLogo from '../logos/TennesseeLogo';
import TexasAmLogo from '../logos/TexasAmLogo';
import TexasLogo from '../logos/TexasLogo';
import TexasStateLogo from '../logos/TexasStateLogo';
import TexasTechLogo from '../logos/TexasTechLogo';
import ToledoLogo from '../logos/ToledoLogo';
import TroyLogo from '../logos/TroyLogo';
import TulaneLogo from '../logos/TulaneLogo';
import TulsaLogo from '../logos/TulsaLogo';
import UabLogo from '../logos/UabLogo';
import UclaLogo from '../logos/UclaLogo';
import UnlvLogo from '../logos/UnlvLogo';
import UscLogo from '../logos/UscLogo';
import UtahLogo from '../logos/UtahLogo';
import UtahStateLogo from '../logos/UtahStateLogo';
import UtepLogo from '../logos/UtepLogo';
import UtsaLogo from '../logos/UtsaLogo';
import VanderbiltLogo from '../logos/VanderbiltLogo';
import VirginiaLogo from '../logos/VirginiaLogo';
import VirginiaTechLogo from '../logos/VirginiaTechLogo';
import WakeForestLogo from '../logos/WakeForestLogo';
import WashingtonLogo from '../logos/WashingtonLogo';
import WashingtonStateLogo from '../logos/WashingtonStateLogo';
import WesternKentuckyLogo from '../logos/WesternKentuckyLogo';
import WesternMichiganLogo from '../logos/WesternMichiganLogo';
import WestVirginiaLogo from '../logos/WestVirginiaLogo';
import WisconsinLogo from '../logos/WisconsinLogo';
import WyomingLogo from '../logos/WyomingLogo';

type TeamLogoSetProps = {
  team: {
    name: string;
    colors: {
      primary: string;
      secondary: string;
    };
  };
  LogoComponent?: React.ElementType<LogoProps>;
};

function TeamLogoSet({ team, LogoComponent }: TeamLogoSetProps) {
  const baseSx = {
    p: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <Grid item xs={6} md={4} xl={2}>
      <Paper>
        {LogoComponent && team ? (
          <Grid container>
            <Grid
              item
              xs={4}
              sx={theme => ({
                ...baseSx,
                backgroundColor: '#ffffff',
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
              })}
            >
              <LogoComponent
                colors={{ primaryColor: team.colors.primary, secondaryColor: team.colors.secondary }}
                style={{ height: '1.5em', width: '2em' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                ...baseSx,
                backgroundColor: team.colors.primary,
              }}
            >
              <LogoComponent
                colors={{ primaryColor: team.colors.primary, secondaryColor: team.colors.secondary }}
                variant="inverted"
                style={{ height: '1.5em', width: '2em' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={theme => ({
                ...baseSx,
                backgroundColor: team.colors.secondary,
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
              })}
            >
              <LogoComponent
                colors={{ primaryColor: team.colors.primary, secondaryColor: team.colors.secondary }}
                variant="third"
                style={{ height: '1.5em', width: '2em' }}
              />
            </Grid>
          </Grid>
        ) : (
          team.name
        )}
      </Paper>
    </Grid>
  );
}

function LogosList() {
  return (
    <Grid container spacing={1}>
      <TeamLogoSet
        team={{ name: 'Air Force', colors: { primary: '#0033a0', secondary: '#8f8f8c' } }}
        LogoComponent={AirForceLogo}
      />
      <TeamLogoSet
        team={{ name: 'Akron', colors: { primary: '#041e42', secondary: '#a89968' } }}
        LogoComponent={AkronLogo}
      />
      <TeamLogoSet
        team={{ name: 'Alabama', colors: { primary: '#b30838', secondary: '#cfd4d8' } }}
        LogoComponent={AlabamaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Appalachian State', colors: { primary: '#ffcc00', secondary: '#222222' } }}
        LogoComponent={AppalachianStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Arizona', colors: { primary: '#00205c', secondary: '#c10230' } }}
        LogoComponent={ArizonaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Arizona State', colors: { primary: '#8c1d40', secondary: '#ffc627' } }}
        LogoComponent={ArizonaStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Arkansas', colors: { primary: '#a41f35', secondary: '#000000' } }}
        LogoComponent={ArkansasLogo}
      />
      <TeamLogoSet
        team={{ name: 'Arkansas State', colors: { primary: '#cc092f', secondary: '#000000' } }}
        LogoComponent={ArkansasStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Army', colors: { primary: '#d3bc8d', secondary: '#2c2a29' } }}
        LogoComponent={ArmyLogo}
      />
      <TeamLogoSet
        team={{ name: 'Auburn', colors: { primary: '#03244d', secondary: '#f26522' } }}
        LogoComponent={AuburnLogo}
      />
      <TeamLogoSet
        team={{ name: 'BYU', colors: { primary: '#002e5d', secondary: '#003da5' } }}
        LogoComponent={ByuLogo}
      />
      <TeamLogoSet
        team={{ name: 'Ball State', colors: { primary: '#ba0c2f', secondary: '#000000' } }}
        LogoComponent={BallStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Baylor', colors: { primary: '#154734', secondary: '#ffb81c' } }}
        LogoComponent={BaylorLogo}
      />
      <TeamLogoSet
        team={{ name: 'Boise State', colors: { primary: '#0033a0', secondary: '#fa4616' } }}
        LogoComponent={BoiseStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Boston College', colors: { primary: '#8c2232', secondary: '#dbcca4' } }}
        LogoComponent={BostonCollegeLogo}
      />
      <TeamLogoSet
        team={{ name: 'Bowling Green', colors: { primary: '#fe5000', secondary: '#4f2c1d' } }}
        LogoComponent={BowlingGreenLogo}
      />
      <TeamLogoSet
        team={{ name: 'Buffalo', colors: { primary: '#005bbb', secondary: '#000000' } }}
        LogoComponent={BuffaloLogo}
      />
      <TeamLogoSet
        team={{ name: 'California', colors: { primary: '#003262', secondary: '#fdb515' } }}
        LogoComponent={CaliforniaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Central Florida', colors: { primary: '#b7a369', secondary: '#000000' } }}
        LogoComponent={CentralFloridaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Central Michigan', colors: { primary: '#6a0032', secondary: '#ffc82e' } }}
        LogoComponent={CentralMichiganLogo}
      />
      <TeamLogoSet
        team={{ name: 'Charlotte', colors: { primary: '#005035', secondary: '#a49665' } }}
        LogoComponent={CharlotteLogo}
      />
      <TeamLogoSet
        team={{ name: 'Cincinnati', colors: { primary: '#e00122', secondary: '#000000' } }}
        LogoComponent={CincinnatiLogo}
      />
      <TeamLogoSet
        team={{ name: 'Clemson', colors: { primary: '#f56600', secondary: '#522d80' } }}
        LogoComponent={ClemsonLogo}
      />
      <TeamLogoSet
        team={{ name: 'Coastal Carolina', colors: { primary: '#006f71', secondary: '#a27752' } }}
        LogoComponent={CoastalCarolinaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Colorado', colors: { primary: '#cfb87c', secondary: '#000000' } }}
        LogoComponent={ColoradoLogo}
      />
      <TeamLogoSet
        team={{ name: 'Colorado State', colors: { primary: '#1e4d2b', secondary: '#c8c372' } }}
        LogoComponent={ColoradoStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Connecticut', colors: { primary: '#000e2f', secondary: '#e4002b' } }}
        LogoComponent={ConnecticutLogo}
      />
      <TeamLogoSet
        team={{ name: 'Duke', colors: { primary: '#013088', secondary: '#000000' } }}
        LogoComponent={DukeLogo}
      />
      <TeamLogoSet
        team={{ name: 'East Carolina', colors: { primary: '#592a8a', secondary: '#fdc82f' } }}
        LogoComponent={EastCarolinaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Eastern Michigan', colors: { primary: '#046a38', secondary: '#97d700' } }}
        LogoComponent={EasternMichiganLogo}
      />
      <TeamLogoSet
        team={{ name: 'Florida', colors: { primary: '#003087', secondary: '#fa4616' } }}
        LogoComponent={FloridaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Florida Atlantic', colors: { primary: '#003366', secondary: '#cc0000' } }}
        LogoComponent={FloridaAtlanticLogo}
      />
      <TeamLogoSet
        team={{ name: 'Florida International', colors: { primary: '#081e3f', secondary: '#b6862c' } }}
        LogoComponent={FloridaInternationalLogo}
      />
      <TeamLogoSet
        team={{ name: 'Florida State', colors: { primary: '#782f40', secondary: '#ceb888' } }}
        LogoComponent={FloridaStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Fresno State', colors: { primary: '#cc0033', secondary: '#00235d' } }}
        LogoComponent={FresnoStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Georgia', colors: { primary: '#ba0c2f', secondary: '#2c2a29' } }}
        LogoComponent={GeorgiaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Georgia Southern', colors: { primary: '#011e41', secondary: '#87714d' } }}
        LogoComponent={GeorgiaSouthernLogo}
      />
      <TeamLogoSet
        team={{ name: 'Georgia State', colors: { primary: '#0039a6', secondary: '#000000' } }}
        LogoComponent={GeorgiaStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Georgia Tech', colors: { primary: '#b3a369', secondary: '#003057' } }}
        LogoComponent={GeorgiaTechLogo}
      />
      <TeamLogoSet
        team={{ name: 'Hawaii', colors: { primary: '#024731', secondary: '#cccccc' } }}
        LogoComponent={HawaiiLogo}
      />
      <TeamLogoSet
        team={{ name: 'Houston', colors: { primary: '#c8102e', secondary: '#000000' } }}
        LogoComponent={HoustonLogo}
      />
      <TeamLogoSet
        team={{ name: 'Illinois', colors: { primary: '#e84a27', secondary: '#13294b' } }}
        LogoComponent={IllinoisLogo}
      />
      <TeamLogoSet
        team={{ name: 'Indiana', colors: { primary: '#990000', secondary: '#edebeb' } }}
        LogoComponent={IndianaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Iowa', colors: { primary: '#fcd116', secondary: '#000000' } }}
        LogoComponent={IowaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Iowa State', colors: { primary: '#a71930', secondary: '#fdc82f' } }}
        LogoComponent={IowaStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Kansas', colors: { primary: '#0051ba', secondary: '#e8000d' } }}
        LogoComponent={KansasLogo}
      />
      <TeamLogoSet
        team={{ name: 'Kansas State', colors: { primary: '#512888', secondary: '#000000' } }}
        LogoComponent={KansasStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Kennesaw State', colors: { primary: '#fdbb30', secondary: '#0b1315' } }}
        LogoComponent={KennesawStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Kent State', colors: { primary: '#003875', secondary: '#efab00' } }}
        LogoComponent={KentStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Kentucky', colors: { primary: '#0033a0', secondary: '#000000' } }}
        LogoComponent={KentuckyLogo}
      />
      <TeamLogoSet
        team={{ name: 'LSU', colors: { primary: '#461d7c', secondary: '#fdd023' } }}
        LogoComponent={LsuLogo}
      />
      <TeamLogoSet
        team={{ name: 'Liberty', colors: { primary: '#0a254e', secondary: '#990000' } }}
        LogoComponent={LibertyLogo}
      />
      <TeamLogoSet
        team={{ name: 'Louisiana Tech', colors: { primary: '#003087', secondary: '#cb333b' } }}
        LogoComponent={LouisianaTechLogo}
      />
      <TeamLogoSet
        team={{ name: 'Louisiana-Lafayette', colors: { primary: '#ce181e', secondary: '#0a0203' } }}
        LogoComponent={LouisianaLafayetteLogo}
      />
      <TeamLogoSet
        team={{ name: 'Louisiana-Monroe', colors: { primary: '#840029', secondary: '#fdb913' } }}
        LogoComponent={LouisianaMonroeLogo}
      />
      <TeamLogoSet
        team={{ name: 'Louisville', colors: { primary: '#c9001f', secondary: '#000000' } }}
        LogoComponent={LouisvilleLogo}
      />
      <TeamLogoSet
        team={{ name: 'Marshall', colors: { primary: '#00B140', secondary: '#231f20' } }}
        LogoComponent={MarshallLogo}
      />
      <TeamLogoSet
        team={{ name: 'Maryland', colors: { primary: '#e21833', secondary: '#ffd200' } }}
        LogoComponent={MarylandLogo}
      />
      <TeamLogoSet
        team={{ name: 'Massachusetts', colors: { primary: '#881c1c', secondary: '#000000' } }}
        LogoComponent={MassachusettsLogo}
      />
      <TeamLogoSet
        team={{ name: 'Memphis', colors: { primary: '#014386', secondary: '#9d9fa2' } }}
        LogoComponent={MemphisLogo}
      />
      <TeamLogoSet
        team={{ name: 'Miami', colors: { primary: '#005030', secondary: '#f05a00' } }}
        LogoComponent={MiamiLogo}
      />
      <TeamLogoSet
        team={{ name: 'Miami (OH)', colors: { primary: '#cf0a2c', secondary: '#000000' } }}
        LogoComponent={MiamiOhioLogo}
      />
      <TeamLogoSet
        team={{ name: 'Michigan', colors: { primary: '#f5c300', secondary: '#00274c' } }}
        LogoComponent={MichiganLogo}
      />
      <TeamLogoSet
        team={{ name: 'Michigan State', colors: { primary: '#18453b', secondary: '#000000' } }}
        LogoComponent={MichiganStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Middle Tennessee', colors: { primary: '#0066cc', secondary: '#000000' } }}
        LogoComponent={MiddleTennesseeLogo}
      />
      <TeamLogoSet
        team={{ name: 'Minnesota', colors: { primary: '#862334', secondary: '#fbb93c' } }}
        LogoComponent={MinnesotaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Mississippi State', colors: { primary: '#5d1725', secondary: '#c1c6c8' } }}
        LogoComponent={MississippiStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Missouri', colors: { primary: '#f1b82d', secondary: '#000000' } }}
        LogoComponent={MissouriLogo}
      />
      <TeamLogoSet
        team={{ name: 'NC State', colors: { primary: '#cc0000', secondary: '#000000' } }}
        LogoComponent={NcStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Navy', colors: { primary: '#00225b', secondary: '#b4a67c' } }}
        LogoComponent={NavyLogo}
      />
      <TeamLogoSet
        team={{ name: 'Nebraska', colors: { primary: '#e41c38', secondary: '#000000' } }}
        LogoComponent={NebraskaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Nevada', colors: { primary: '#003366', secondary: '#807f84' } }}
        LogoComponent={NevadaLogo}
      />
      <TeamLogoSet
        team={{ name: 'New Mexico', colors: { primary: '#ba0c2f', secondary: '#9fa0a2' } }}
        LogoComponent={NewMexicoLogo}
      />
      <TeamLogoSet
        team={{ name: 'New Mexico State', colors: { primary: '#891216', secondary: '#000000' } }}
        LogoComponent={NewMexicoStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'North Carolina', colors: { primary: '#7BAFD4', secondary: '#13294B' } }}
        LogoComponent={NorthCarolinaLogo}
      />
      <TeamLogoSet
        team={{ name: 'North Texas', colors: { primary: '#00853e', secondary: '#000000' } }}
        LogoComponent={NorthTexasLogo}
      />
      <TeamLogoSet
        team={{ name: 'Northern Illinois', colors: { primary: '#ba0c2f', secondary: '#000000' } }}
        LogoComponent={NorthernIllinoisLogo}
      />
      <TeamLogoSet
        team={{ name: 'Northwestern', colors: { primary: '#59118e', secondary: '#000000' } }}
        LogoComponent={NorthwesternLogo}
      />
      <TeamLogoSet
        team={{ name: 'Notre Dame', colors: { primary: '#0c2340', secondary: '#c99700' } }}
        LogoComponent={NotreDameLogo}
      />
      <TeamLogoSet
        team={{ name: 'Ohio', colors: { primary: '#00694e', secondary: '#000000' } }}
        LogoComponent={OhioLogo}
      />
      <TeamLogoSet
        team={{ name: 'Ohio State', colors: { primary: '#ce0f3d', secondary: '#b0b7bc' } }}
        LogoComponent={OhioStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Oklahoma', colors: { primary: '#841617', secondary: '#fdf9d8' } }}
        LogoComponent={OklahomaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Oklahoma State', colors: { primary: '#fe5c00', secondary: '#000000' } }}
        LogoComponent={OklahomaStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Old Dominion', colors: { primary: '#003767', secondary: '#A1D0F0' } }}
        LogoComponent={OldDominionLogo}
      />
      <TeamLogoSet
        team={{ name: 'Ole Miss', colors: { primary: '#14213d', secondary: '#ce1126' } }}
        LogoComponent={OleMissLogo}
      />
      <TeamLogoSet
        team={{ name: 'Oregon', colors: { primary: '#004F39', secondary: '#fee123' } }}
        LogoComponent={OregonLogo}
      />
      <TeamLogoSet
        team={{ name: 'Oregon State', colors: { primary: '#dc4405', secondary: '#000000' } }}
        LogoComponent={OregonStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Penn State', colors: { primary: '#002d62', secondary: '#000000' } }}
        LogoComponent={PennStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Pittsburgh', colors: { primary: '#003594', secondary: '#ffb81c' } }}
        LogoComponent={PittsburghLogo}
      />
      <TeamLogoSet
        team={{ name: 'Purdue', colors: { primary: '#000000', secondary: '#cfb991' } }}
        LogoComponent={PurdueLogo}
      />
      <TeamLogoSet
        team={{ name: 'Rice', colors: { primary: '#00205b', secondary: '#c1c6c8' } }}
        LogoComponent={RiceLogo}
      />
      <TeamLogoSet
        team={{ name: 'Rutgers', colors: { primary: '#cf102d', secondary: '#231f20' } }}
        LogoComponent={RutgersLogo}
      />
      <TeamLogoSet
        team={{ name: 'SMU', colors: { primary: '#cc0035', secondary: '#354ca1' } }}
        LogoComponent={SmuLogo}
      />
      <TeamLogoSet
        team={{ name: 'San Diego State', colors: { primary: '#c41230', secondary: '#000000' } }}
        LogoComponent={SanDiegoStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'San Jose State', colors: { primary: '#0038a8', secondary: '#ffb81a' } }}
        LogoComponent={SanJoseStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'South Alabama', colors: { primary: '#00205b', secondary: '#Bf0d3e' } }}
        LogoComponent={SouthAlabamaLogo}
      />
      <TeamLogoSet
        team={{ name: 'South Carolina', colors: { primary: '#73000a', secondary: '#000000' } }}
        LogoComponent={SouthCarolinaLogo}
      />
      <TeamLogoSet
        team={{ name: 'South Florida', colors: { primary: '#006747', secondary: '#cfc493' } }}
        LogoComponent={SouthFloridaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Southern Miss', colors: { primary: '#ffd046', secondary: '#000000' } }}
        LogoComponent={SouthernMissLogo}
      />
      <TeamLogoSet
        team={{ name: 'Stanford', colors: { primary: '#8c1515', secondary: '#000000' } }}
        LogoComponent={StanfordLogo}
      />
      <TeamLogoSet
        team={{ name: 'Syracuse', colors: { primary: '#d44500', secondary: '#0c233f' } }}
        LogoComponent={SyracuseLogo}
      />
      <TeamLogoSet
        team={{ name: 'TCU', colors: { primary: '#4d1979', secondary: '#000000' } }}
        LogoComponent={TcuLogo}
      />
      <TeamLogoSet
        team={{ name: 'Temple', colors: { primary: '#981e32', secondary: '#000000' } }}
        LogoComponent={TempleLogo}
      />
      <TeamLogoSet
        team={{ name: 'Tennessee', colors: { primary: '#ff8200', secondary: '#58595b' } }}
        LogoComponent={TennesseeLogo}
      />
      <TeamLogoSet
        team={{ name: 'Texas', colors: { primary: '#bf5700', secondary: '#333f48' } }}
        LogoComponent={TexasLogo}
      />
      <TeamLogoSet
        team={{ name: 'Texas A&M', colors: { primary: '#500000', secondary: '#b1b3b6' } }}
        LogoComponent={TexasAmLogo}
      />
      <TeamLogoSet
        team={{ name: 'Texas State', colors: { primary: '#501214', secondary: '#8c734a' } }}
        LogoComponent={TexasStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Texas Tech', colors: { primary: '#cc0000', secondary: '#000000' } }}
        LogoComponent={TexasTechLogo}
      />
      <TeamLogoSet
        team={{ name: 'Toledo', colors: { primary: '#15397f', secondary: '#ffda00' } }}
        LogoComponent={ToledoLogo}
      />
      <TeamLogoSet
        team={{ name: 'Troy', colors: { primary: '#8a2432', secondary: '#b2b3b5' } }}
        LogoComponent={TroyLogo}
      />
      <TeamLogoSet
        team={{ name: 'Tulane', colors: { primary: '#005837', secondary: '#418fde' } }}
        LogoComponent={TulaneLogo}
      />
      <TeamLogoSet
        team={{ name: 'Tulsa', colors: { primary: '#003595', secondary: '#d0b787' } }}
        LogoComponent={TulsaLogo}
      />
      <TeamLogoSet
        team={{ name: 'UAB', colors: { primary: '#00704a', secondary: '#cfc580' } }}
        LogoComponent={UabLogo}
      />
      <TeamLogoSet
        team={{ name: 'UCLA', colors: { primary: '#2d68c4', secondary: '#f2a900' } }}
        LogoComponent={UclaLogo}
      />
      <TeamLogoSet
        team={{ name: 'UNLV', colors: { primary: '#cf0a2c', secondary: '#000000' } }}
        LogoComponent={UnlvLogo}
      />
      <TeamLogoSet
        team={{ name: 'USC', colors: { primary: '#9d2235', secondary: '#ffc72c' } }}
        LogoComponent={UscLogo}
      />
      <TeamLogoSet
        team={{ name: 'UTEP', colors: { primary: '#011e41', secondary: '#f38221' } }}
        LogoComponent={UtepLogo}
      />
      <TeamLogoSet
        team={{ name: 'UTSA', colors: { primary: '#0a2240', secondary: '#e04403' } }}
        LogoComponent={UtsaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Utah', colors: { primary: '#cc0000', secondary: '#000000' } }}
        LogoComponent={UtahLogo}
      />
      <TeamLogoSet
        team={{ name: 'Utah State', colors: { primary: '#00263a', secondary: '#8a8d8f' } }}
        LogoComponent={UtahStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'Vanderbilt', colors: { primary: '#ceb888', secondary: '#000000' } }}
        LogoComponent={VanderbiltLogo}
      />
      <TeamLogoSet
        team={{ name: 'Virginia', colors: { primary: '#232d4b', secondary: '#f84c1e' } }}
        LogoComponent={VirginiaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Virginia Tech', colors: { primary: '#6a2c3e', secondary: '#cf4520' } }}
        LogoComponent={VirginiaTechLogo}
      />
      <TeamLogoSet
        team={{ name: 'Wake Forest', colors: { primary: '#ceb888', secondary: '#000000' } }}
        LogoComponent={WakeForestLogo}
      />
      <TeamLogoSet
        team={{ name: 'Washington', colors: { primary: '#330066', secondary: '#e8d3a2' } }}
        LogoComponent={WashingtonLogo}
      />
      <TeamLogoSet
        team={{ name: 'Washington State', colors: { primary: '#981e32', secondary: '#53565a' } }}
        LogoComponent={WashingtonStateLogo}
      />
      <TeamLogoSet
        team={{ name: 'West Virginia', colors: { primary: '#002855', secondary: '#eaaa00' } }}
        LogoComponent={WestVirginiaLogo}
      />
      <TeamLogoSet
        team={{ name: 'Western Kentucky', colors: { primary: '#b01e24', secondary: '#000000' } }}
        LogoComponent={WesternKentuckyLogo}
      />
      <TeamLogoSet
        team={{ name: 'Western Michigan', colors: { primary: '#532e1f', secondary: '#f1c500' } }}
        LogoComponent={WesternMichiganLogo}
      />
      <TeamLogoSet
        team={{ name: 'Wisconsin', colors: { primary: '#c4012f', secondary: '#000000' } }}
        LogoComponent={WisconsinLogo}
      />
      <TeamLogoSet
        team={{ name: 'Wyoming', colors: { primary: '#492f24', secondary: '#ffc425' } }}
        LogoComponent={WyomingLogo}
      />
      <TeamLogoSet team={{ name: 'Abilene Christian', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Alabama A&M', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Alabama State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Albany', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Alcorn State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Arkansas-Pine Bluff', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Austin Peay', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Bethune-Cookman', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Brown', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Bryant', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Bucknell', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Butler', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Cal Poly', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Campbell', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Central Arkansas', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Central Connecticut', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Charleston Southern', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Chattanooga', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Colgate', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Columbia', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Cornell', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Dartmouth', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Davidson', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Dayton', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Delaware', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Delaware State', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Drake', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Duquesne', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'East Tennessee State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Eastern Illinois', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Eastern Kentucky', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Eastern Washington', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Elon', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Florida A&M', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Fordham', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Furman', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Gardner-Webb', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Georgetown', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Grambling State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Hampton', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Harvard', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Holy Cross', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Houston Baptist', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Howard', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Idaho', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Idaho State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Illinois State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Incarnate Word', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Indiana State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Jackson State', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Jacksonville', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Jacksonville State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'James Madison', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Lafayette', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Lamar', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Lehigh', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Long Island', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Maine', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Marist', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'McNeese State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Mercer', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Merrimack', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Mississippi Valley State', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Missouri State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Monmouth', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Montana', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Montana State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Morehead State', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Morgan State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Murray State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'New Hampshire', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Nicholls State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Norfolk State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'North Alabama', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'North Carolina A&T', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'North Carolina Central', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'North Dakota', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'North Dakota State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Northern Arizona', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Northern Colorado', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Northern Iowa', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Northwestern State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Penn', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Portland State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Prairie View A&M', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Presbyterian', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Princeton', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Rhode Island', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Richmond', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Robert Morris', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Sacramento State', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Sacred Heart', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Saint Francis', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Sam Houston State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Samford', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'San Diego', colors: { primary: '', secondary: '' } }} /> */}
      {/* <TeamLogoSet team={{ name: 'Savannah State', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'South Carolina State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'South Dakota', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'South Dakota State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Southeast Missouri State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Southeastern Louisiana', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Southern', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Southern Illinois', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Southern Utah', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'St. Thomas', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Stephen F. Austin', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Stetson', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Stony Brook', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Tarleton State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Tennessee State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Tennessee Tech', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Texas Southern', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'The Citadel', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Towson', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'UC Davis', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'UT Martin', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Utah Tech', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'VMI', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Valparaiso', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Villanova', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Wagner', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Weber State', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Western Carolina', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Western Illinois', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Western New Mexico', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'William & Mary', colors: { primary: '', secondary: '' } }} />
      <TeamLogoSet team={{ name: 'Wofford', colors: { primary: '', secondary: '' } }} />
      {/* <TeamLogoSet team={{ name: 'Yale', colors: { primary: '', secondary: '' } }} /> */}
      <TeamLogoSet team={{ name: 'Youngstown State', colors: { primary: '', secondary: '' } }} />
    </Grid>
  );
}

export default LogosList;
