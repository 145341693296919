import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function SamHoustonLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 500 500" {...restProps}>
      <g clipPath="url(#clip0)">
        <path
          d="M386.743 198.273L376.516 246.631L398.359 250.167L388.258 297.768H338.763C320.707 309.131 304.167 303.071 304.167 303.071C283.965 346.379 246.97 343.727 246.97 343.727L234.596 401.934L211.364 405.47L201.137 453.828H314.394L324.622 405.47L302.778 401.934L312.879 354.333H376.137L354.925 453.828H445.707L455.935 405.47L434.091 401.934L466.415 250.167L489.647 246.631L499.874 198.273H386.743V198.273Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M170.96 254.586C162.5 248.02 158.46 237.162 161.111 224.535C169.318 185.646 220.707 175.419 220.707 175.419C211.111 159.51 192.677 149.283 169.571 149.283H132.449C121.97 149.283 115.152 141.328 117.172 131.606L119.192 122.389C121.212 112.667 131.439 104.712 141.919 104.712H178.03L178.409 131.101C180.556 131.101 233.838 131.101 233.838 131.101L252.02 46H127.525C88.1313 46 50 75.6717 42.1717 112.162L35.9848 141.455C28.1566 178.071 53.7879 207.616 93.1818 207.616H130.303C140.783 207.616 147.601 215.571 145.581 225.293L143.561 234.51C141.54 244.232 131.313 252.187 120.833 252.187H73.9899L73.6111 225.798C71.4646 225.798 18.1818 225.798 18.1818 225.798L0 310.773H146.338C146.338 310.773 133.207 280.596 170.96 254.586Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M251.388 330.47C269.822 330.47 289.393 313.677 293.686 293.727C295.454 285.268 293.56 279.965 294.949 273.273C296.085 267.717 297.095 265.066 298.232 259.636C301.262 245.116 290.024 233.626 275.631 233.626C252.146 233.626 245.58 248.273 209.469 253.449C176.767 258.121 161.11 276.429 158.459 288.677C154.545 306.859 163.636 316.328 192.676 316.328C203.661 316.328 210.605 314.687 214.141 314.687C228.787 314.561 226.136 330.47 251.388 330.47Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M227.904 186.909C227.904 186.909 180.934 193.601 174.368 224.535C171.464 238.172 180.934 247.641 193.813 247.641C203.914 247.641 217.171 242.338 219.444 231.48C221.591 221.505 213.257 214.813 215.025 206.101C217.424 195.621 227.904 186.909 227.904 186.909Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M236.364 202.692C233.46 216.328 244.697 221.884 256.187 221.884C273.106 221.884 283.207 214.308 286.364 199.157L288.258 190.444C292.803 169.106 316.414 161.278 316.414 161.278C283.712 161.278 242.298 174.914 236.364 202.692Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M298.611 220.495C296.212 231.732 305.808 236.025 313.889 236.025C328.283 236.025 335.606 229.207 337.752 218.98C339.015 212.919 338.257 193.98 359.722 185.899C340.53 185.646 303.788 196.379 298.611 220.495Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M302.778 276.177C301.136 284.131 305.808 291.328 317.424 291.328C327.778 291.328 337.879 287.414 340.53 275.041C341.667 269.864 341.919 265.571 342.172 264.308C345.581 248.525 361.869 242.717 361.869 242.717C339.646 242.844 307.449 254.333 302.778 276.177Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M451.01 446.379H453.409V453.828H454.798V446.379H457.449V444.99H451.01V446.379Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
        <path
          d="M462.5 451.051L460.48 444.99H458.586V453.828H460.101V448.147L461.869 453.828H463.131L464.899 448.147V453.828H466.288V444.99H464.394L462.5 451.051Z"
          fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="500"
            height="407.828"
            fill={variant === 'main' ? colors.primaryColor : colors.secondaryColor}
            transform="translate(0 46)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default SamHoustonLogo;
