import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link as MuiLink,
  Paper,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { gql, useMutation } from 'urql';
import { useFlashMessageContext } from '../../contexts/flashMessage';
import { ChangePasswordMutation, ChangePasswordMutationVariables } from '../../generated/hooks';
import { ChangePasswordInputType } from '../../generated/schemas';
import InputField from '../forms/InputField';

const changePassword = gql`
  mutation changePassword($changePasswordInput: ChangePasswordInputType!) {
    changePassword(input: $changePasswordInput) {
      ok
    }
  }
`;

export default function ChangePasswordForm() {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });
  const { addFlashMessage, clearFlashMessage } = useFlashMessageContext();
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
  const password = watch('password', '');
  useEffect(() => {
    if (formHasBeenSubmitted) {
      trigger('passwordConfirm');
    }
  }, [password, formHasBeenSubmitted]);

  const [, changePasswordMutation] = useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    changePassword,
  );
  const handleChangePassword = useCallback(
    (changePasswordInput: ChangePasswordInputType) => changePasswordMutation({ changePasswordInput }),
    [changePasswordMutation],
  );

  return (
    <>
      <Breadcrumbs sx={{ mb: 2 }}>
        <MuiLink
          component={Link}
          to="/"
          sx={theme => ({ color: 'text.secondary', textDecorationColor: theme.palette.text.secondary })}
        >
          Home
        </MuiLink>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Change Password
        </Typography>
      </Breadcrumbs>
      <Container maxWidth="sm" sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper sx={{ p: 2 }}>
          <form
            onSubmit={handleSubmit(
              ({ password }) => {
                clearFlashMessage();
                setFormHasBeenSubmitted(true);
                return handleChangePassword({ password })
                  .then(() => addFlashMessage('success', 'Your password has been updated'))
                  .catch(() => addFlashMessage('error', 'An unknown error occurred'));
              },
              () => setFormHasBeenSubmitted(true),
            )}
          >
            <Grid container spacing={2} sx={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <InputField
                  control={control}
                  label="Password"
                  name="password"
                  rules={{
                    required: 'Required',
                    minLength: {
                      value: 8,
                      message: 'Password must be at least 8 characters long',
                    },
                  }}
                  sx={{ width: '100%' }}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  control={control}
                  label="Confirm Password"
                  name="passwordConfirm"
                  rules={{ validate: value => (value !== password ? 'Passwords do not match' : undefined) }}
                  sx={{ width: '100%' }}
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
                <Button type="submit" variant="contained" disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} /> : 'Change Password'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}
