import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import currency from 'currency.js';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { FetchLeagueTeamOwnersQuery, FetchLeagueTeamOwnersQueryVariables } from '../../../../../generated/hooks';

const fetchLeagueTeamOwners = gql`
  query fetchLeagueTeamOwners($leagueId: UUID!, $teamId: UUID!) {
    league(id: $leagueId) {
      id
      team(id: $teamId) {
        stockPurchasePrices
        positions {
          id
          quantity
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

export default function Owners() {
  const { leagueId, teamId } = useParams<{ leagueId: string; teamId: string }>();

  const [{ data }] = useQuery<FetchLeagueTeamOwnersQuery, FetchLeagueTeamOwnersQueryVariables>({
    query: fetchLeagueTeamOwners,
    variables: { leagueId, teamId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const userPositions = data?.league.team.positions.sort((left, right) => {
    if (left.quantity !== right.quantity) {
      return right.quantity - left.quantity;
    }
    return left.user.fullName.localeCompare(right.user.fullName);
  });
  const totalQuantity = userPositions?.reduce((total, position) => total + position.quantity, 0);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Table sx={{ maxWidth: 1200 }} size={isMdDown ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell align="right">Shares Owned</TableCell>
            <TableCell align="right">Total Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userPositions?.map(position => (
            <TableRow key={position.id}>
              <TableCell>
                <MuiLink component={Link} to={`/league/${leagueId}/user/${position.user.id}`}>
                  {position.user.fullName}
                </MuiLink>
              </TableCell>
              <TableCell align="right">{position.quantity}</TableCell>
              <TableCell align="right">
                {currency(data?.league.team.stockPurchasePrices[0]).multiply(position.quantity).format()}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align="right">{totalQuantity}</TableCell>
            <TableCell align="right">
              {currency(data?.league.team.stockPurchasePrices[0]).multiply(totalQuantity).format()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
