import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function RutgersLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 303.7 270.09" {...restProps}>
      <path
        d="m3.5795 3.5795v47.954h19.907v167.84h-19.907v47.141h127.82v-47.141h-20.595v-65.882h34.419l42.718 65.882h-24.176v47.141h136.36v-47.141h-24.864l-48.659-75.043c8.9245-4.8541 15.971-11.005 21.495-17.541 8.8002-10.481 13.908-22.085 16.23-33.817 2.5278-13.144 1.67-26.572-2.8703-39.506-3.74-10.499-9.92-20.747-19.45-29.72-6.93-6.471-15.74-12.316-26.69-16.102-7.44-2.5838-15.87-4.0816-24.4-4.0579-0.12351 0.0002979-0.2468 0.00159-0.37036 0.00263l-0.0685-0.00921h-186.9v0zm107.22 47.954h52.196c14.841 0 26.599 11.394 26.599 28.052 0 16.659-14.98 28.187-28.292 28.187h-50.502v-56.239 0z"
        stroke={variant === 'third' ? '#ffffff' : colors.secondaryColor}
        strokeLinecap="round"
        strokeWidth="7.159"
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
      />
    </SvgIcon>
  );
}

export default RutgersLogo;
