import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function WisconsinLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox={variant === 'third' ? '0 -10 250 256' : '0 0 250 246'} {...restProps}>
      {variant === 'third' && (
        <path
          d="m173.257 9.251.008-.014.665-5.841s18.115-1.987 37.663-2.216c24.482-.286 37.23 1.005 37.23 1.005l-6.517 62.758s-3.769-.289-6.664 1.72c-4.078 2.819-5.649 10.861-5.649 10.861L195.52 187.686s-1.404-.495-4.019-1.214l-.005.017c-5.896-1.619-17.99-4.381-34.429-5.223-14.565-.745-23.828.504-23.828.504l-10.135-46.891-.001-.002-3.665 18.394-.011-.019-6.477 32.501c-1.945.416-3.875.869-5.79 1.339l-.005.02c-22.454 5.508-42.581 14.961-55.785 34.083l-28.661-87.036c-1.129-3.562-2.165-6.576-6.084-7.79-3.951-1.234-7.097.716-7.097.716l6.933-63.672-.058.004-.012-.016.592-5.462s11.588-10.205 27.888-19.656c15.511-8.999 33.939-15.811 39.641-17.826l-6.97 67.612c-1.14.798-2.64 2.289-3.922 5.04-1.375 2.965-1.438 8.99-.58 12.728l6.245 28.307-.006.024 3.387 15.356 13.252-52.901c1.157-4.859 1.992-6.68 0-10.435-1.738-3.292-4.78-3.356-4.78-3.356l6.906-60.108h.001v-.002l-.001.002.012-.011.699-6.088s13.396-3.862 29.111-7.083c14.548-2.971 28.154-4.533 32.515-4.992l-6.69 64.906c-1.112.411-2.748 1.413-3.874 3.852-1.525 3.294-1.61 8.852-.727 12.366l6.244 28.896-.007.028 3.316 15.339 14.313-54.634c1.237-3.885.929-6.035-.508-8.724-1.449-2.719-5.434-2.79-5.434-2.79l6.243-54.537v-.001Z"
          fill="none"
          fillRule="nonzero"
          stroke="#ffffff"
          strokeWidth="24"
        />
      )}
      <path
        d="m155.327 114.598-10.783 9.016-2.162-107.03-44.337 6.138v.002a250.032 250.032 0 0 0-7.234 2.378l-.703.244-7.04 60.848-.154 1.538 1.552-.234c.172-.023 1.882-.15 3.453 2.021 1.655 2.292 1.11 4.535.119 8.613l-8.759 36.036-6.164 4.249-20.979-75.996-35.725.988c-4.947 4.069-7.948 6.942-8.007 6.999l-.31.292-7.26 66.312 2.083-1.273c.039-.026 3.793-2.277 7.291-2.277.676 0 1.3.079 1.857.26 2.606.81 3.469 2.182 4.59 5.682l32.795 95.664 1.34-2.149c12.59-20.256 28.341-31.669 54.365-39.411l.685-.204 1.823-8.567-.8-33.999 12.575 2.533 8.772 36.843 1.159-.261c.076-.015 7.324-1.597 20.958-1.597 2.199 0 4.476.048 6.765.133 15.749.558 31.007 4.633 31.16 4.678l1.123.292 2.131-6.867c-.376-.103-3.208-46.471-3.208-46.471l23.405-121.856-38.446-8.913v-.001c-4.231.444-6.81.772-6.88.778l-.933.128-6.062 57.594-.08 1.3h1.327c.314 0 3.076.057 4.103 1.95 1.138 2.097 1.064 4.992-.243 9.126l-9.162 34.471Z"
        fill={colors.secondaryColor}
        fillRule="nonzero"
      />
      <path
        d="m173.257 9.251.008-.014.665-5.841s18.115-1.987 37.663-2.216c24.482-.286 37.23 1.005 37.23 1.005l-6.517 62.758s-3.769-.289-6.664 1.72c-4.078 2.819-5.649 10.861-5.649 10.861L195.52 187.686s-1.404-.495-4.019-1.214l-.005.017c-5.896-1.619-17.99-4.381-34.429-5.223-14.565-.745-23.828.504-23.828.504l-10.135-46.891-.001-.002-3.665 18.394-.011-.019-6.477 32.501c-1.945.416-3.875.869-5.79 1.339l-.005.02c-22.454 5.508-42.581 14.961-55.785 34.083l-28.661-87.036c-1.129-3.562-2.165-6.576-6.084-7.79-3.951-1.234-7.097.716-7.097.716l6.933-63.672-.058.004-.012-.016.592-5.462s11.588-10.205 27.888-19.656c15.511-8.999 33.939-15.811 39.641-17.826l-6.97 67.612c-1.14.798-2.64 2.289-3.922 5.04-1.375 2.965-1.438 8.99-.58 12.728l6.245 28.307-.006.024 3.387 15.356 13.252-52.901c1.157-4.859 1.992-6.68 0-10.435-1.738-3.292-4.78-3.356-4.78-3.356l6.906-60.108h.001v-.002l-.001.002.012-.011.699-6.088s13.396-3.862 29.111-7.083c14.548-2.971 28.154-4.533 32.515-4.992l-6.69 64.906c-1.112.411-2.748 1.413-3.874 3.852-1.525 3.294-1.61 8.852-.727 12.366l6.244 28.896-.007.028 3.316 15.339 14.313-54.634c1.237-3.885.929-6.035-.508-8.724-1.449-2.719-5.434-2.79-5.434-2.79l6.243-54.537v-.001Z"
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default WisconsinLogo;
