import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function UscLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 122.28125 182" {...restProps}>
      <g>
        {variant === 'main' && (
          <path
            fill={colors.secondaryColor}
            d="m19.112 1.0078-17.838 17.838 0.0002 37.457 17.834 17.84h27.944l-14.997 14.998v5.595h-2.839v-15.974h-27.942l0.0002 24.408 17.838 17.84h12.943v42.16l17.836 17.84h53.829l17.84-17.84v-24.41h-27.946v15.98h-33.621v-33.73h12.943l17.838-17.84v-5.596h2.84v15.976h27.946v-24.405l-17.84-17.842h-12.946v-5.59l-17.836-17.84-43.722-0.002v-20.592h33.619v15.973h27.939v-24.404l-17.838-17.84-53.824-0.0002z"
          />
        )}
        <path
          d="m20.289 3.8457l-16.178 16.175 0.0003 35.108 16.174 16.176h45.385v26.269h-5.676v20.596h11.764l16.178-16.18v-35.101l-16.174-16.176-45.387-0.002v-26.273h39.295v15.976h22.266v-20.391l-16.174-16.177-51.473-0.0003zm30.777 70.297l-16.175 16.173v0.002 71.672l16.173 16.18h51.476l16.18-16.18v-20.39h-22.271v15.98h-39.295v-62.844h5.68v-20.591l-11.768-0.002zm39.707 0v20.593h5.678v15.974h22.269v-20.39l-16.18-16.177h-11.767zm-86.662 7.459v20.388l16.174 16.18h11.77v-20.596h-5.68v-15.972h-22.264z"
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          transform="translate(0 -.00093080)"
        />
      </g>
    </SvgIcon>
  );
}

export default UscLogo;
