import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MiamiOhioLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 196 140" {...restProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          fill="#FFFFFF"
          points="162.688824 95.227451 162.663333 44.9823529 189.377059 0.494117647 120.524118 0.494117647 94.9888235 44.3588235 69.2652941 0.384313725 0.612352941 0.488235294 27.4417647 45.1843137 27.3221569 95.1862745 0.612352941 139.876471 189.245686 139.880392"
        />
        <polygon
          fill={colors.secondaryColor}
          points="161.149216 102.513333 157.727647 102.513333 157.727647 37.8603922 161.149216 37.8603922 180.75902 5.35647059 123.511961 5.35647059 94.9923529 53.9780392 66.4786275 5.35647059 9.22960784 5.35647059 28.8394118 37.8603922 32.2609804 37.8603922 32.2609804 102.513333 28.8394118 102.513333 9.22764706 135.019216 93.5198039 135.019216 73.9041176 102.513333 70.4864706 102.513333 70.4864706 81.8447059 95.0139216 123.525098 119.355098 81.9682353 119.355098 102.513333 115.933529 102.513333 96.3237255 135.019216 180.75902 135.019216"
        />
        <polygon
          fill={colors.primaryColor}
          points="164.760588 14.3882353 156.052745 28.8294118 148.691961 28.8294118 148.691961 111.545098 156.052745 111.545098 164.756667 125.980392 112.325294 125.980392 121.035098 111.545098 128.388039 111.545098 128.388039 48.6803922 94.9919608 105.67451 61.4566667 48.6745098 61.4566667 111.545098 68.8115686 111.545098 77.517451 125.980392 25.2311765 125.980392 33.9390196 111.545098 41.2978431 111.545098 41.2998039 28.8294118 33.9409804 28.8294118 25.2311765 14.3882353 61.3037255 14.3882353 94.9919608 71.8411765 128.688039 14.3882353"
        />
        <path
          d="M185.83902,130.889216 C185.844902,131.179412 185.991961,131.742157 186.956667,131.742157 C187.476275,131.742157 188.06451,131.618627 188.06451,131.053922 C188.06451,130.648039 187.662549,130.532353 187.103725,130.39902 L186.533137,130.267647 C185.686078,130.069608 184.860588,129.877451 184.860588,128.712745 C184.860588,128.126471 185.174314,127.085294 186.888039,127.085294 C188.505686,127.085294 188.950784,128.144118 188.958627,128.795098 L187.89,128.795098 C187.862549,128.561765 187.772353,127.99902 186.813529,127.99902 C186.386078,127.99902 185.882157,128.153922 185.882157,128.630392 C185.882157,129.048039 186.227255,129.130392 186.450784,129.189216 L187.73902,129.50098 C188.466471,129.673529 189.121373,129.973529 189.121373,130.922549 C189.121373,132.514706 187.505686,132.653922 187.033137,132.653922 C185.109608,132.653922 184.770392,131.546078 184.770392,130.889216 L185.83902,130.889216 Z M195.180196,132.514706 L194.150784,132.514706 L194.150784,128.093137 L194.135098,128.093137 L193.150784,132.514706 L192.062549,132.514706 L191.105686,128.093137 L191.088039,128.093137 L191.088039,132.514706 L190.048824,132.514706 L190.048824,127.228431 L191.670392,127.228431 L192.631176,131.393137 L192.644902,131.393137 L193.580196,127.228431 L195.180196,127.228431 L195.180196,132.514706 Z"
          fill={colors.secondaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default MiamiOhioLogo;
