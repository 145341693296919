import React, { useCallback, useMemo, useState } from 'react';
import { Add as AddIcon, AdminPanelSettings as AdminPanelSettingsIcon } from '@mui/icons-material';
import { Box, Button, Fab, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { gql, useMutation, useQuery } from 'urql';
import { useToggleContext } from '../../contexts/toggle';
import {
  FetchUserLeaguesQuery,
  FetchUserLeaguesQueryVariables,
  RespondToLeagueInvitationMutation,
  RespondToLeagueInvitationMutationVariables,
} from '../../generated/hooks';
import { LeagueType, RespondToLeagueInvitationInputType } from '../../generated/schemas';
import { leagueTypeLabel } from '../../utils';
import CreateLeagueModal from './CreateLeagueModal';

const fetchUserLeagues = gql`
  query fetchUserLeagues {
    invitations {
      id
      league {
        id
        name
        season
        leagueType
        userIsCommissioner
        commissioner {
          id
          fullName
        }
        invitations {
          id
          email
          user {
            id
            fullName
          }
          status
        }
        users {
          id
          fullName
        }
      }
    }
    leagues {
      id
      name
      season
      leagueType
      userIsCommissioner
      commissioner {
        id
        fullName
      }
      invitations {
        id
        email
        user {
          id
          fullName
        }
        status
      }
      users {
        id
        fullName
      }
    }
  }
`;

const respondToLeagueInvitation = gql`
  mutation respondToLeagueInvitation($respondToLeagueInvitationInput: RespondToLeagueInvitationInputType!) {
    respondToLeagueInvitation(input: $respondToLeagueInvitationInput) {
      ok
      league {
        id
      }
    }
  }
`;

function LeagueList() {
  const { getToggle } = useToggleContext();
  const [{ data }] = useQuery<FetchUserLeaguesQuery, FetchUserLeaguesQueryVariables>({ query: fetchUserLeagues });
  const leagues = useMemo(() => {
    if (!data?.leagues) {
      return [];
    }
    return data.leagues
      .map(league => ({ ...league, invitationId: null }))
      .concat(data.invitations.map(invitation => ({ ...invitation.league, invitationId: invitation.id })));
  }, [data]);
  const [, respondToLeagueInvitationMutation] = useMutation<
    RespondToLeagueInvitationMutation,
    RespondToLeagueInvitationMutationVariables
  >(respondToLeagueInvitation);
  const handleRespondToLeagueInvitation = useCallback(
    (respondToLeagueInvitationInput: RespondToLeagueInvitationInputType) =>
      respondToLeagueInvitationMutation({ respondToLeagueInvitationInput }),
    [respondToLeagueInvitationMutation],
  );
  const [createLeagueModalOpen, setCreateLeagueModalOpen] = useState(false);
  const allowLeagueCreation = getToggle('allowLeagueCreation')?.on;
  const showSeason = leagues.some(league => league.season.toString() !== getToggle('currentSeason').value);
  const showLeagueType = leagues.some(league => league.leagueType !== LeagueType.PickEm);
  return (
    <div>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Your Leagues
      </Typography>
      <Grid container spacing={2}>
        {leagues?.map(league => {
          const leaguePaper = (
            <Paper sx={{ p: 2, flexGrow: 1 }}>
              <div style={{ display: 'flex' }}>
                <Typography variant="h5" sx={{ flexGrow: 1 }}>
                  {league.invitationId && 'Invitation: '}
                  {league.name}
                </Typography>
                {league.userIsCommissioner && <AdminPanelSettingsIcon />}
              </div>
              {showSeason && <Typography variant="h6">{league.season}</Typography>}
              {showLeagueType && <Typography variant="h6">{leagueTypeLabel[league.leagueType]}</Typography>}
              <p>
                <Typography variant="h6" component="span">
                  Commissioner:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                  {league.commissioner.fullName}
                </Typography>
              </p>
              <Typography variant="h6">Players:</Typography>
              <List>
                {league.users.map(user => (
                  <ListItem key={user.id}>{user.fullName}</ListItem>
                ))}
              </List>
              {league.invitations.length > 0 && (
                <>
                  <Typography variant="h6">Invitations:</Typography>
                  <List>
                    {league.invitations.map(invitation => (
                      <ListItem key={invitation.id}>
                        {invitation.user ? invitation.user.fullName : invitation.email}
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              {league.invitationId && (
                <Box sx={{ display: 'flex' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ flexGrow: 1, mr: 1 }}
                    onClick={() => {
                      handleRespondToLeagueInvitation({ invitationId: league.invitationId, accept: true });
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{ flexGrow: 1, ml: 1 }}
                    onClick={() => {
                      handleRespondToLeagueInvitation({ invitationId: league.invitationId, accept: false });
                    }}
                  >
                    Decline
                  </Button>
                </Box>
              )}
            </Paper>
          );
          return (
            <Grid item key={league.id} xs={12} md={6} lg={4} xl={3}>
              {league.invitationId ? (
                leaguePaper
              ) : (
                <Button
                  component={Link}
                  to={{ pathname: `/league/${league.id}` }}
                  state={{
                    leagueName: league.name,
                    leagueType: league.leagueType,
                    season: league.season,
                    userIsCommissioner: league.userIsCommissioner,
                  }}
                  variant="text"
                  sx={{
                    p: 0,
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                    textTransform: 'inherit',
                  }}
                  disableFocusRipple
                  fullWidth
                  tabIndex={-1}
                >
                  {leaguePaper}
                </Button>
              )}
            </Grid>
          );
        })}
      </Grid>
      {allowLeagueCreation && (
        <Fab
          color="primary"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          onClick={() => setCreateLeagueModalOpen(true)}
        >
          <AddIcon />
        </Fab>
      )}
      {createLeagueModalOpen && <CreateLeagueModal onClose={() => setCreateLeagueModalOpen(false)} />}
    </div>
  );
}

export default LeagueList;
