import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function TexasTechLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 189 220.91" {...restProps}>
      <g transform="matrix(2.4993 0 0 2.4993 -1.9457 -.62563)">
        <path
          d="m75.683 29.539h-10.582l0.0156 15.574h-14.168v18.129h7.043v24.883h-38.805v-24.91h7.0586l0.0117-18.102h-14.281v-15.574h-10.715v-28.484h74.422z"
          fill={colors.secondaryColor}
        />
        <path
          d="m61.565 41.496h-7.1641l-1.6875 1.7188h10.492v-13.711l-1.6406 1.6484zm-19.328-3.5117v33.91h0.0274l1.7383-1.6602h-8e-3v-30.664h3.5039v27.145h7.0898v17.898h-31.832l-1.6641 1.6445h35.199v-21.148h-7.0703v-25.539h3.4922l1.6875-1.5859zm5.2539 41.066h-17.434l-1.7188 1.75h20.953v-10.566h-8e-3l-1.7852 1.6641h-8e-3zm8e-3 -59.77v10.223h1.7227v-8.4609h7.0586l1.5977-1.7617zm24.781 6.4922h-14.402l-1.5977 1.8672h17.703v-24.715l-1.7031 1.8672zm-52.926-6.457v6.457h-14.07l-2.0117 1.8672h17.785v-6.5977h7.2617l1.6953-1.7227zm10.66 20.254h-5.2031v3.6445h-10.566l1.6133-1.7188h7.2031v-3.5117h12.125l-1.6719 1.5859z"
          fill="#cccccc"
        />
        <path
          d="m28.339 70.234h5.1797v-30.664h-3.5v27.117h-7.2617v17.926l5.582-5.7734zm1.7188 1.6875v7.1289l3.3906-3.6328h5.2188v-40.809l-3.4805 3.3711v33.941zm13.957-1.6914h5.2773v1.4844l5.3008-4.957v-0.043h-7.0976l-3.5039 3.5195h0.0117zm-13.996-50.91v10.184h8.8281v-17.797zm35.098-7.6133-7.2422 7.5742v6.4922l7.2422-6.8594zm-53.074 0h53.074l7.1602-6.9141h-66.992v20.98l6.7578-6.7305zm46.098 22.902-3.7383 3.3711v3.5156l3.7383-3.5117zm-10.648 37.297v-0.0117h-5.2305l-3.5938 3.5234h5.4453zm-27.945-33.922-3.6875 3.5117v-10.344h45.707l-3.4258 3.457h-38.594z"
          fill={colors.primaryColor}
        />
        <path
          d="m28.339 70.234v10.566l1.7188-1.75v-7.1289h5.1289v-33.941l-1.668 1.5898v30.664zm19.152 1.6602h8e-3l1.7852-1.6602 4e-3 -4e-3h-5.2734l-0.0117 4e-3 -1.7383 1.6602h-4e-3zm6.9102-33.914-1.6875 1.5898v3.6445l1.6875-1.7188zm-31.645 28.707h7.2617v-27.117h-1.7461v25.516h-7.1797v21.172l1.6641-1.6445zm16.09-37.184h-8.8281v-10.184l-1.6992 1.7227v8.4609h-14.074v13.711l1.6133-1.7188v-10.344h45.707l1.6367-1.6484zm-35.574-1.8633 2.0117-1.8672v-20.98h66.992l1.707-1.8672h-70.711zm54.602-1.8672-1.5976 1.8672v-6.5977l1.5976-1.7617z"
          fill="#ffffff"
        />
        <path
          d="m60.55 86.586v-0.55469h0.36719c0.19141 0 0.39063 0.0391 0.39063 0.26172 0 0.27344-0.20313 0.29297-0.4336 0.29297zm0 0.22656h0.3125l0.46875 0.77735h0.30469l-0.51172-0.78907c0.26953-0.0273 0.46875-0.17187 0.46875-0.48828 0-0.35547-0.21093-0.51172-0.63281-0.51172h-0.67969v1.7891h0.26953zm0.32813 1.4336c0.84766 0 1.5664-0.65625 1.5664-1.5547 0-0.89063-0.71875-1.5469-1.5664-1.5469-0.85156 0-1.5742 0.65625-1.5742 1.5469 0 0.89844 0.72266 1.5547 1.5742 1.5547m-1.2656-1.5547c0-0.73438 0.55469-1.2891 1.2656-1.2891 0.69922 0 1.2539 0.55468 1.2539 1.2891 0 0.74609-0.55469 1.2969-1.2539 1.2969-0.71094 0-1.2656-0.55078-1.2656-1.2969"
          fill="#ffffff"
        />
      </g>
    </SvgIcon>
  );
}

export default TexasTechLogo;
