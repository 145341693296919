import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import AuthApp from './components/AuthApp';
import AccountProvider from './contexts/account';
import FlashMessageProvider from './contexts/flashMessage';
import ToggleProvider from './contexts/toggle';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FlashMessageProvider>
          <AccountProvider>
            <ToggleProvider>
              <AuthApp />
            </ToggleProvider>
          </AccountProvider>
        </FlashMessageProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
