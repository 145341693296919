import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function UtsaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 115 53" {...restProps}>
      <g>
        <path
          d="M18.4676 30.6174C18.2768 30.4482 18.124 30.2401 18.0195 30.0069C17.9149 29.7737 17.8611 29.5207 17.8614 29.2649C17.8746 28.88 17.9975 28.507 18.2155 28.1903C18.4335 27.8737 18.7373 27.6267 19.0909 27.4788C14.6512 25.3921 7.13775 23.6875 0 22.846C10.8646 18.5524 20.1325 18.7327 20.1325 18.7327C15.0653 16.8865 2.7663 16.3541 2.7663 16.3541C10.8774 13.7779 18.4548 11.7084 24.6363 11.9789C19.6288 10.21 11.2317 8.67287 11.2317 8.67287C11.2317 8.67287 26.5873 4.25906 35.3387 5.58149L22.7665 0.429187C58.2247 -2.2543 69.6229 7.33327 76.8802 18.5953L77.2175 19.1235L81.4224 17.187L80.7778 22.1161L81.495 22.2062C92.8163 23.6789 108.39 26.9292 114.097 41.888C95.3137 38.1741 73.7809 44.0134 55.1852 52.9741C41.7208 42.6437 28.3034 37.968 8.20073 34.3485L18.4676 30.6174Z"
          fill="#ffffff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9789 28.7327H20.3887V28.3076H19.0739V28.7327H19.4837V30.2311H19.9789V28.7327Z"
          fill={colors.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5039 30.2311H20.9991L21.1613 29.1234H21.1656L21.601 30.2311H21.7974L22.2585 29.1234H22.2627L22.4036 30.2311H22.8988L22.6085 28.3076H22.1133L21.7035 29.3338L21.3193 28.3076H20.8284L20.5039 30.2311Z"
          fill={colors.primaryColor}
        />
        <path
          d="M14.4463 33.8806L25.5456 30.2353C20.9522 23.6146 8.25195 22.421 8.25195 22.421C18.5914 19.2051 28.7431 21.4979 28.7431 21.4979C23.9063 16.2855 13.6949 15.3752 13.6949 15.3752C28.0558 11.3092 37.264 17.1356 37.264 17.1356C33.6183 11.6699 20.1667 8.66864 20.1667 8.66864C37.8659 4.45234 46.9589 11.4981 46.9589 11.4981C43.2705 6.38445 32.4016 2.14668 32.4016 2.14668C63.1383 0.837141 73.3839 15.6071 76.3124 21.7254L78.9293 20.5189L78.4256 23.9109C90.161 25.4523 104.501 27.8738 110.294 39.3291C93.3414 36.753 73.354 42.3604 55.42 50.6642C50.3527 46.903 34.9886 36.4954 14.4377 33.8891"
          fill={colors.primaryColor}
        />
        <path
          d="M69.9431 28.3374C68.0647 29.969 64.6495 31.7723 61.2984 29.4065C58.5833 27.5087 59.8085 24.2542 61.5972 23.2495L64.4788 25.0099C64.184 25.2665 63.9381 25.575 63.7531 25.9201C63.0487 27.2812 63.7061 28.711 65.2259 29.1103C65.9175 29.272 66.6406 29.2314 67.31 28.9933C67.9794 28.7551 68.5672 28.3294 69.0039 27.7664L69.9431 28.3374Z"
          fill="#ffffff"
        />
        <path
          d="M50.3271 16.1738C50.3271 16.1738 61.5418 14.8385 68.5856 20.038L70.1865 16.7835L72.321 26.8777C72.321 26.8777 67.4031 19.4197 50.3442 16.1437"
          fill={colors.secondaryColor}
        />
        <path
          d="M64.7136 35.259C66.5621 33.739 71.4287 30.2827 80.5089 27.2085C90.8014 28.5996 99.7193 30.9353 104.458 35.9588C104.458 35.9588 80.6455 33.2453 64.7136 35.259Z"
          fill="#ffffff"
        />
        <path
          d="M80.1972 28.767C79.1129 29.0547 75.7105 30.7292 75.0745 31.0855C75.0745 31.0855 81.7469 29.9048 87.5228 30.2268C85.217 29.2058 82.716 28.7074 80.1972 28.767"
          fill={colors.primaryColor}
        />
        <path
          d="M64.5984 37.3799H85.8281C80.7053 38.1441 76.2527 39.3034 71.1342 40.7546C68.7293 40.1473 66.494 38.996 64.5984 37.3885"
          fill="#ffffff"
        />
        <path
          d="M56.0006 25.2203C54.7882 29.7886 58.2205 32.09 62.831 32.6181C60.4276 33.8547 54.9718 34.4901 51.4584 31.7594C49.7081 30.4026 49.5417 27.7492 49.8533 27.1567C46.9931 27.191 39.6077 27.7535 30.5233 33.0518C36.3249 25.0743 48.9611 19.8919 58.434 22.0945C57.2088 23.0649 56.244 24.2156 55.9793 25.2117"
          fill={colors.secondaryColor}
        />
        <path d="M51.3987 43.8202L59.7702 38.7795L56.9441 37.1479L51.3987 43.8202Z" fill={colors.secondaryColor} />
        <path d="M54.4979 46.7997L66.0797 42.7508L61.324 39.8784L54.4979 46.7997Z" fill={colors.secondaryColor} />
      </g>
    </SvgIcon>
  );
}

export default UtsaLogo;
