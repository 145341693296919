import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function OregonLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 284 235" {...restProps}>
      <g transform="translate(-200.51 -300.71)">
        <path
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          d="m342.86 302.48c-39.671 0-76.176 7.711-97.281 21.938-23.728 15.994-43 29.994-43 93.656s19.272 77.662 43 93.656c21.106 14.227 57.61 21.938 97.281 21.938s76.176-7.711 97.281-21.938c23.728-15.994 43-29.994 43-93.656s-19.272-77.662-43-93.656c-21.106-14.227-57.61-21.938-97.281-21.938zm0 18.656c29.243 0 51.782 15.022 61.094 30.406 9.3121 15.385 8.4062 33.866 8.4062 66.531s0.90583 51.147-8.4062 66.531c-9.3121 15.385-31.85 30.406-61.094 30.406-29.243 0-51.782-15.022-61.094-30.406-9.3121-15.385-8.4062-33.866-8.4062-66.531s-0.90583-51.147 8.4062-66.531c9.3121-15.385 31.85-30.406 61.094-30.406z"
        />
      </g>
    </SvgIcon>
  );
}

export default OregonLogo;
