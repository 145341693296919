import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: 'rgba(0, 0, 0, 0.10)',
    },
  },
  typography: {
    h1: { '@media (max-width:899.95px)': { fontSize: '4.8rem' } },
    h2: { '@media (max-width:899.95px)': { fontSize: '3rem' } },
    h3: { '@media (max-width:899.95px)': { fontSize: '2.4rem' } },
    h4: { '@media (max-width:899.95px)': { fontSize: '1.7rem' } },
    h5: { '@media (max-width:899.95px)': { fontSize: '1.2rem' } },
    h6: { '@media (max-width:899.95px)': { fontSize: '1rem' } },
    subtitle1: { '@media (max-width:899.95px)': { fontSize: '0.8rem' } },
    subtitle2: { '@media (max-width:899.95px)': { fontSize: '0.7rem' } },
    body1: { '@media (max-width:899.95px)': { fontSize: '0.8rem' } },
    body2: { '@media (max-width:899.95px)': { fontSize: '0.7rem' } },
    button: { '@media (max-width:899.95px)': { fontSize: '0.7rem' } },
    caption: { '@media (max-width:899.95px)': { fontSize: '0.6rem' } },
    overline: { '@media (max-width:899.95px)': { fontSize: '0.6rem' } },
  },
});

export default theme;
