import React, { useMemo } from 'react';
import { Box, Breadcrumbs, CircularProgress, Link as MuiLink, Stack, Typography } from '@mui/material';
import currency from 'currency.js';
import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { useAccountContext } from '../../../../../contexts/account';
import { FetchStockMarketTeamNameQuery, FetchStockMarketTeamNameQueryVariables } from '../../../../../generated/hooks';
import { useTabs } from '../../../../../utils';
import { TeamLogoComponent } from '../../../../logos';
import TeamActionButtons from '../TeamActionButtons';
import Owners from './Owners';
import PriceHistory from './PriceHistory';
import Schedule from './Schedule';

const fetchStockMarketTeamName = gql`
  query fetchStockMarketTeamName($leagueId: UUID!, $teamId: UUID!, $userId: UUID!) {
    league(id: $leagueId) {
      user(id: $userId) {
        cashBalance
        position(teamId: $teamId) {
          costBasis
          quantity
        }
      }
      team(id: $teamId) {
        id
        name
        stockPurchasePrices
        styles {
          primaryColor
          secondaryColor
          logoComponent
        }
      }
    }
  }
`;

const tabs = [
  {
    route: 'owners',
    label: 'Owners',
    Component: Owners,
  },
  {
    route: 'price-history',
    label: 'Price History',
    Component: PriceHistory,
  },
  {
    route: 'schedule',
    label: 'Schedule',
    Component: Schedule,
  },
];

function TeamPageInner() {
  const { userId } = useAccountContext();
  const { leagueId, teamId } = useParams<{ leagueId: string; teamId: string }>();
  const [{ data, fetching, stale }, refetch] = useQuery<
    FetchStockMarketTeamNameQuery,
    FetchStockMarketTeamNameQueryVariables
  >({
    query: fetchStockMarketTeamName,
    variables: { leagueId, teamId, userId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const { TabsComponent } = useTabs(tabs, `/league/${leagueId}/team/${teamId}/:teamPage`, 'teamPage');

  if (fetching || stale) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Breadcrumbs sx={{ mb: 2 }}>
        <MuiLink
          component={Link}
          to={`/league/${leagueId}`}
          sx={theme => ({ color: 'text.secondary', textDecorationColor: theme.palette.text.secondary })}
        >
          League Home
        </MuiLink>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <TeamLogoComponent styles={data?.league.team.styles} />
          <Typography variant="h5" sx={{ color: 'text.primary' }}>
            {data?.league.team.name}
          </Typography>
        </Stack>
      </Breadcrumbs>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="subtitle2">Share Price:</Typography>
        <Typography variant="body2">{currency(data?.league.team.stockPurchasePrices[0]).format()}</Typography>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <TeamActionButtons
          cashBalance={currency(data.league.user.cashBalance)}
          costBasis={currency(data.league.user.position?.costBasis || 0)}
          leagueId={leagueId}
          shareQuantity={data.league.user.position?.quantity || 0}
          team={data.league.team}
          refetch={() => refetch({ requestPolicy: 'network-only' })}
        />
      </Box>
      <TabsComponent />
    </>
  );
}

export default function TeamPage() {
  const { leagueId, teamId } = useParams<{ leagueId: string; teamId: string }>();
  return (
    <Routes>
      <Route path="/:teamPage" element={<TeamPageInner />} />
      <Route path="" element={<Navigate to={`/league/${leagueId}/team/${teamId}/owners`} replace />} />
      <Route path="*" element={<Navigate to={`/league/${leagueId}/team/${teamId}/owners`} replace />} />
    </Routes>
  );
}
