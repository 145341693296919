import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function NorthwesternLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 152 234.01286" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -339.55 573.75)">
        <g transform="matrix(4.2615 0 0 4.2615 -1081 -1188.1)">
          <path
            d="m335.41 385.4v-5.543l6.18-10.382v-12.289l-16.617 28.214h-6.441v-41.613h9.338v5.544l-6.179 10.381v12.289l16.616-28.214h6.442v41.613h-9.339z"
            fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default NorthwesternLogo;
