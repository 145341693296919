import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function PennStateLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 121.195 84.184998" {...restProps}>
      <g transform="translate(-.30613 .15306)">
        <path
          fill="#ffffff"
          d="m60.946 0.81305c-32.915 0-59.598 18.398-59.598 41.091-0.0001 22.695 26.683 41.092 59.598 41.092 32.913 0 59.594-18.397 59.594-41.092 0-22.693-26.681-41.091-59.594-41.091z"
        />
        <path
          fill={colors.primaryColor}
          d="m60.946 2.6627c-31.892 0-57.745 17.569-57.745 39.242 0.0004 8.58 4.0524 16.517 10.926 22.975-0.542-0.691-5.9705-7.871-5.9705-18.836 0-27.029 33.596-33.043 33.596-33.043h0.682c-3.798 2.858-25.795 12.376-25.795 34.719 0 25.374 27.129 31.623 30.266 32.26 4.495 0.763 9.198 1.169 14.041 1.169 31.891 0 57.744-17.57 57.744-39.244 0-21.673-25.853-39.242-57.744-39.242z"
        />
        <path
          fill="#ffffff"
          d="m53.614 5.1098c-2.782 0.965-4.001 4.0028-5.051 6.4136-2.214 5.933-3.688 12.26-4.483 18.729 7.208-5.08 15.521-7.491 24.432-8.457 5.135-0.226 10.868-1.134 15.662 0.398 2.668 1.079 3.518 4.314 6.016 5.789 3.972 2.582 8.056 4.854 11.92 7.719 2.38 2.1 6.04 4.17 6.13 7.69-0.86 3.078-1.93 5.646-4.91 6.668l-0.14-0.2c0.62-2.128 0.39-4.538-0.63-6.412-3.716-5.817-9.931-8.741-15.862-10.812-1.532-0.368-2.659-0.638-4.626-1.106 0.683-0.936 0.597-2.271 0.2-3.32-0.284-0.482-0.623-1.22-1.36-1.021l-10.558 4.767c4.795-0.17 9.166 1.107 13.365 2.582 5.221 2.469 12.57 6.441 13.139 13.139 0.283 3.264-1.448 5.928-4.086 7.773-6.812 3.802-13.99 6.754-21.395 9.194-10.755 2.127-24.942 3.803-32.943-5.334-0.37-0.453-0.681-1.049-1.108-1.362-0.226 2.697 0.512 5.135 1.702 7.291 1.873 2.895 4.881 4.457 8.115 4.768 8.995 0.54 16.857-2.468 25-4.483l16.939 2.639c6.874 0.863 7.946-3.548 9.28-5.648 0.935-2.043 2.038-4.142 2.638-6.186-5.817 2.326-11.747 4.371-17.707 6.385l-0.254-0.213c-0.2-0.199-0.595-0.355-0.283-0.523 4.226-1.619 8.34-3.521 12.455-5.45 4.17-2.297 8.569-4 12.339-7.293 1.92-2.661 3.78-6.043 1.96-9.332-5.07-6.755-12.68-9.196-18.865-14.218-2.071-1.9-3.153-4.74-5.565-5.932-7.462-2.894-15.065-5.361-23.095-6.809-2.299-2.81-4.086-7.6911-8.371-7.8336z"
        />
        <path
          fill={colors.primaryColor}
          d="m53.953 7.4947c-1.133 0.425-1.531 1.6454-2.183 2.5258-2.269 4.653-3.633 9.676-4.373 14.982h0.144c2.639-4.484 6.783-9.79 11.778-11.861 0.055-0.483-0.344-0.765-0.541-1.162-1.448-1.56-2.356-4.3148-4.825-4.4848z"
        />
      </g>
    </SvgIcon>
  );
}

export default PennStateLogo;
