import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function GeorgiaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 378 245" {...restProps}>
      <ellipse
        cx="189.1"
        cy="123.2"
        rx="183.8"
        ry="117.5"
        fill="#FFFFFF"
        stroke={colors.primaryColor}
        strokeWidth="7.5"
      />
      <path
        d="M 73.9,44.9 C 115.3,21.7 164,14.3 211,17.3 c 34.3,2.6 68.2,12.4 97.6,30.4 15.9,9.9 30.7,22.4 39.7,39.2 h-83.9 c-19.8,-19.3 -46.3,-32.5 -74.2,-33.2 -20.7,0.4 -41.2,6.5 -58.3,17.9 -12.8,8.5 -23.9,20.4 -28,35.4 -4.3,14.8 0.5,30.7 8.3,43.3 13.4,20.8 37.3,33.3 61.6,35.5 17.3,1.5 34.9,-0.1 51.4,-5.5 18,-5.6 34.3,-18.8 41.8,-36.3 h-111.4 v-33.3 h201.5 c2,-0.4 1.6,2.6 1.9,3.9 0.3,8.6 0.5,17.4 -2.1,25.8 -6.5,23.5 -24.4,42.2 -44.6,55.1 -39.7,25.4 -88,33.9 -134.6,33.4 -25,-1 -50.3,-4.8 -74.1,-13.6 C 82.2,207.3 62,195.4 45.7,179.2 32.6,165.3 21.6,148.5 19.2,129.2 18.3,118.9 18.7,108.3 22.7,98.6 32,74.9 52.4,57.5 73.9,44.9z"
        fill={colors.secondaryColor}
      />
    </SvgIcon>
  );
}

export default GeorgiaLogo;
