import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function VirginiaTechLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 206.1925 98.1875" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -127.51 602.7)">
        <g transform="translate(.77443 .77443)">
          <path
            d="m156.28 480.56-13.223-23.585-12.685 23.585h-28.362l41.172-76.95 28.291 52.284h24.631l-27.753-51.543h28.597l27.545 51.751h27.748l13.123 24.458h-109.08z"
            fill={variant === 'third' ? colors.primaryColor : colors.secondaryColor}
          />
          <path
            d="m129.27 478.73 13.748-25.568 14.338 25.568h104.93l-11.153-20.787h-27.75l-27.545-51.753h-24.416l27.75 51.544h-28.801l-27.187-50.245-38.113 71.241h24.195z"
            fill={variant === 'main' ? '#ffffff' : variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
          />
          <path
            d="m108.14 476.89h20.034l14.814-27.544 15.446 27.544h100.79l-9.184-17.112h-27.752l-27.547-51.75h-20.24l27.751 51.54h-32.97l-26.082-48.203-35.06 65.525z"
            fill={variant === 'main' ? colors.primaryColor : '#ffffff'}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default VirginiaTechLogo;
