import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function ToledoLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 243.20595 142.8685" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -66.875 524)">
        <g transform="translate(.45672 .68509)">
          <path
            d="m188.38 11.748s-2.2326 2.2324-9.9238 6.2012c-7.6912 3.97-16.373 9.9238-16.373 9.9238l-11.66 5.707-11.908 3.2246-3.2246 9.9238s4.2166-0.24844 16.869-5.2109c12.652-4.9612 24.808-12.899 28.033-15.133 3.225-2.2325 8.6836-7.1953 8.6836-7.1953l-0.4961-7.4414zm-85.529 7.6113c-2.0216 0.04749-4.8979 1.5664-6.6348 1.5664-1.985 0-9.1787 3.1016-10.42 3.1016-1.24 0-9.4273 3.4745-10.791 3.9707-1.365 0.495-7.1953 3.7207-7.1953 3.7207s4.8373 2.1094 6.8223 2.1094 4.9629 1.2402 4.9629 1.2402l3.0996-2.6055 4.5898-4.2168s6.6988-3.4745 8.1875-3.9707c1.4875-0.495 8.4356-2.9766 8.4356-2.9766s1.735-1.3651-0.25-1.8613c-0.24813-0.06203-0.51784-0.08491-0.80664-0.07813z"
            transform="matrix(.8 0 0 -.8 53.5 419.2)"
            fill={variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
          />
          <path
            d="m186.39 2.002c-6.4614 0.042969-12.917 0.73984-20.998 2.1055-13.36 2.2575-20.881 4.6077-33.184 10.285-15.66 0.755-24.595 2.1987-39.678 6.4824-12.894 3.6625-20.053 6.4655-31.992 12.561 4.3425 0.09 6.8187 0.42438 11.045 1.4219 4.01 0.94625 6.2451 1.7263 9.9863 3.4551 0.98625-5.0525 12.095-11.883 18.9-14.225 9.1938-3.1625 13.396-4.0714 23.678-5.9414-5.73 3.0125-8.8518 4.9709-14.092 8.7734-5.6862 4.1238-8.7606 6.6897-13.734 11.648l1.8027 2.4922c13.215-10.691 21.846-15.736 37.691-21.891 18.5-7.1875 30.132-9.4095 49.979-9.582-7.5475 5.8275-12.045 8.8609-20.326 13.586-7.8562 4.48-12.288 6.708-20.676 10.092 1.255-2.2812 2.672-4.2587 3.8144-7.1074l-26.219 10.611c-9.605 3.8875-15.109 5.8626-24.768 9.6113 0 0-6.4347 11.618-11.18 18.049 7.8325-3.5025 12.198-5.6059 19.846-9.4922 7.69-3.9075 11.972-6.2329 19.453-10.525-6.9075 7.6225-10.184 11.202-19.166 18.121-8.53 6.5688-15.661 10.34-23.854 14.523-3.8212 2.1738-3.9297 3.4043-3.9297 3.4043 6.1525-2.5713 10.888-4.8404 17.002-8.0254 14.67-7.6412 21.596-12.619 33.736-22.012 18.884-4.1512 29.503-8.5389 45.838-18.879 13.968-8.8412 21.289-15.184 32.053-27.73-8.1006-1.2462-14.568-1.8555-21.029-1.8125zm-1.1191 14.137-0.87695 3.2637c-7.5738 5.5688-12.078 8.4445-20.316 12.971-9.84 5.4062-15.669 8.0593-26.24 11.842 1.44-1.7225 2.5134-3.0861 3.5234-4.5273 10.039-3.78 13.958-5.6841 23.393-10.789 8.2875-4.4838 12.815-7.3298 20.518-12.76zm-83.16 4.291c-5.94 1.7412-10.477 3.5602-15.221 6.4902-4.1362 2.5538-5.1748 3.7844-7.9473 6.7656-1.4075-0.63375-2.2373-0.92797-3.7285-1.3242-1.8638-0.49625-2.9585-0.65383-4.8848-0.76758 4.3912-2.3288 6.9421-3.5314 11.568-5.3477 7.635-2.9975 12.969-4.6564 20.213-5.8164zm-13.524 21.07-88.586 39.092 79.1-27.039 9.486-12.053z"
            transform="matrix(.8 0 0 -.8 53.5 419.2)"
            fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          />
          <path
            d="m154.87 54.658-28.393 1.7031-8.6445 64.492 29.461-1.7676 1.5527-11.58-14.686 0.88086 2.1602-16.123 12.641-0.75781 1.4785-11.047-12.639 0.75977 1.4687-10.705-5.6348-3.1094 6.1016-0.36719 13.582-0.79883 1.5508-11.58zm-101.08 0.90625-42.873 12.227 0.43359 14.004 12.496-3.5625 1.9824 63.984 17.883-5.0996l-1.878-60.446-6.334-1.768 6.225-1.775 12.494-3.563-0.434-14.004zm109.05 0.684-13.52 63.252l18.871 0.0684c13.541 0.05 18.437-2.8149 22.881-23.607l3.3789-15.812c4.4438-20.793 0.79906-23.78-12.742-23.83l-18.871-0.07031zm-56.21 1.397-15.007 2.605l-3.3223 64.584 28.057-3.7168 0.62305-11.783-13.023 1.5586-6.2227 0.89453 6.4082-4.4316 2.4902-49.711zm112.05 1.5391c-8.4867-0.04525-13.934 2.7802-16.553 11.186l-12.066 38.75c-3.2212 10.344 2.595 13.888 14.781 15.396 12.186 1.5075 19.695-0.38546 22.916-10.73l12.066-38.748c3.2212-10.345-2.595-13.888-14.781-15.396-2.2847-0.28289-4.4048-0.44659-6.3633-0.45703zm-141.85 4.314c-2.733-0.01375-6.0496 0.53789-9.9121 1.4941-12.36 3.0612-19.117 7.4912-19.117 17.982v39.303c0 10.494 6.7572 11.576 19.117 8.5156 12.36-3.0612 19.119-7.4899 19.119-17.982v-39.303c0-7.2136-3.1944-9.9795-9.207-10.01zm92.176 3.6152 6.0332 0.02148 3.8301 0.01367c3.145 0.0125 3.5439 1.4134 2.5352 6.1309l-6.1602 28.83c-1.0088 4.7175-2.0054 6.1128-5.1504 6.1016l-3.8438-0.0156 8.0156-37.65-5.2598-3.4316zm42.979 2.207 9.6797 1.1992c2.7188 0.33625 3.5812 1.072 2.8438 3.4395l-11.576 37.172c-0.7375 2.3662-1.9785 2.8499-4.6973 2.5137-2.7175-0.33625-3.5793-1.0798-2.8418-3.4473l11.45-36.762-4.86-4.118zm-143.31 6.403c1.463-0.07664 2.0625 0.57109 2.0625 2.3711v37.703c0 2.4012-1.068 3.2882-3.8242 3.9707s-3.8223 0.32187-3.8223-2.0781v-37.283l-5.9941-1.9512 9.8164-2.4297c0.68906-0.17062 1.274-0.27719 1.7617-0.30274z"
            transform="matrix(.8 0 0 -.8 53.5 419.2)"
            stroke={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
            fill={variant === 'main' ? colors.secondaryColor : '#ffffff'}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ToledoLogo;
