import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import currency from 'currency.js';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { useAccountContext } from '../../../../contexts/account';
import {
  FetchStockMarketLeagueTeamsQuery,
  FetchStockMarketLeagueTeamsQueryVariables,
} from '../../../../generated/hooks';
import { TeamLogoComponent } from '../../../logos';
import TeamActionButtons from './TeamActionButtons';

const fetchStockMarketLeagueTeams = gql`
  query fetchStockMarketLeagueTeams($leagueId: UUID!, $userId: UUID!) {
    league(id: $leagueId) {
      user(id: $userId) {
        cashBalance
        positions {
          costBasis
          quantity
          team {
            id
          }
        }
      }
      teams {
        id
        name
        positions {
          quantity
        }
        styles {
          primaryColor
          secondaryColor
          logoComponent
        }
        stockPurchasePrices
      }
    }
  }
`;

export default function TeamPrices() {
  const { userId } = useAccountContext();
  const { leagueId } = useParams<{ leagueId: string }>();
  const [{ data, fetching, stale }, refetch] = useQuery<
    FetchStockMarketLeagueTeamsQuery,
    FetchStockMarketLeagueTeamsQueryVariables
  >({
    query: fetchStockMarketLeagueTeams,
    variables: { leagueId, userId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  if (fetching || stale) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const teams = data.league.teams
    .map(team => {
      const sharesOwned = team.positions.reduce((sum, position) => sum + position.quantity, 0);
      const sharePrice = currency(team.stockPurchasePrices[0]);
      const totalValue = sharePrice.multiply(sharesOwned);
      return {
        ...team,
        sharesOwned,
        sharePrice,
        totalValue,
      };
    })
    .sort((left, right) => {
      if (left.sharePrice.intValue !== right.sharePrice.intValue) {
        return right.sharePrice.intValue - left.sharePrice.intValue;
      }
      return left.name.localeCompare(right.name);
    });

  return (
    <Table
      sx={{
        maxWidth: 1200,
        '& .MuiTableCell-sizeSmall': {
          px: 0.5,
        },
        '& .MuiTableCell-sizeSmall:first-of-type': {
          pl: 0,
        },
        '& .MuiTableCell-sizeSmall:last-of-type': {
          pr: 0,
        },
        '& .team-row .MuiTableCell-sizeSmall:not(.team-cell)': {
          borderBottom: 0,
        },
        '& .team-actions-row .MuiTableCell-root': {
          pt: 0,
        },
      }}
      size={isMdDown ? 'small' : 'medium'}
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Shares Owned</TableCell>
          <TableCell align="right">Share Price</TableCell>
          <TableCell align="right">Total Value</TableCell>
          {!isMdDown && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        {teams.map(team => {
          const userPosition = data.league.user.positions.find(position => position.team.id === team.id);
          const costBasis = currency(userPosition?.costBasis || 0);
          const shareQuantity = userPosition?.quantity || 0;
          return (
            <React.Fragment key={team.id}>
              <TableRow className="team-row">
                <TableCell rowSpan={isMdDown ? 2 : 1} className="team-cell">
                  <MuiLink component={Link} to={`/league/${leagueId}/team/${team.id}`}>
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <TeamLogoComponent styles={team.styles} />
                      <Box>{team.name}</Box>
                    </Stack>
                  </MuiLink>
                </TableCell>
                <TableCell align="right">{team.sharesOwned}</TableCell>
                <TableCell align="right">{team.sharePrice.format()}</TableCell>
                <TableCell align="right">{team.totalValue.format()}</TableCell>
                {!isMdDown && (
                  <TableCell>
                    <TeamActionButtons
                      cashBalance={currency(data.league.user.cashBalance)}
                      costBasis={costBasis}
                      leagueId={leagueId}
                      shareQuantity={shareQuantity}
                      team={team}
                      refetch={() => refetch({ requestPolicy: 'network-only' })}
                    />
                  </TableCell>
                )}
              </TableRow>
              {isMdDown && (
                <TableRow className="team-actions-row">
                  <TableCell colSpan={3}>
                    <TeamActionButtons
                      alignRight
                      cashBalance={currency(data.league.user.cashBalance)}
                      costBasis={costBasis}
                      leagueId={leagueId}
                      shareQuantity={shareQuantity}
                      team={team}
                      refetch={() => refetch({ requestPolicy: 'network-only' })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
}
