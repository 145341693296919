import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MarylandLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 146.34501 159.69648" {...restProps}>
      <g>
        <path
          d="m3.041 1.1328l0.0039 0.002 10.559 28.133-0.008 83.492-11.502 25.24h43.996v-52.023l17.908 40.353h17.479l17.894-40.338v52.008h44.619l-12.13-25.27v-83.609l10.38-27.984-41.26-0.0003-28.265 65.277-28.238-65.281-41.436-0.0002zm-2.1601 144.92h0.00391l3.8027 6.33-3.8164 6.44h144.34l-3.81-6.45 3.81-6.32h-144.33z"
          transform="translate(0 -.0045015)"
          fill="#000000"
        />
        <path
          d="m7.6562 4.3281l-0.0019 0.0039h0.0039l-0.002-0.0039zm0.002 0.0039l9.1368 24.354-0.006 84.774-9.7285 21.35h35.836v-63.9l23.178 52.23h13.328l23.158-52.22v63.89h36.35l-10.24-21.35v-84.909l8.98-24.219h-34.58l-30.353 70.129-30.336-70.129h-34.723zm2.6348 143.93v8.35h6.648v-4.21l-6.406-4.14h-0.24-0.002zm8.008 0v3.98l6.523 4.37h0.129v-8.35h-6.652zm13.545 0l1.136 0.74v-0.74h-1.136zm10.48 0v6.98l2.043 1.37h5.111l0.01-8.35h-7.164zm62.394 0v8.35h6.65v-4.21l-6.41-4.14h-0.24zm8.01 0v3.98l6.51 4.37h0.13v-8.35h-6.64zm13.53 0l1.14 0.74v-0.74h-1.14zm10.48 0v6.98l2.04 1.37h2.38l-2.9-4.25 2.88-4.1h-4.4zm-102.43 2.68v5.67h6.649v-1.29l-6.649-4.38zm94.416 0v5.67h6.65v-1.29l-6.65-4.38z"
          transform="translate(0 -.0045015)"
          fill={colors.secondaryColor}
        />
        <path
          d="m11.006 6.6445v0.0059l8.113 21.616-0.008 85.694-8.441 18.52h29.904v-72.539l27.01 60.869h10.305l27.001-60.857v72.527h30.33l-8.87-18.5v-85.855l7.96-21.48-29.71-0.0005-31.883 73.654-31.862-73.654-29.849 0.0005zm44.158 141.63c-0.199 0.42-0.357 0.9-0.467 1.42-2.831 0.17-4.88 1.32-4.886 2.71h23.181v-4.13h-3.527v0.98h-8.979c-0.099-0.35-0.228-0.68-0.369-0.98h-4.953zm17.828 4.13v4.21h3.524v-1.04h9.082c0.103 0.38 0.24 0.72 0.392 1.04h4.901c0.21-0.44 0.378-0.93 0.496-1.47 2.836-0.18 4.884-1.32 4.884-2.73v-0.01h-23.279zm23.279 0h0.391v-4.13h-5.744c0.198 0.42 0.356 0.9 0.469 1.42 2.83 0.17 4.875 1.32 4.884 2.71zm-46.46 0h-0.329l-0.005 4.21h5.714c-0.21-0.44-0.378-0.93-0.494-1.47-2.836-0.18-4.886-1.32-4.886-2.73v-0.01zm26.705-4.13v0.98h9.082c0.097-0.35 0.225-0.68 0.369-0.98h-9.449-0.002zm-16.03 7.3c-0.104 0.38-0.241 0.72-0.392 1.04h9.371v-1.04h-8.979z"
          transform="translate(0 -.0045015)"
          fill={colors.primaryColor}
        />
        <path
          fill="#ffffff"
          d="m49.492 148.26-0.0058 4.1396h0.3291c0.0063-1.3969 2.0545-2.5488 4.8858-2.7188 0.11-0.5175 0.26902-0.99525 0.46777-1.4209h-5.677zm0.32324 4.1396c0 0.001-0.001 0.002-0.001 0.009 0 1.4019 2.0504 2.5502 4.8867 2.7246 0.11562 0.5419 0.28414 1.0362 0.49414 1.4736h4.9024c0.15126-0.31938 0.2882-0.66435 0.39258-1.0381h8.9785v1.0381h3.5283v-4.207h-23.182zm23.182 0h23.28c-0.0094-1.3931-2.0532-2.5488-4.8838-2.7188-0.11312-0.5175-0.2716-0.99525-0.46972-1.4209h-4.9502c-0.14374 0.30562-0.27164 0.63105-0.36914 0.9873h-9.082v-0.9873h-3.5244v4.1396zm23.28 0v0.009c0 1.4019-2.0476 2.5502-4.8838 2.7246-0.11812 0.5419-0.2861 1.0362-0.4961 1.4736h5.7715v-4.207h-0.3916zm-36.156-4.1396c0.14125 0.30562 0.26977 0.63105 0.36914 0.9873h8.9785v-0.9873h-9.3475zm16.4 7.3085v1.0381h9.4735c-0.1525-0.31937-0.28786-0.66435-0.3916-1.0381h-9.082z"
        />
      </g>
    </SvgIcon>
  );
}

export default MarylandLogo;
