import React, { useMemo } from 'react';
import { Box, Breadcrumbs, CircularProgress, Link as MuiLink, Typography } from '@mui/material';
import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { FetchStockMarketUserNameQuery, FetchStockMarketUserNameQueryVariables } from '../../../../../generated/hooks';
import { useTabs } from '../../../../../utils';
import TeamsOwned from './TeamsOwned';
import TransactionHistory from './TransactionHistory';

const fetchStockMarketUserName = gql`
  query fetchStockMarketUserName($leagueId: UUID!, $userId: UUID!) {
    league(id: $leagueId) {
      user(id: $userId) {
        fullName
      }
    }
  }
`;

const tabs = [
  {
    route: 'teams-owned',
    label: 'Teams Owned',
    Component: TeamsOwned,
  },
  {
    route: 'transaction-history',
    label: 'Transaction History',
    Component: TransactionHistory,
  },
];

function UserPageInner() {
  const { leagueId, userId } = useParams<{ leagueId: string; userId: string }>();
  const [{ data }] = useQuery<FetchStockMarketUserNameQuery, FetchStockMarketUserNameQueryVariables>({
    query: fetchStockMarketUserName,
    variables: { leagueId, userId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const { TabsComponent } = useTabs(tabs, `/league/${leagueId}/user/${userId}/:userPage`, 'userPage');

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Breadcrumbs sx={{ mb: 2 }}>
        <MuiLink
          component={Link}
          to={`/league/${leagueId}`}
          sx={theme => ({ color: 'text.secondary', textDecorationColor: theme.palette.text.secondary })}
        >
          League Home
        </MuiLink>
        <Typography variant="h5" sx={{ color: 'text.primary' }}>
          {data.league.user.fullName}
        </Typography>
      </Breadcrumbs>
      <TabsComponent />
    </>
  );
}

export default function UserPage() {
  const { leagueId, userId } = useParams<{ leagueId: string; userId: string }>();
  return (
    <Routes>
      <Route path="/:userPage" element={<UserPageInner />} />
      <Route path="" element={<Navigate to={`/league/${leagueId}/user/${userId}/teams-owned`} replace />} />
      <Route path="*" element={<Navigate to={`/league/${leagueId}/user/${userId}/teams-owned`} replace />} />
    </Routes>
  );
}
