import React, { useMemo } from 'react';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { GameStatus } from '../../../../generated/schemas';
import TeamChip from '../../TeamChip';
import BoxScore from './BoxScore';
import PicksTableProps from './PicksTableProps';

function MobilePicksTable({ data, leagueId, userId, weekPickUsers, onCreatePick }: PicksTableProps) {
  const sortedUsers = useMemo(() => {
    return weekPickUsers.slice().sort((left, right) => {
      if (left.id === userId && right.id !== userId) {
        return -1;
      }
      if (left.id !== userId && right.id === userId) {
        return 1;
      }
      return left.fullName.localeCompare(right.fullName);
    });
  }, [weekPickUsers, userId]);
  return (
    <>
      {data.league.games.map(game => (
        <Box key={game.id} sx={{ mt: 2 }}>
          <BoxScore
            game={game}
            userPickedHomeTeam={
              sortedUsers.find(user => user.id === userId).picks.find(pick => pick.gameId === game.id).pickedHomeTeam
            }
            handleCreatePick={pickedHomeTeam => onCreatePick({ leagueId, gameId: game.id, pickedHomeTeam })}
          />
          <Table
            sx={{
              '& .MuiTableCell-root': { px: 1, py: 1, height: 53 },
              '& .MuiTableCell-root:first-of-type': { pl: 0 },
              '& .MuiTableCell-root:last-of-type': { pr: 0 },
            }}
          >
            <TableBody>
              {sortedUsers.map(weekPickUser => {
                const pick = weekPickUser.picks.find(p => p.gameId === game.id);
                const homeTeamCovered =
                  game.status === GameStatus.Completed
                    ? game.homeScore + (game.homeOdds !== null ? Number(game.homeOdds) : 0) > game.awayScore
                    : null;
                return (
                  <TableRow key={weekPickUser.id}>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{weekPickUser.fullName}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {pick.pickHidden ? (
                          <TeamChip team={{ name: 'Pick Hidden' }} />
                        ) : (
                          pick.pickedHomeTeam !== null && (
                            <TeamChip team={pick.pickedHomeTeam ? game.homeTeam : game.awayTeam} />
                          )
                        )}
                        {homeTeamCovered ? (
                          pick.pickedHomeTeam ? (
                            <CheckCircleIcon sx={{ color: 'success.main', ml: 1 }} />
                          ) : (
                            <CancelIcon sx={{ color: 'error.main', ml: 1 }} />
                          )
                        ) : homeTeamCovered === false ? (
                          pick.pickedHomeTeam === false ? (
                            <CheckCircleIcon sx={{ color: 'success.main', ml: 1 }} />
                          ) : (
                            <CancelIcon sx={{ color: 'error.main', ml: 1 }} />
                          )
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      ))}
    </>
  );
}

export default MobilePicksTable;
