export enum MarketCloseReason {
  MarketClose = 'MARKET_CLOSE',
  GameStart = 'GAME_START',
}

export type MarketInfo = {
  open: boolean;
  openDateTime: Date;
  closeDateTime: Date;
  closeReason: MarketCloseReason;
};

export function getMarketInfo(
  marketOpenWindows: { open: string; close: string }[],
  currentGameKickoffDateTime: string,
): MarketInfo {
  if (!marketOpenWindows) {
    return {
      open: false,
      openDateTime: null,
      closeDateTime: null,
      closeReason: null,
    };
  }
  const kickoffDateTime = currentGameKickoffDateTime ? new Date(currentGameKickoffDateTime) : null;
  const parsedMarketOpenWindows = marketOpenWindows.map(window => ({
    open: new Date(window.open),
    close: new Date(window.close),
  }));
  const currentDateTime = new Date();
  let closeReason: MarketCloseReason = null;
  for (const window of parsedMarketOpenWindows) {
    if (window.open < currentDateTime && window.close > currentDateTime) {
      if (!kickoffDateTime || kickoffDateTime > window.close) {
        return {
          open: true,
          openDateTime: window.open,
          closeDateTime: window.close,
          closeReason: MarketCloseReason.MarketClose,
        };
      }
      if (kickoffDateTime > currentDateTime) {
        return {
          open: true,
          openDateTime: window.open,
          closeDateTime: kickoffDateTime,
          closeReason: MarketCloseReason.GameStart,
        };
      }
      closeReason = MarketCloseReason.GameStart;
    } else if (window.open > currentDateTime) {
      return {
        open: false,
        openDateTime: window.open,
        closeDateTime: window.close,
        closeReason: closeReason,
      };
    }
  }
  return {
    open: false,
    openDateTime: null,
    closeDateTime: null,
    closeReason: null,
  };
}
