import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function AirForceLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 200 200" {...restProps}>
      <g>
        <path
          d="M50.12,81.5l10.52-38.47L72.71,81.5H50.12z M104.04,81.5h12.06v32.3h-14.3L70.51,7.91H51.15L21.02,113.8H8.12    v18.09h43.19V113.8h-9.47l4.03-14.2h31.1l3.8,14.2H70.03v18.09h46.08v43.1h-12.06v18.09h43.43v-18.09h-12.06v-43.1h25.02v7.24    h15.68v-32.57h-15.68v7.24h-25.02V81.5h39.5v12.06H193V63.4h-88.96V81.5z"
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default AirForceLogo;
