import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function LsuLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 249.35339 82.281128" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -424 774.12)">
        <path
          d="m340.55 617.76v-0.64375-61.648-0.64375h0.64375 55.584 0.64375v0.64375 12.483 0.64375h-0.64375-33.633v48.522 0.64375h-0.64375-21.308-0.64375zm78.627-0.002c-10.501 0-19.044-6.4421-19.044-14.361v-23.348-0.64375h0.64375 43.978v-6.0609c0-2.5761-2.9439-4.6719-6.5641-4.6719h-37.414-0.64375v-0.64376-12.562-0.64374h0.64375 47.459c10.544 0 19.12 6.4421 19.12 14.361v23.428 0.64375h-0.64375-43.978v5.9812c0 2.5336 2.9688 4.6734 6.4859 4.6734h37.492 0.64375v0.64374 12.561 0.64375h-0.64375-47.536zm95.441 0v-0.64375-43.77c0-2.6199-2.9452-4.75-6.5641-4.75h-15.612v48.367 0.64375h-0.64376-21.086-0.64375v-0.64375-61.495-0.64374h0.64375 47.387c10.503 0 19.047 6.4421 19.047 14.361v47.931 0.64375h-0.64375-21.241-0.64375z"
          stroke={variant === 'main' ? colors.secondaryColor : '#ffffff'}
          strokeMiterlimit="10"
          strokeWidth="1.2874"
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default LsuLogo;
