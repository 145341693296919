import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { MoneyCell } from '../../../money';
import PicksTableProps from './PicksTableProps';

type MobilePayoutsTableProps = {
  currentWeek: number;
  lastWeek: number;
  startWeek: number;
  weekPickUsers: PicksTableProps['weekPickUsers'];
};

function MobilePayoutsTable({ currentWeek, lastWeek, startWeek, weekPickUsers }: MobilePayoutsTableProps) {
  const sortedUsers = useMemo(() => {
    return weekPickUsers
      .slice()
      .sort((left: MobilePayoutsTableProps['weekPickUsers'][0], right: MobilePayoutsTableProps['weekPickUsers'][0]) => {
        // TODO: Convert to use currency.js
        const leftPayout = parseFloat(left.totalPayout);
        const rightPayout = parseFloat(right.totalPayout);
        if (leftPayout === rightPayout) {
          return left.fullName.localeCompare(right.fullName);
        }
        return rightPayout - leftPayout;
      });
  }, [weekPickUsers]);
  const overallWinnerPayoutExists = useMemo(() => {
    return currentWeek === lastWeek && sortedUsers.some(user => Number(user.weekPayouts[currentWeek + 1]) > 0);
  }, [sortedUsers]);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell sx={{ textAlign: 'center' }}>Total</TableCell>
          {startWeek <= currentWeek && (
            <TableCell sx={{ textAlign: 'center' }}>
              {overallWinnerPayoutExists ? (
                'Overall Winner'
              ) : currentWeek === lastWeek ? (
                'Bowls'
              ) : (
                <>Week {currentWeek}</>
              )}
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedUsers.map(user => (
          <TableRow key={user.id}>
            <TableCell>{user.fullName}</TableCell>
            <MoneyCell value={user.totalPayout} />
            {startWeek <= currentWeek && (
              <MoneyCell value={user.weekPayouts[overallWinnerPayoutExists ? currentWeek + 1 : currentWeek]} />
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default MobilePayoutsTable;
