import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import currency from 'currency.js';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { formatDateTime } from '../../../../../dates';
import {
  FetchStockMarketTeamPriceHistoryQuery,
  FetchStockMarketTeamPriceHistoryQueryVariables,
} from '../../../../../generated/hooks';
import { PriceChangeType } from '../../../../../generated/schemas';
import { priceChangeTypeLabel } from '../../../../../utils';
import { TeamLogoComponent } from '../../../../logos';

const fetchStockMarketTeamPriceHistory = gql`
  query fetchStockMarketTeamPriceHistory($leagueId: UUID!, $teamId: UUID!) {
    league(id: $leagueId) {
      team(id: $teamId) {
        id
        styles {
          primaryColor
          secondaryColor
          logoComponent
        }
        priceLogs {
          id
          created
          priceChangeType
          price
          data
        }
      }
    }
  }
`;

function PriceLogDescription({
  leagueId,
  team,
  log,
}: {
  leagueId: string;
  team: FetchStockMarketTeamPriceHistoryQuery['league']['team'];
  log: FetchStockMarketTeamPriceHistoryQuery['league']['team']['priceLogs'][0];
}) {
  if (log.priceChangeType === PriceChangeType.InitialPrice) {
    return null;
  }
  if (log.priceChangeType === PriceChangeType.InitialPurchases) {
    return (
      <>
        {log.data['sharesOwned']} share{log.data['sharesOwned'] !== 1 && 's'}
      </>
    );
  }
  if (log.priceChangeType === PriceChangeType.GameWon) {
    return (
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
        <MuiLink component={Link} to={`/league/${leagueId}/team/${team.id}`}>
          <TeamLogoComponent styles={team.styles} />
        </MuiLink>
        <Box>
          {' '}
          {log.data['score']} - {log.data['opponentScore']}{' '}
        </Box>
        <MuiLink component={Link} to={`/league/${leagueId}/team/${log.data['opponent']['id']}`}>
          <TeamLogoComponent styles={log.data['opponent']['styles']} />
        </MuiLink>
      </Stack>
    );
  }
  if ([PriceChangeType.SharesPurchased, PriceChangeType.SharesSold].includes(log.priceChangeType)) {
    const action = log.priceChangeType === PriceChangeType.SharesPurchased ? 'bought' : 'sold';
    const sharesKey = log.priceChangeType === PriceChangeType.SharesPurchased ? 'sharesPurchased' : 'sharesSold';
    return (
      <>
        <MuiLink component={Link} to={`/league/${leagueId}/user/${log.data['user']['id']}`}>
          {log.data['user']['fullName']}
        </MuiLink>{' '}
        {action} {log.data[sharesKey]} share{log.data[sharesKey] !== 1 && 's'}
      </>
    );
  }
  if (log.priceChangeType === PriceChangeType.NewRatings) {
    return null;
  }
  return null;
}

export default function PriceHistory() {
  const { leagueId, teamId } = useParams<{ leagueId: string; teamId: string }>();
  const [{ data }] = useQuery<FetchStockMarketTeamPriceHistoryQuery, FetchStockMarketTeamPriceHistoryQueryVariables>({
    query: fetchStockMarketTeamPriceHistory,
    variables: { leagueId, teamId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Table
      sx={{
        maxWidth: 1200,
        '& .MuiTableCell-sizeSmall': {
          px: 0.5,
        },
        '& .MuiTableCell-sizeSmall:first-of-type': {
          pl: 0,
        },
        '& .MuiTableCell-sizeSmall:last-of-type': {
          pr: 0,
        },
      }}
      size={isMdDown ? 'small' : 'medium'}
    >
      <TableHead>
        <TableRow>
          <TableCell>Time</TableCell>
          <TableCell>Action</TableCell>
          <TableCell align="right">Price</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.league.team.priceLogs.map(log => (
          <TableRow key={log.id}>
            <TableCell>{formatDateTime(log.created)}</TableCell>
            <TableCell>{priceChangeTypeLabel[log.priceChangeType]}</TableCell>
            <TableCell align="right">{currency(log.price).format()}</TableCell>
            <TableCell>
              <PriceLogDescription leagueId={leagueId} team={data.league.team} log={log} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
