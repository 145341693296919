import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function TempleLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 83.669999 95.481248" {...restProps}>
      <g>
        <path fill={colors.primaryColor} d="m0.49329 94.977h82.67v-94.481h-82.67v94.481z" />
        <path
          d="m7.3379 7.1738v27.379h5.9041v-21.205h57.17v21.205h5.908v-27.379l-68.98-0.0002h-0.0021zm12.797 12.711v14.676h5.902v-8.598h6.621v43.484h-10.332v6.016h16.238v-55.578h-18.429zm24.959 0v55.58h16.238v-6.018h-10.332v-43.48h6.619v8.595h5.904v-14.677h-18.429zm-22.768 62.388v6.018h38.963v-6.018h-38.963z"
          fill="#ffffff"
          transform="translate(-.00010681)"
        />
      </g>
    </SvgIcon>
  );
}

export default TempleLogo;
