import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function LouisvilleLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 67.760064 74.855004" {...restProps}>
      <g transform="translate(0 -.13610)">
        <path
          d="m65.658 38.804c-1.5688-2.9538-3.9088-5.0588-6.6138-6.0388 0.08125-0.5875 0.6975-6.5638-6.5062-10.009-2.155-1.0288-4.61-1.98-7.2075-2.985-4.3075-1.6675-9.19-3.5575-13.591-6.2038-6.908-4.1528-13.106-9.889-13.168-9.9478l-3.265-3.0338s-2.2825 4.6175-2.1975 13.046c-0.785-1.0625-1.3525-1.64-1.3525-1.64s-1.3438 6.42 0.265 11.534c-5.3412 3.9888-8.94 8.6488-8.94 8.6488s1.1538 0.305 2.2938 0.60625c-2.875 3.915-4.9625 8.9162-4.9625 8.9162s2.6862-0.22625 3.7962-0.0063c0 0-3.0538 9.425 6.6075 14.702l32.678 18.048c-0.31375-1.205-4.7038-14.176 2.55-15.182 3.48 0.6125 6.94-0.0625 8.2475-0.37625 3.66-0.88 6.5275-3.3362 7.5788-4.3375 0.6 1.855 0.8525 2.69 0.8525 2.69 5.3088-4.9925 5.2938-13.991 2.9362-18.43"
          fill={colors.secondaryColor}
          stroke="#ffffff"
          strokeWidth={variant !== 'main' ? '1' : '0'}
        />
        <path
          d="m39.916 29.887c-1.7262 0.805-2.9238 2.5527-2.9238 4.584 0 2.795 2.2648 5.0605 5.0586 5.0605 2.0275 0 3.768-1.1934 4.5742-2.9121-0.32968-0.39211-0.65952-0.7694-0.98047-1.1328-0.4975 1.1562-1.6449 1.9648-2.9824 1.9648-1.795 0-3.248-1.4523-3.248-3.2461 0-1.3575 0.83049-2.5157 2.0117-3.002h0.0039c-0.9325-0.85-1.5099-1.3089-1.5137-1.3164zm3.4863 3.209c-0.235 0.23375-0.38086 0.55656-0.38086 0.91406 0 0.71625 0.58136 1.2988 1.2988 1.2988 0.33375 0 0.63508-0.12844 0.86133-0.33594-0.62375-0.68625-1.223-1.317-1.7793-1.877zm10.953 12.551c-0.75625-0.02625-1.4096 0.04853-1.9746 0.19727 0.2325 0.64 0.5843 1.7525 0.7168 2.9375 0.61375-0.0925 1.2685-0.1718 1.9473-0.2168-0.07625-1.2212-0.43195-2.293-0.68945-2.918zm1.252 0.11719c0.17625 0.6225 0.40109 1.6417 0.40234 2.7617 0.5925-0.01 1.1955 0.01016 1.793 0.06641-0.03875-0.73375-0.18375-1.5239-0.5-2.3164-0.51125-0.225-1.0766-0.40422-1.6953-0.51172zm-4.2285 0.47266c-1.3562 0.73625-1.9491 2.056-2.0391 3.416 0.44125-0.14375 1.4644-0.44688 2.7969-0.70312-0.13125-1.135-0.53531-2.1966-0.75781-2.7129zm7.2129 0.76758c0.0825 0.37125 0.16602 0.96848 0.13476 1.7109 0.72125 0.12125 1.427 0.31188 2.0957 0.57812-0.4175-0.6175-1.1492-1.5328-2.2305-2.2891zm-1.6777 2.6895c-0.31047-0.0086-0.64086-0.0086-0.99023 0.0039-0.1925 1.35-0.605 2.3741-0.84375 2.8828 0.68625-0.1125 1.4341-0.30797 2.2441-0.60547 0.1425-0.4125 0.37969-1.2217 0.46094-2.2305-0.27125-0.02562-0.56062-0.04219-0.87109-0.05078zm-1.877 0.05859c-0.59 0.05125-1.2299 0.13742-1.9199 0.26367-0.10375 1.0738-0.49781 2.0341-0.75781 2.5703 0.63875 0.12875 1.3816 0.15945 2.2266 0.06445 0.12375-0.54125 0.37117-1.7197 0.45117-2.8984zm3.5605 0.09961c-0.10875 0.57125-0.2968 1.1967-0.5918 1.8555 0.79625-0.33125 1.6426-0.7507 2.5488-1.2832-0.33125-0.15625-0.97331-0.40853-1.957-0.57227zm-6.4238 0.35352c-0.77875 0.1725-1.616 0.39789-2.5098 0.68164 0.405 0.68125 1.1822 1.3052 2.3359 1.6152 0.0625-0.50375 0.14758-1.3319 0.17383-2.2969z"
          fill="#fff"
          transform="translate(0 -.000034332)"
        />
        <path
          fill="#fdb913"
          d="m56.408 34.979c-6.9675-0.7925-8.4 6.5725-8.4 6.5725s-8.1462 0.59-7.6588 8.3975c0.49375 7.9075 9.5862 7.1162 13.292 6.2275 3.705-0.89125 6.6212-3.955 6.6212-3.955-8.6475 4.3-12.996 0.99-12.996-2.37s2.62-7.3125 8.6975-5.7312c6.0762 1.58 7.805 7.2625 7.805 7.2625 2.916-5.584-0.393-15.614-7.361-16.404"
        />
        <path
          d="m31.133 53.542s3.8275 0.6875 6.2825-1.2762c0 0-4.4162-0.09875-6.675-3.3388 0 0 3.5325 0.6875 6.2812-1.1775 0 0-3.2375-1.275-5.3-3.5325 0 0 3.0675 0.4125 5.7712-0.6625-3.38-1.1788-5.7712-4.0838-5.7712-7.4838 0-3.695 2.8212-6.8038 6.6662-7.7512-0.79625-1.2925-2.39-2.9975-5.5862-4.5188 0 0 4.9075 0.49125 7.655 2.2575 2.7488 1.7675 3.9262 4.81 6.48 5.2025 3.0325 0.4675 3.2375-1.2762 2.8438-2.7488 0 0 2.9825 2.2475-0.485 6.1312 0.66875-0.68125 2.9162-2.5825 6.9912-2.2612 0 0 0.6875-4.4112-4.9512-7.1088-5.638-2.695-13.778-4.95-21.033-9.314-7.257-4.364-13.631-10.296-13.631-10.296s-2.795 9.2188 1.815 16.18c0 0-2.8938-0.1475-4.4125-2.205 0 0-0.05 4.5575 4.46 9.1662 0 0-1.96 0-5.3925-2.6475 0 0-3.48 2.55-5.0988 4.4612 0 0 1.6675 0.4425 2.2062 0.73625 0 0-3.48 3.5788-5.59 7.55 0 0 2.06-0.05 2.9425 0.04875 0 0-2.6962 7.6975 2.2062 13.041l26.451 15.01s-3.1625-7.9638 1.6462-10.224c0 0-4.6125 0-6.7725-3.2375"
          fill={colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default LouisvilleLogo;
