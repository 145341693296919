import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function CharlotteLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 300 378.1" {...restProps}>
      <path
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        d="M160.6,200.9c-6,44.9-6.4,89.7-5.7,134.3c-24-40.2-36.3-85-33.3-132.4l-7.3,0.3c-1.4,0.1-2.4-0.8-2.6-2  l-14,0.7c-1.4,0.1-2.4-1-2.2-2.4l11.7-34.6c0.3-1.3,1.6-2.3,2.9-2.2l7.4,0.8c0.5-1.3,1.9-2.2,3.3-2.1l7.2,0.3  c11.6-47.4,37.7-92.2,74.1-132.4c-14.4,44.6-27.9,89.4-35.7,134.3l10.2,4.2l97.8-4.8c1.4-0.1,2.8-1,3-2.4c0,0,0,0,0,0  c6.1-35.4,13.9-75,21.5-110.1c0.4-1.8,0-3.4-1.1-4.7c-9.2-11.2-18.4-22.6-27.4-34.1c-1.1-1.4-2.7-2.2-4.8-2.3  C209.4,6.6,153.3,3.9,97.2,1.3c-1.9-0.1-3.7,0.5-5.5,1.9c-14.2,11-28.3,22.2-42.4,33.5c-1.7,1.4-2.8,3.1-3.2,5  C26.2,139.3,11.1,238,1,337.5c-0.2,2,0.3,3.6,1.6,5c10.5,11.3,21.1,22.4,31.7,33.4c1.2,1.2,4.9,0.8,4.9,0.8h162.3  c0,0,4.1-0.9,5.5-2.2c12.7-12,32.2-31.8,45-43.5c1.6-1.4,2.5-6.5,2.7-8.3c2.8-24.9,5.8-49.8,9.3-74.6c0.4-3,0.8-6.1,1.3-9.1  c1.6-11.5,3.7-24,5.6-35.8c0.4-2.4-1.5-4.6-4-4.6l-94.8-1.6L160.6,200.9z"
      />
    </SvgIcon>
  );
}

export default CharlotteLogo;
