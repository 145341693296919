import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAccountContext } from '../contexts/account';
import { useFlashMessageContext } from '../contexts/flashMessage';
import { buildUrl } from '../utils';

function EmailVerification() {
  const { setRefreshToken } = useAccountContext();
  const { addFlashMessage, clearFlashMessage } = useFlashMessageContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const token = urlSearchParams.get('token');

  useEffect(() => {
    if (!token) {
      return;
    }
    setLoading(true);
    clearFlashMessage();
    fetch(buildUrl('/api/verify-email'), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ token }),
    })
      .then(response => {
        if (response.status === 200) {
          return response.json().then(responseData => {
            setRefreshToken(responseData.refresh);
            addFlashMessage('success', 'Your email address has been verified');
            navigate('/');
          });
        }
        setLoading(false);
        if (response.status === 400) {
          return response.json().then(responseData => addFlashMessage('warning', responseData.message));
        }
        addFlashMessage('error', 'An unknown error occurred');
      })
      .catch(() => {
        setLoading(false);
        addFlashMessage('error', 'An unknown error occurred');
      });
  }, []);

  return (
    <Box sx={{ textAlign: 'center' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography variant="h5">
          Your email address needs to be verified. Please check your email for a verification link.
        </Typography>
      )}
    </Box>
  );
}

export default EmailVerification;
