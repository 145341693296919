import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function HawaiiLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 316.549 238.984" {...restProps}>
      <g transform="translate(.86176 1.1382)">
        <path
          fill={colors.secondaryColor}
          d="m275.35 183.09 19.08-22-17.72-18.32 18.83-20.87-18.1-19.05 18.29-19.947-19.02-19.715 34.36-37.806-137.19-25.192v71.946c-2.854-0.444-12.75-0.607-16.432-0.687-4.361-0.093-12.573 0.575-16.703 0.983l0.01-72.432-137.27 25.412 34.398 37.803-19.05 19.714 19.76 19.94-19.023 19.055 19.571 20.872-19.807 18.313 19.877 22.007-39.206 53.87 140.61-22.301 0.17-64.684c3.431-0.956 13.326-1.531 16.672-1.531 3.682 0 12.743 0.407 16.391 1.377l0.177 65.027 140.53 22.084-39.201-53.862"
        />
        <path
          d="m265.33 182.47 18.48-21.18-19.11-19.08 19.11-20.31-18.21-18.99 18.57-20.398-18.93-19.447 30.12-33.646-113.59-21.361v72.295c-5.687-0.496-15.098-1.452-24.315-1.527-5.534-0.045-17.766 0.951-24.664 1.55v-72.289l-113.6 21.354 30.12 33.654-18.93 19.444 18.567 20.396-18.205 18.988 19.106 20.301-19.106 19.094 18.475 21.178-32.027 44.801 116.08-19.029 0.111-64.416c9.65-1.921 19.877-2.084 24.065-2.161 5.194-0.1 17.601 0.575 23.713 2.139l0.113 64.411 116.09 19.03-32.028-44.798"
          fill="#000"
        />
        <path
          fill="#ffffff"
          d="m254.68 182.4 18.649-21.104-18.641-18.636c0-0.702 18.641-20.754 18.641-20.754l-18.649-18.651c0-0.695 18.649-20.737 18.649-20.737l-18.66-19.343 26.07-28.841-84.86-15.377v71.479c-10.244-1.668-29.537-3.496-38.429-3.402-10.059 0.106-25.021 0.888-38.773 3.43l-0.01-71.48-84.856 15.375 26.076 28.842-18.663 19.333s18.651 20.038 18.651 20.738l-18.651 18.646s18.643 20.057 18.643 20.754l-18.643 18.641 18.647 21.104-26.631 34.648 85.4-14.037 0.053-66.02c9.417-2.461 30.291-4.069 38.755-3.869 7.206 0.163 28.257 1.658 38.411 3.842 0.014 6.171 0.153 65.988 0.153 65.988l85.295 14.063-26.632-34.641"
        />
        <path
          fill={colors.primaryColor}
          d="m250.48 60.547 21.25-23.253-71.66-13.312v70.927c-11.404-2.164-34.489-3.807-42.62-3.865-10.56-0.075-28.775 0.848-42.964 3.892l-0.005-70.926-71.663 13.306 21.259 23.257-0.022 124.22-20.406 26.283 70.707-11.635 0.154-65.839c11.087-3.23 33.302-4.7 42.939-4.64 10.233 0.059 31.329 1.931 42.593 4.612l0.154 65.848 70.709 11.627-20.402-26.289-0.023-124.21"
        />
        <path
          fill="#ffffff"
          d="m240.77 68.604-17.371 17.865 17.207 17.697-17.207 17.695 17.35 17.85-17.35 17.848 17.371 17.869v-35.695-0.0449-35.352-0.33789-35.395zm-166.99 0.021v35.396 0.34375 35.35 0.0449 35.686l17.371-17.86-17.349-17.84 17.349-17.85-17.203-17.7 17.203-17.698-17.371-17.867z"
        />
      </g>
    </SvgIcon>
  );
}

export default HawaiiLogo;
