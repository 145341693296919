import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MiamiLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 443.6 274" {...restProps}>
      <g transform="translate(1.0366 .96338)">
        <path
          fill="#ffffff"
          d="m318.31 0 0.33894 182.19c0 11.662 0 47.176-89.44 47.325h-16.807c-89.45-0.15-89.45-35.66-89.45-47.33l0.34-182.19h-123.29l0.10966 209.53c0 57.96 162.51 62.47 212.33 62.47h16.738c49.804 0 212.32-4.5053 212.32-62.466l0.1-209.53h-123.29z"
        />
        <path
          fill={colors.primaryColor}
          d="m327.82 182.19 0.0399-173.3h104.57s-0.1296 193.71-0.1296 200.64c0 38.036-104.4 53.286-203.13 53.286 0-4.7943 0.0299-16.566 0.0299-24.131 49.416-0.0797 98.612-10.526 98.612-56.495"
        />
        <path
          fill={colors.secondaryColor}
          d="m113.78 182.19-0.0399-173.3h-104.57s0.11963 193.71 0.11963 200.64c0 38.036 104.42 53.286 203.15 53.286 0-4.7943-0.0498-16.566-0.0498-24.131-49.406-0.0797-98.602-10.526-98.602-56.495"
        />
      </g>
    </SvgIcon>
  );
}

export default MiamiLogo;
