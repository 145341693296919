import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MassachusettsLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250.04 205" {...restProps}>
      <g fill="none" strokeMiterlimit="10">
        <g stroke="#ffffff">
          <path
            strokeWidth="18.9121"
            d="M38.56,138.95l-26.78,0l3.9-17.69l4.66,0l8.25-38.59h-4.92l3.69-17.81h25.63L38.56,138.95z M209.92,82.57h28.45l-3.93,18.7h-16.12l0.99-4.71h-5.45l-1.51,7.16h13.27c2.34,0,4.53,0.91,6.04,2.75c1.74,2.12,2.36,5.18,1.71,8.38l-2.98,14.14c-1.23,5.4-6.7,9.95-11.96,9.95l-30.6,0"
          />
          <path
            strokeWidth="31.7562"
            d="M79.25,188.9c-7.88,0-14.77-3.13-19.39-8.82c-5.32-6.55-7.21-15.87-5.19-25.55l20.84-97.08H64.01l8.71-41.35h65.6l-27.67,131.19h18.19L156.5,16.1h65.05l-8.4,41.36h-11.05l-20.65,96.54c-3.61,16.52-19.74,34.91-37.13,34.91H79.25z"
          />
        </g>
        <path
          stroke={colors.secondaryColor}
          strokeWidth="21.8257"
          d="M52.98,64.86l6.62,17.87 M144.33,188.9c17.39,0,33.52-18.39,37.13-34.91l20.65-96.54h11.05l8.4-41.36H156.5l-27.67,131.19h-18.19L138.31,16.1h-65.6l-8.71,41.35h11.51l-20.84,97.08c-2.03,9.68-0.14,18.99,5.19,25.55c4.62,5.69,11.5,8.82,19.39,8.82H144.33z"
        />
      </g>
      <path
        fill={colors.primaryColor}
        stroke="#ffffff"
        strokeWidth="7.2759"
        strokeMiterlimit="10"
        d="M79.25,188.9c-7.88,0-14.77-3.13-19.39-8.82c-5.32-6.55-7.21-15.87-5.19-25.55l20.84-97.08H64.01l8.71-41.35h65.6l-27.67,131.19h18.19L156.5,16.1h65.05l-8.4,41.36h-11.05l-20.65,96.54c-3.61,16.52-19.74,34.91-37.13,34.91H79.25z"
      />
      <path
        fill="none"
        stroke={colors.secondaryColor}
        strokeWidth="8.9846"
        strokeMiterlimit="10"
        d="M218.32,101.26l0.99-4.71h-5.45l-1.51,7.16h13.27c2.45,0,4.59,0.98,6.04,2.75c1.74,2.12,2.36,5.18,1.71,8.38l-2.98,14.14c-1.23,5.4-6.7,9.95-11.96,9.95h-30.6l4.1-19.44h17.38l-1.16,5.21h5.1l1.74-7.91H202.4c-2.66,0-4.99-1.07-6.56-3.02c-1.87-2.32-2.51-5.62-1.76-9.04l2.64-12.63c1.28-5.97,7.72-9.54,13.2-9.54h28.45l-3.93,18.7H218.32z M193.79,101.26l3.94-18.7h-28.45c-5.49,0-11.92,3.57-13.2,9.54l-2.64,12.63c-0.75,3.43-0.1,6.72,1.76,9.04c1.57,1.94,3.89,3.01,6.55,3.01h12.6l-1.74,7.91h-5.1l1.16-5.21H151.3l-4.1,19.44h30.59c5.26,0,10.73-4.54,11.96-9.95l2.98-14.14c0.65-3.2,0.03-6.26-1.71-8.38c-1.45-1.77-3.59-2.75-6.04-2.75h-13.27l1.51-7.16h5.45l-0.99,4.71H193.79z M147.01,138.94l3.04-14.23H146l0.66-42.14h-26.84l-16.52,42.3h-4.24l-2.94,14.07h20.76l4.54-13.05h6.61l-1.32,13.05H147.01z M125.53,113.38l4.05-11.49l-0.57,11.49H125.53z M96.01,138.95l3.87-17.7h-4.8l8.1-38.57l4.63-0.02l3.69-17.8h-25.4L63.71,93.81L52.98,64.86H27.35l-3.69,17.81h4.92l-8.25,38.59l-4.66,0l-3.9,17.69h26.78l7.27-33.85c3.36,7.86,6.88,15.8,10.22,23.31l1.02,2.28l19.69-25.34l-7.14,33.6H96.01z"
      />
      <path fill={colors.primaryColor} d="M221.5,94.6h-56.51v32.17h56.51V94.6z" />
      <path
        fill="#ffffff"
        stroke={colors.primaryColor}
        strokeWidth="2.9975"
        strokeMiterlimit="10"
        d="M218.32,101.26l0.99-4.71h-5.45l-1.51,7.16h13.27c2.45,0,4.59,0.98,6.04,2.75c1.74,2.12,2.36,5.18,1.71,8.38l-2.98,14.14c-1.23,5.4-6.7,9.95-11.96,9.95h-30.6l4.1-19.44h17.38l-1.16,5.21h5.1l1.74-7.91H202.4c-2.66,0-4.99-1.07-6.56-3.02c-1.87-2.32-2.51-5.62-1.76-9.04l2.64-12.63c1.28-5.97,7.72-9.54,13.2-9.54h28.45l-3.93,18.7H218.32z M193.79,101.26l3.94-18.7h-28.45c-5.49,0-11.92,3.57-13.2,9.54l-2.64,12.63c-0.75,3.43-0.1,6.72,1.76,9.04c1.57,1.94,3.89,3.01,6.55,3.01h12.6l-1.74,7.91h-5.1l1.16-5.21H151.3l-4.1,19.44h30.59c5.26,0,10.73-4.54,11.96-9.95l2.98-14.14c0.65-3.2,0.03-6.26-1.71-8.38c-1.45-1.77-3.59-2.75-6.04-2.75h-13.27l1.51-7.16h5.45l-0.99,4.71H193.79z M147.01,138.94l3.04-14.23H146l0.66-42.14h-26.84l-16.52,42.3h-4.24l-2.94,14.07h20.76l4.54-13.05h6.61l-1.32,13.05H147.01z M125.53,113.38l4.05-11.49l-0.57,11.49H125.53z M96.01,138.95l3.87-17.7h-4.8l8.1-38.57l4.63-0.02l3.69-17.8h-25.4L63.71,93.81L52.98,64.86H27.35l-3.69,17.81h4.92l-8.25,38.59l-4.66,0l-3.9,17.69h26.78l7.27-33.85c3.36,7.86,6.88,15.8,10.22,23.31l1.02,2.28l19.69-25.34l-7.14,33.6H96.01z"
      />
      <path
        d="M212.53,71.82l0.15-0.7h3.57l-0.15,0.7h-1.37l-0.75,3.49h-0.83l0.75-3.49H212.53z M218.16,71.12l0.43,3l1.72-3h1.19l-0.9,4.18h-0.77l0.72-3.38l-1.88,3.38H218l-0.44-3.38l-0.73,3.38h-0.77l0.9-4.18H218.16z"
        fill="#000"
      />
    </SvgIcon>
  );
}

export default MassachusettsLogo;
