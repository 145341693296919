import React, { useCallback, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Box, Button, List, ListItem, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { gql, useMutation, useQuery } from 'urql';
import {
  CancelLeagueInvitationMutation,
  CancelLeagueInvitationMutationVariables,
  CreateLeagueInvitationMutation,
  CreateLeagueInvitationMutationVariables,
  FetchPickEmLeagueSettingsQuery,
  FetchPickEmLeagueSettingsQueryVariables,
} from '../../../../generated/hooks';
import { CancelLeagueInvitationInputType, CreateLeagueInvitationInputType } from '../../../../generated/schemas';
import { InputFieldBase } from '../../../forms/InputField';

const fetchPickEmLeagueSettings = gql`
  query fetchPickEmLeagueSettings($leagueId: UUID!) {
    league(id: $leagueId) {
      id
      name
      invitations {
        id
        email
        user {
          id
          fullName
        }
        status
      }
      users {
        id
        fullName
      }
    }
  }
`;

const cancelLeagueInvitation = gql`
  mutation cancelLeagueInvitation($cancelLeagueInvitationInput: CancelLeagueInvitationInputType!) {
    cancelLeagueInvitation(input: $cancelLeagueInvitationInput) {
      ok
      league {
        id
      }
    }
  }
`;

const createLeagueInvitation = gql`
  mutation createLeagueInvitation($createLeagueInvitationInput: CreateLeagueInvitationInputType!) {
    createLeagueInvitation(input: $createLeagueInvitationInput) {
      ok
      invitation {
        id
        league {
          id
        }
      }
    }
  }
`;

type LeagueSettingsProps = {
  leagueId: string;
};

function LeagueSettings({ leagueId }: LeagueSettingsProps) {
  const [invitationEmail, setInvitationEmail] = useState('');
  const [{ data }] = useQuery<FetchPickEmLeagueSettingsQuery, FetchPickEmLeagueSettingsQueryVariables>({
    query: fetchPickEmLeagueSettings,
    variables: { leagueId },
  });
  const [, cancelLeagueInvitationMutation] = useMutation<
    CancelLeagueInvitationMutation,
    CancelLeagueInvitationMutationVariables
  >(cancelLeagueInvitation);
  const handleCancelLeagueInvitation = useCallback(
    (cancelLeagueInvitationInput: CancelLeagueInvitationInputType) =>
      cancelLeagueInvitationMutation({ cancelLeagueInvitationInput }),
    [cancelLeagueInvitationMutation],
  );
  const [, createLeagueInvitationMutation] = useMutation<
    CreateLeagueInvitationMutation,
    CreateLeagueInvitationMutationVariables
  >(createLeagueInvitation);
  const handleCreateLeagueInvitation = useCallback(
    (createLeagueInvitationInput: CreateLeagueInvitationInputType) =>
      createLeagueInvitationMutation({ createLeagueInvitationInput }),
    [createLeagueInvitationMutation],
  );
  return (
    <Paper sx={{ p: 2, flexGrow: 1 }}>
      <div style={{ display: 'flex' }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {data?.league.name} Settings
        </Typography>
        <Button variant="outlined" startIcon={<ArrowBack />} component={Link} to={`/league/${leagueId}`}>
          Back to League
        </Button>
      </div>
      <Typography variant="h6">Players:</Typography>
      <List>
        {data?.league.users.map(user => (
          <ListItem key={user.id}>{user.fullName}</ListItem>
        ))}
      </List>
      <Typography variant="h6">Invitations:</Typography>
      <List>
        {data?.league.invitations.map(invitation => (
          <ListItem key={invitation.id} sx={{ maxWidth: 600 }}>
            <Box sx={{ flexGrow: 1 }}>{invitation.user ? invitation.user.fullName : invitation.email}</Box>
            <Button
              color="error"
              variant="contained"
              onClick={() => handleCancelLeagueInvitation({ invitationId: invitation.id })}
            >
              Cancel
            </Button>
          </ListItem>
        ))}
        <ListItem sx={{ maxWidth: 600 }}>
          <InputFieldBase
            label="Email Address"
            name="invitationEmail"
            onChange={e => setInvitationEmail(e.target.value)}
            value={invitationEmail}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            onClick={() =>
              handleCreateLeagueInvitation({ leagueId, email: invitationEmail }).then(response => {
                if (!response.error) {
                  setInvitationEmail('');
                }
              })
            }
            sx={{ ml: 2 }}
          >
            Send Invite
          </Button>
        </ListItem>
      </List>
    </Paper>
  );
}

export default LeagueSettings;
