import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import currency from 'currency.js';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { formatDateTime } from '../../../../dates';
import {
  FetchStockMarketLeagueTransactionsQuery,
  FetchStockMarketLeagueTransactionsQueryVariables,
} from '../../../../generated/hooks';
import { BalanceChangeType } from '../../../../generated/schemas';
import { TeamLogoComponent } from '../../../logos';

const fetchStockMarketLeagueTransactions = gql`
  query fetchStockMarketLeagueTransactions($leagueId: UUID!) {
    league(id: $leagueId) {
      transactions {
        id
        created
        balanceChangeType
        balance
        data
        user {
          id
          fullName
        }
      }
    }
  }
`;

function BalanceLogDescription({
  leagueId,
  log,
}: {
  leagueId: string;
  log: FetchStockMarketLeagueTransactionsQuery['league']['transactions'][0];
}) {
  const sharesKey = log.balanceChangeType === BalanceChangeType.SharesPurchased ? 'sharesPurchased' : 'sharesSold';
  const totalKey = log.balanceChangeType === BalanceChangeType.SharesPurchased ? 'totalCost' : 'totalPayment';
  const action = log.balanceChangeType === BalanceChangeType.SharesPurchased ? 'bought' : 'sold';
  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
      <Box>
        <MuiLink component={Link} to={`/league/${leagueId}/user/${log.user.id}`}>
          {log.user.fullName}
        </MuiLink>{' '}
        {action} {log.data[sharesKey]} share{log.data[sharesKey] !== 1 && 's'} of{' '}
      </Box>
      <MuiLink component={Link} to={`/league/${leagueId}/team/${log.data['team']['id']}`}>
        <TeamLogoComponent styles={log.data['team']['styles']} />
      </MuiLink>
      <Box> for {currency(log.data[totalKey]).format()}</Box>
    </Stack>
  );
}

export default function Transactions() {
  const { leagueId } = useParams<{ leagueId: string }>();
  const [{ data, fetching, stale }] = useQuery<
    FetchStockMarketLeagueTransactionsQuery,
    FetchStockMarketLeagueTransactionsQueryVariables
  >({
    query: fetchStockMarketLeagueTransactions,
    variables: { leagueId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  if (fetching || stale) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Table
      sx={{
        maxWidth: 1200,
        '& .MuiTableCell-sizeSmall': {
          px: 0.5,
        },
        '& .MuiTableCell-sizeSmall:first-of-type': {
          pl: 0,
        },
        '& .MuiTableCell-sizeSmall:last-of-type': {
          pr: 0,
        },
      }}
      size={isMdDown ? 'small' : 'medium'}
    >
      <TableHead>
        <TableRow>
          <TableCell>Time</TableCell>
          <TableCell>Transaction</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.league.transactions.map(log => (
          <TableRow key={log.id}>
            <TableCell>{formatDateTime(log.created)}</TableCell>
            <TableCell>
              <BalanceLogDescription leagueId={leagueId} log={log} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
