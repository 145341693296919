import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function OklahomaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 194 250.18933" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -775.87 1678.6)">
        <g>
          <path
            d="m695.33 1310.7 8.5367-7.9619v-66.205l-8.5367-7.9604h-2.756v48.022h-28.553v-48.022h-2.753l-8.5382 7.9604v66.205l8.5382 7.9619h34.062zm48.382-34.105v-93.865l-8.5382-7.9619h-34.063l-8.5367 7.9619v17.29h10.757l29.09 27.124v84.985l-29.09 27.126h-50.066l-29.089-27.126v-84.985l29.089-27.124h10.757v-26.679l29.09-27.127h50.065l29.089 27.127v103.25h-28.553z"
            fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default OklahomaLogo;
