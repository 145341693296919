export function formatDate(dateTime: Date | string) {
  if (typeof dateTime === 'string') {
    dateTime = new Date(dateTime);
  }
  return dateTime.toLocaleString(undefined, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
}

export function formatTime(dateTime: Date | string) {
  if (typeof dateTime === 'string') {
    dateTime = new Date(dateTime);
  }
  return dateTime.toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });
}

export function formatDateTime(dateTime: Date | string) {
  if (typeof dateTime === 'string') {
    dateTime = new Date(dateTime);
  }
  return dateTime.toLocaleString(undefined, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });
}
