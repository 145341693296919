import React from 'react';
import { Paper } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import LeagueHome from './LeagueHome';
import TeamPage from './TeamPage';
import UserPage from './UserPage';

type StockMarketLeagueDetailProps = {
  leagueId: string;
};

function StockMarketLeagueDetail({ leagueId }: StockMarketLeagueDetailProps) {
  return (
    <Paper sx={{ p: 2, flexGrow: 1 }}>
      <Routes>
        <Route path="/user/:userId/*" element={<UserPage />} />
        <Route path="/team/:teamId/*" element={<TeamPage />} />
        <Route path="/:leaguePage/*" element={<LeagueHome />} />
        <Route path="" element={<Navigate to={`/league/${leagueId}/player-standings`} replace />} />
        <Route path="*" element={<Navigate to={`/league/${leagueId}/player-standings`} replace />} />
      </Routes>
    </Paper>
  );
}

export default StockMarketLeagueDetail;
