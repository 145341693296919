import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function AuburnLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 220.58984" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -376.63 815)">
        <g>
          <path
            d="m453.31 600.28v-8.6562l-4.3106 8.6562h4.3106m-103.36 0h4.3106l-4.3106-8.6562v8.6562m51.52-92.21c-25.53 0-33.87 3.538-42.869 8.5421-0.75501 0.41262-1.4222 0.83401-2.0456 1.2466h26.715v28.076h-16.25l7.2077 12.818h56.459l7.3042-13.195h-18.269v-27.698h26.654c-0.62332-0.41262-1.2905-0.83401-2.0455-1.2466-8.9986-5.0041-17.339-8.5421-42.86-8.5421m0.89548 100.61 12.212-21.474h-24.59l12.379 21.474zm98.3-8.3841v28.076h-61.463v-11.808l-19.621 34.792h-36.17l-19.683-34.897v11.913h-61.463v-28.076h12.379s-0.009-34.265-0.0176-54.738h-11.773v-27.698h12.335c1.2203-7.6993 4.6705-16.066 14.117-23.028 14.942-11.009 32.939-18.348 72.165-18.348 39.216 0 57.214 7.3394 72.156 18.348 7.9012 5.8206 11.553 15.293 13.239 23.028h13.265v27.698h-11.826v54.738h12.361z"
            fill={variant === 'main' ? colors.secondaryColor : '#ffffff'}
          />
          <path
            d="m106.71 7.791l-74.564 132.26h-23.22l-0.0002 20.63h86.529v-21.1h-25.283l16.889-30.02h78.779l16.88 30.49h-27.71v20.63h86.53v-20.63h-23.22l-74.57-132.26-37.04 0.001zm72.65 28.742v20.17h17.35v30.77l25.69 45.577h4.33v-75.415h15.47v-21.102h-62.84zm-171.17 0.002v21.102h15.473v75.413h4.391l25.623-45.443v-30.902h17.349v-20.17h-62.836zm118.18 3.541l27.24 47.912h-54.874l27.634-47.912zm-101.9 127.6c1.47 7.95 5.256 16.21 14.672 23.14 17.821 13.14 38.693 21.58 86.059 21.58 47.36 0 68.23-8.44 86.06-21.58 9.41-6.93 13.19-15.19 14.67-23.14h-34.11c-2.17 2.66-5.25 5.22-9.65 7.67-12.66 7.03-24.61 11.55-56.97 11.55-32.365 0-44.326-4.52-56.979-11.55-4.401-2.45-7.485-5.01-9.647-7.67h-34.105z"
            transform="matrix(.8 0 0 -.8 301.31 652)"
            fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default AuburnLogo;
