import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function GeorgiaTechLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 250 157.48767" {...restProps}>
      <g transform="matrix(.81578 0 0 .81578 1.1766 1.1197)">
        <path
          fill={variant === 'third' ? '#ffffff' : colors.secondaryColor}
          d="m120 60.7v49.5h27.6l2.7-14.1h8.6c-5.1 21.4-28.7 35.6-56.4 33.4-26.3-2-54.5-16.9-54.4-44.7 0.1-30.7 27.6-48.4 53.3-49.5 17.2-0.7 36.5 5.9 50.4 17.4 0.8 0.7 1 0.8 1.1 0.9s0.2 0.1 0.9 0.7l0.8 0.6h30.7v-54.9h-31.9v15.3c-13-7.6-33.6-14.9-57.3-14.8-27.1 0.1-53.5 11.2-72.5 30.4-15.5 15.8-23.8 35-23.6 54.3 0.2 18.9 8.8 37 24 50.9 19.4 17.7 47.8 26.9 75.8 24.6 21.8-1.8 41.2-6.6 55.4-13.7v5.4h33.8v-56.2h8.1v62.5h-16.2v31.9h74.9v-31.9h-16.2v-62.6h34.2l2.5 14.1h27.7v-49.5h-184z"
        />
        <path
          d="m125.4 104.8h17.6l2.7-14.1h19.6l-0.5 3.1c-3.9 25.9-30.9 43.6-62.8 41.2-28.7-2.2-59.5-18.9-59.4-50.1 0.1-29.9 25.3-53.5 58.5-54.9 18.5-0.8 39.2 6.3 54.1 18.6 0.6 0.5 0.6 0.5 0.7 0.6s0.2 0.1 0.5 0.4h23.3v-44.2h-21v19.2l-4.5-2.3c-11.5-8-32.6-16.4-57.6-16.4h-0.4c-25.8 0.1-50.8 10.6-68.7 28.8-14.4 14.7-22.3 32.6-22 50.4 0.2 17.4 8.1 34.1 22.2 46.9 18.3 16.7 45.1 25.4 71.7 23.2 23.2-1.9 43.5-7.3 57.3-15.2l4.1-2.4-0.1 9.3h22.8v-56.2h19v73.4h-16.2v21h64v-21h-16.2v-73.4h44.2l2.5 14.1h17.7v-38.7h-173.1v38.7z"
          fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default GeorgiaTechLogo;
