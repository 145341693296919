import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function MinnesotaLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 177 106.13349" {...restProps}>
      <g>
        <path
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          d="m164.99 78.679-30.674-50.992h8.4521v-26.573h-51.065v26.573h8.4521l-11.728 19.478-11.713-19.479h8.446v-26.573h-51.059v26.573h8.4521l-30.674 50.992h-10.947v26.567h52.075v-26.567h-9.3526l15.979-26.567 6.1945 10.302h-5.939v26.573h57.089v-26.573h-5.939l6.1945-10.302 15.979 26.567h-9.3587v26.567h52.075v-26.567h-10.941z"
        />
        <path
          fill={variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
          d="m139.32 4.5635v19.758h-11.069l34.776 57.808h9.4622v19.758h-45.26v-19.758h11.969l-22.003-36.577-12.219 20.318h8.5555v19.752h-50.28v-19.752h8.5677l-12.239-20.321-22.003 36.577h11.975v19.758h-45.26v-19.758h9.4561l34.776-57.808h-11.063v-19.758h44.244v19.758h-11.069l17.744 29.494 17.744-29.494h-11.063v-19.758h44.244z"
        />
        <path
          d="m135.91 7.9711v12.949h-13.685l38.877 64.617h7.9774v12.943h-38.451v-12.943h14.592l-28.028-46.587-18.243 30.322h11.172v12.949h-43.465v-12.949h11.178l-18.243-30.322-28.028 46.587h14.592v12.943h-38.451v-12.943h7.9774l38.877-64.617h-13.685v-12.949h37.435v12.949h-13.685l23.762 39.504 23.774-39.504h-13.685v-12.949h37.435z"
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
        />
      </g>
    </SvgIcon>
  );
}

export default MinnesotaLogo;
