import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function SyracuseLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 202 273.23888" {...restProps}>
      <g>
        <path
          fill={variant === 'main' ? colors.secondaryColor : '#ffffff'}
          d="m200.98 94.332v-54.698l-40.248-38.628h-1.4555-119.3l-38.998 38.661v90.648l37.52 37.966h87.184v39.109h-48.859v-28.48l-75.845 0.002v54.666l38.998 38.666h120.76l40.248-38.634v-90.678l-37.516-37.97h-86.639v-39.109h48.859v28.484l75.298-0.0088z"
        />
        <path
          fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          d="m131.12 88.925c3.3305 0 61.114-0.0087 64.445-0.0087v-46.966c-1.06-1.017-35.99-34.548-37-35.514h-116.34c-1.0225 1-34.79 34.475-35.815 35.5v86.152c0.99722 1.0139 33.341 33.75 34.355 34.772h90.345v49.952h-59.709v-28.475c-3.3361 0-61.659 0-64.991 0.005v46.975c1.0222 1.0194 34.791 34.502 35.802 35.505h116.34c1.0056-0.96389 35.939-34.498 37.002-35.525v-86.13c-0.99723-1.0139-33.345-33.75-34.359-34.775h-89.795v-49.952h59.709v28.48z"
        />
      </g>
    </SvgIcon>
  );
}

export default SyracuseLogo;
