import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function TexasAmLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 247 203" {...restProps}>
      <path
        d="M217.9,66.4l-18.2,38l-18.2,-38h-27.5v17.2h6.3v52.5h-6.6v17.2h31v-17.2h-6.7v-37.2l21.85,45l21.85,-45v37.2h-6.6v17.2h31v-17.2h-6.6v-52.5h6.6v-17.3z"
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
      />
      <path
        d="M27.4,66h36.9v17.1h-4.5l23,53h9.4v17.1h-33.5v-17h5l-3.7,-8.8h-27.6l-3.7,8.8h5v17.1h-33.5v-17.1h9.4l23,-53h-5v-17.1zM46.2,95.5l-6.5,14.8h13z"
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
      />
      <path
        d="M27.4,0h191v54.7h-37.6v-20.7h-39.5v130.7h21v37.3h-79.2v-37.3h21v-130.7h-39.5v20.7h-37.6v-54.7z"
        fill={variant === 'inverted' ? '#ffffff' : colors.primaryColor}
      />
      <path d="M34.5,7.7h172.4l-10.4,8h-153.8z" fill={variant === 'inverted' ? colors.secondaryColor : '#ffffff'} />
      <path d="M125.7,18.5l7.9,7.8v146.6l-7.9,7.8z" fill={variant === 'inverted' ? colors.secondaryColor : '#ffffff'} />
      <path d="M210.9,11.8v35.9l-7.9,-7.6v-20.2z" fill={variant === 'inverted' ? colors.secondaryColor : '#ffffff'} />
      <path d="M154.65,172.2v22.3l-8,-7.5v-6.5z" fill={variant === 'inverted' ? colors.secondaryColor : '#ffffff'} />
    </SvgIcon>
  );
}

export default TexasAmLogo;
