import React from 'react';
import { SvgIcon } from '@mui/material';
import { LogoProps } from '../../types';

function CentralMichiganLogo({ colors, variant = 'main', ...restProps }: LogoProps) {
  return (
    <SvgIcon viewBox="0 0 201.84179 142.96804" {...restProps}>
      <g transform="matrix(1.25 0 0 -1.25 -502.42 254.48)">
        <g transform="translate(.84580 .63435)">
          <path
            d="m109.18 8.0137-35.742 13.385-4.127 9.2793h-38.014l-3.1328 6.6816h38.174l-11.502 25.859h-39.27l-4.3047 9.5527h39.326l-5.4473 12.246h-27.932l-2.9277 6.8809h27.799l-6.5566 14.74 18.387 13.857h76.814l35.75-15.99 15.316-33.902-55.381 0.12305-10.78 24.769h-17.764l28.184-62.49h17.479l-12.037 23.566 54.625 0.0918 15.836-33.812-21.357-14.842h-71.424zm-97.272 117.5l-7.7617 17.135h163.05l7.9414-17.135h-163.23z"
            transform="matrix(.8 0 0 -.8 400.25 203.32)"
            fill={variant === 'main' ? colors.secondaryColor : '#ffffff'}
          />
          <path
            d="m443.64 110.34h60.64l26.895 11.874 12.424 27.654-43.107 0.036-8.281-19.567h-15.872l22.546 49.993h15.646l-9.597-18.757 43.109 0.02 12.553 26.861-16.413 11.874h-57.14l-26.896-11.874-31.759-66.634 15.252-11.48z"
            fill={variant === 'main' ? '#ffffff' : variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
          />
          <path
            d="m485.33 201.98-26.896-11.873-29.761-65.99 16.3-12.123h57.594l26.895 11.873 12.564 27.654-42.927-0.097-8.601-19.433h-15.873l22.547 49.993h15.646l-8.328-18.84 41.679-0.012 12.713 26.975-16.412 11.873h-57.14z"
            fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          />
          <path
            d="m28.463 27.18-3.3887 6.6816h55.117l3.0918-6.6816h-54.82zm-16.082 32.197-6.1172 9.5547h80.852l4.1504-9.5547h-78.885zm2.3574 21.949-3.9785 6.8809h52.492l3.2812-6.8809h-51.795zm-4.7949 43.09-7.8281 14.576h163.02l6.6309-14.285-161.83-0.29101z"
            transform="matrix(.8 0 0 -.8 400.25 203.32)"
            fill={variant === 'main' ? '#ffffff' : variant === 'inverted' ? colors.primaryColor : colors.secondaryColor}
          />
          <path
            d="m30.807 24.365-3.0234 6.6816h54.201l4.5938-6.6816h-55.771zm-17.258 32.133-4.4395 9.8105h80.631l5.6738-9.8105h-81.865zm2.1484 21.945-3.1367 6.8828h53.646l3.9785-6.8828h-54.488zm-8.3594 47.066-5.1855 11.527h161.24l5.3008-11.527h-161.35z"
            transform="matrix(.8 0 0 -.8 400.25 203.32)"
            fill={variant === 'inverted' ? colors.secondaryColor : colors.primaryColor}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CentralMichiganLogo;
